/*! Core v5.0.5 */
:root {
  --bs-primary: #9b242c;
  --bs-primary-hoover: #E83541;  
  --bs-link-color: #9b242c;
  --bs-link-hover-color: #9b242c;
  --bs-primary-rgb: 155, 36, 44, 255;
  --bs-primary-rgbAzul: 29, 55, 99, 255;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #f64e60;
  --bs-orange: #fd7e14;
  --bs-yellow: #fad776;
  --bs-green: #6dbb30;
  --bs-teal: #20c997;
  --bs-cyan: #73e7f7;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #869ab8;
  --bs-gray-dark: #384c74;
  --bs-gray-100: #f9fbfd;
  --bs-gray-200: #f1f4f8;
  --bs-gray-300: #d9e2ef;
  --bs-gray-400: #c6d3e6;
  --bs-gray-500: #abbcd5;
  --bs-gray-600: #869ab8;
  --bs-gray-700: #506690;
  --bs-gray-800: #384c74;
  --bs-gray-900: #1b2a4e;
  --bs-primaryDefault: #574fec;
  --bs-secondary: #6c757d;
  --bs-success: #6dbb30;
  --bs-info: #73e7f7;
  --bs-warning: #fad776;
  --bs-danger: #9b242c;
  --bs-light: #f8f9fa;
  --bs-dark: #2e2e2e;
  --bs-primary-rgbDeafult: 87, 79, 236;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 109, 187, 48;
  --bs-info-rgb: 115, 231, 247;
  --bs-warning-rgb: 250, 215, 118;
  --bs-danger-rgb: 246, 78, 96;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 46, 46, 46;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 28, 9, 80;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  --bs-body-font-family: Inter, Rubik, Open Sans, Roboto, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #1c0950;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dde4ea;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.35rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.45rem;
  --bs-border-radius-xl: 0.6rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-colorDefault: var(--bs-primary);
  --bs-link-hover-colorDefault: #463fbd;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fef7e4;
}
*,
:after,
:before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  margin: 0;
  text-align: var(--bs-body-text-align);
}
hr {
  border: 0;
  border-top: 1px solid;
  color: inherit;
  margin: 1rem 0;
  opacity: 0.25;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
}
.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2rem;
  }
}
.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}
.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
p {
  margin-bottom: 1rem;
  margin-top: 0;
}
abbr[title] {
  cursor: help;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-bottom: 1rem;
  margin-top: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
.small,
small {
  font-size: 0.875em;
}
.mark,
mark {
  background-color: var(--bs-highlight-bg);
  padding: 0.1875em;
}
sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}
pre {
  display: block;
  font-size: 0.875em;
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto;
}
pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}
code {
  word-wrap: break-word;
  color: var(--bs-code-color);
  font-size: 0.875em;
}
a > code {
  color: inherit;
}
kbd {
  background-color: var(--bs-body-color);
  border-radius: 0.2rem;
  color: var(--bs-body-bg);
  font-size: 0.875em;
  padding: 0.1875rem 0.375rem;
}
kbd kbd {
  font-size: 1em;
  padding: 0;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  border-collapse: collapse;
  caption-side: bottom;
}
caption {
  color: #506690;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not(
    [type="week"]
  ):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}
legend {
  float: left;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  margin-bottom: 0.5rem;
  padding: 0;
  width: 100%;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  -webkit-appearance: button;
  font: inherit;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  cursor: pointer;
  display: list-item;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.listInline,
.listUnstyled {
  list-style: none;
  padding-left: 0;
}
.listInlineItem {
  display: inline-block;
}
.listInlineItem:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  color: #869ab8;
  font-size: 0.875em;
  margin-bottom: 1rem;
  margin-top: -1rem;
}
.blockquote-footer:before {
  content: "\2014\00A0";
}
.img-fluid,
.img-thumbnail {
  height: auto;
  max-width: 100%;
}
.img-thumbnail {
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.35rem;
  padding: 0.25rem;
}
.figure {
  display: inline-block;
}
.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}
.figure-caption {
  color: #869ab8;
  font-size: 0.875em;
}
.container,
.containerFluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
}
.row > * {
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}
.col3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}
.col9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}
.co12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}
.g3,
.gx-3 {
  --bs-gutter-x: 1rem;
}
.g3,
.gy-3 {
  --bs-gutter-y: 1rem;
}
.g4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}
.g4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}
.g-6,
.gx-6 {
  --bs-gutter-x: 4.5rem;
}
.g-6,
.gy-6 {
  --bs-gutter-y: 4.5rem;
}
.g-7,
.gx-7 {
  --bs-gutter-x: 6rem;
}
.g-7,
.gy-7 {
  --bs-gutter-y: 6rem;
}
.g-8,
.gx-8 {
  --bs-gutter-x: 8rem;
}
.g-8,
.gy-8 {
  --bs-gutter-y: 8rem;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .colSm2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .colSm3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .colSm6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .colSm12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 8rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .colMdAuto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 6rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 6rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 8rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .colLg12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 8rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 8rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 8rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 8rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 8rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: #eaf0f5;
  --bs-table-accent-bg: #fff;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #f1f4f8;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #f1f4f8;
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
  margin-bottom: 1rem;
  vertical-align: top;
  width: 100%;
}
.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  padding: 0.5rem;
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table-group-divider {
  border-top: 2px solid #f1f4f8;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 0.25rem;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.table-striped-columns > :not(caption) > tr > :nth-child(2n),
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}
.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dddcfb;
  --bs-table-border-color: #c7c6e2;
  --bs-table-striped-bg: #d2d1ee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c7c6e2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cccce8;
  --bs-table-hover-color: #000;
}
.table-primary,
.table-secondary {
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
}
.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
}
.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #e2f1d6;
  --bs-table-border-color: #cbd9c1;
  --bs-table-striped-bg: #d7e5cb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbd9c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1dfc6;
  --bs-table-hover-color: #000;
}
.table-info,
.table-success {
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
}
.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #e3fafd;
  --bs-table-border-color: #cce1e4;
  --bs-table-striped-bg: #d8eef0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cce1e4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d2e7ea;
  --bs-table-hover-color: #000;
}
.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fef7e4;
  --bs-table-border-color: #e5decd;
  --bs-table-striped-bg: #f1ebd9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5decd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebe4d3;
  --bs-table-hover-color: #000;
}
.table-danger,
.table-warning {
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
}
.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #fddcdf;
  --bs-table-border-color: #e4c6c9;
  --bs-table-striped-bg: #f0d1d4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4c6c9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eaccce;
  --bs-table-hover-color: #000;
}
.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
}
.table-dark,
.table-light {
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
}
.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #2e2e2e;
  --bs-table-border-color: #434343;
  --bs-table-striped-bg: #383838;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #434343;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #3e3e3e;
  --bs-table-hover-color: #fff;
}
.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 767.98px) {
  .tableResponsiveMd {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}
.col-form-label {
  font-size: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: calc(0.78rem + 1px);
  padding-top: calc(0.78rem + 1px);
}
.col-form-label-lg {
  font-size: 1rem;
  padding-bottom: calc(1.09rem + 1px);
  padding-top: calc(1.09rem + 1px);
}
.col-form-label-sm {
  font-size: 1rem;
  padding-bottom: calc(0.46rem + 1px);
  padding-top: calc(0.46rem + 1px);
}
.form-text {
  color: #506690;
  font-size: 0.875em;
  margin-top: 0.25rem;
}
.formControl {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #dde4ea;
  border-radius: 0.35rem;
  color: #1c0950;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.78rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .formControl {
    transition: none;
  }
}
.formControl[type="file"] {
  overflow: hidden;
}
.formControl[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.formControl:focus {
  background-color: #fff;
  border-color: rgba(87, 79, 236, 0.5);
  box-shadow: 0 0 25px rgba(87, 79, 236, 0.1);
  color: #1c0950;
  outline: 0;
}
.formControl::-webkit-date-and-time-value {
  height: 1.5em;
}
.formControl::-moz-placeholder {
  color: #95a4af;
  opacity: 1;
}
.formControl::placeholder {
  color: #95a4af;
  opacity: 1;
}
.formControl:disabled {
  background-color: #f1f4f8;
  opacity: 1;
}
.formControl::file-selector-button {
  -webkit-margin-end: 1rem;
  background-color: #f1f4f8;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #1c0950;
  margin: -0.78rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.78rem 1rem;
  pointer-events: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    transform 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .formControl::file-selector-button {
    transition: none;
  }
}
.formControl:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e5e8ec;
}
.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: #1c0950;
  display: block;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.78rem 0;
  width: 100%;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.formControlSm {
  padding-left: 0;
  padding-right: 0;
}
.formControlSm {
  border-radius: 0.2rem;
  font-size: 1rem;
  min-height: calc(1.5em + 0.92rem + 2px);
  padding: 0.46rem 1rem;
}
.formControlSm::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -0.46rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.46rem 1rem;
}
.form-control-lg {
  border-radius: 0.45rem;
  font-size: 1rem;
  min-height: calc(1.5em + 2.18rem + 2px);
  padding: 1.09rem 1rem;
}
.form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -1.09rem -1rem;
  margin-inline-end: 1rem;
  padding: 1.09rem 1rem;
}
textarea.formControl {
  min-height: calc(1.5em + 1.56rem + 2px);
}
textarea.formControlSm {
  min-height: calc(1.5em + 0.92rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 2.18rem + 2px);
}
.form-control-color {
  height: calc(1.5em + 1.56rem + 2px);
  padding: 0.78rem;
  width: 3rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.35rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.35rem;
}
.form-control-color.formControlSm {
  height: calc(1.5em + 0.92rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 2.18rem + 2px);
}
.formSelect {
  -moz-padding-start: calc(1rem - 3px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23384c74' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #dde4ea;
  border-radius: 0.35rem;
  color: #1c0950;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.78rem 3rem 0.78rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .formSelect {
    transition: none;
  }
}
.formSelect:focus {
  border-color: rgba(87, 79, 236, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(87, 79, 236, 0.25);
  outline: 0;
}
.formSelect[multiple],
.formSelect[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}
.formSelect:disabled {
  background-color: #f1f4f8;
}
.formSelect:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1c0950;
}
.form-select-sm {
  border-radius: 0.2rem;
  padding-bottom: 0.46rem;
  padding-top: 0.46rem;
}
.form-select-lg,
.form-select-sm {
  font-size: 1rem;
  padding-left: 1rem;
}
.form-select-lg {
  border-radius: 0.45rem;
  padding-bottom: 1.09rem;
  padding-top: 1.09rem;
}
.form-check {
  display: block;
  margin-bottom: 0.125rem;
  min-height: 1.5rem;
  padding-left: 1.75em;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.75em;
}
.form-check-reverse {
  padding-left: 0;
  padding-right: 1.75em;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.75em;
}
.form-check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f1f4f8;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #d9e2ef;
  height: 1.25em;
  margin-top: 0.125em;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  vertical-align: top;
  width: 1.25em;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: none;
  box-shadow: none;
  outline: 0;
}
.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #506690;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  border-color: #506690;
}
.form-check-input:disabled {
  filter: none;
  opacity: 0.5;
  pointer-events: none;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.8em;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2.3em;
  margin-left: -2.8em;
  transition: background-position 0.15s ease-in-out;
  width: 2.3em;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(87, 79, 236, 0.5)'/%3E%3C/svg%3E");
}
.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 100%;
}
.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.8em;
}
.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.8em;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}
.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  filter: none;
  opacity: 0.65;
  pointer-events: none;
}
.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 1.7rem;
  padding: 0;
  width: 100%;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #506690;
  border: 0;
  border-radius: 1rem;
  height: 1.2rem;
  margin-top: -0.4rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1.2rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #506690;
}
.form-range::-webkit-slider-runnable-track {
  background-color: #f1f4f8;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.4rem;
  width: 100%;
}
.form-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: #506690;
  border: 0;
  border-radius: 1rem;
  height: 1.2rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1.2rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #506690;
}
.form-range::-moz-range-track {
  background-color: #f1f4f8;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.4rem;
  width: 100%;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #abbcd5;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #abbcd5;
}
.formFloating {
  position: relative;
}
.formFloating > .formControl,
.formFloating > .form-control-plaintext,
.formFloating > .formSelect {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.formFloating > label {
  border: 1px solid transparent;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  text-align: start;
  text-overflow: ellipsis;
  top: 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  white-space: nowrap;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .formFloating > label {
    transition: none;
  }
}
.formFloating > .formControl,
.formFloating > .form-control-plaintext {
  padding: 1rem;
}
.formFloating > .form-control-plaintext::-moz-placeholder,
.formFloating > .formControl::-moz-placeholder {
  color: transparent;
}
.formFloating > .form-control-plaintext::placeholder,
.formFloating > .formControl::placeholder {
  color: transparent;
}
.formFloating > .form-control-plaintext:not(:-moz-placeholder-shown),
.formFloating > .formControl:not(:-moz-placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.formFloating > .form-control-plaintext:focus,
.formFloating > .form-control-plaintext:not(:placeholder-shown),
.formFloating > .formControl:focus,
.formFloating > .formControl:not(:placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.formFloating > .form-control-plaintext:-webkit-autofill,
.formFloating > .formControl:-webkit-autofill {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.formFloating > .formSelect {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.formFloating > .formControl:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.formFloating > .form-control-plaintext ~ label,
.formFloating > .formControl:focus ~ label,
.formFloating > .formControl:not(:placeholder-shown) ~ label,
.formFloating > .formSelect ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.formFloating > .formControl:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.formFloating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.input-group > .formControl,
.input-group > .formFloating,
.input-group > .formSelect {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%;
}
.input-group > .formControl:focus,
.input-group > .formFloating:focus-within,
.input-group > .formSelect:focus {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}
.input-group-text {
  align-items: center;
  background-color: #f1f4f8;
  border: 1px solid #dde4ea;
  border-radius: 0.35rem;
  color: #1c0950;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.78rem 1rem;
  text-align: center;
  white-space: nowrap;
}
.input-group-lg > .btn,
.input-group-lg > .formControl,
.input-group-lg > .formSelect,
.input-group-lg > .input-group-text {
  border-radius: 0.45rem;
  font-size: 1rem;
  padding: 1.09rem 1rem;
}
.input-group-sm > .btn,
.input-group-sm > .formControl,
.input-group-sm > .formSelect,
.input-group-sm > .input-group-text {
  border-radius: 0.2rem;
  font-size: 1rem;
  padding: 0.46rem 1rem;
}
.input-group-lg > .formSelect,
.input-group-sm > .formSelect {
  padding-right: 4rem;
}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .formFloating:nth-last-child(n + 3)
  > .formControl,
.input-group.has-validation
  > .formFloating:nth-last-child(n + 3)
  > .formSelect,
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdownMenu):not(
    .formFloating
  ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .formFloating:not(:last-child)
  > .formControl,
.input-group:not(.has-validation)
  > .formFloating:not(:last-child)
  > .formSelect,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdownMenu):not(
    .formFloating
  ) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdownMenu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
.input-group > .formFloating:not(:first-child) > .formControl,
.input-group > .formFloating:not(:first-child) > .formSelect {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.valid-feedback {
  color: #6dbb30;
  display: none;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}
.valid-tooltip {
  background-color: #6dbb30;
  border-radius: 0.25rem;
  color: #000;
  display: none;
  font-size: 0.875rem;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.4rem 0.6rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.formControl.is-valid,
.was-validated .formControl:valid {
  border-color: #6dbb30;
}
.formControl.is-valid:focus,
.was-validated .formControl:valid:focus {
  border-color: #6dbb30;
  box-shadow: 0 0 0 0.25rem rgba(109, 187, 48, 0.25);
}
.formSelect.is-valid,
.was-validated .formSelect:valid {
  border-color: #6dbb30;
}
.formSelect.is-valid:focus,
.was-validated .formSelect:valid:focus {
  border-color: #6dbb30;
  box-shadow: 0 0 0 0.25rem rgba(109, 187, 48, 0.25);
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #6dbb30;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #6dbb30;
}
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 187, 48, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #6dbb30;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.input-group > .formControl:not(:focus).is-valid,
.input-group > .formFloating:not(:focus-within).is-valid,
.input-group > .formSelect:not(:focus).is-valid,
.was-validated .input-group > .formControl:not(:focus):valid,
.was-validated .input-group > .formFloating:not(:focus-within):valid,
.was-validated .input-group > .formSelect:not(:focus):valid {
  z-index: 3;
}
.invalid-feedback {
  color: #f64e60;
  display: none;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}
.invalid-tooltip {
  background-color: #f64e60;
  border-radius: 0.25rem;
  color: #fff;
  display: none;
  font-size: 0.875rem;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.4rem 0.6rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.formControl.is-invalid,
.was-validated .formControl:invalid {
  border-color: #f64e60;
}
.formControl.is-invalid:focus,
.was-validated .formControl:invalid:focus {
  border-color: #f64e60;
  box-shadow: 0 0 0 0.25rem rgba(246, 78, 96, 0.25);
}
.formSelect.is-invalid,
.was-validated .formSelect:invalid {
  border-color: #f64e60;
}
.formSelect.is-invalid:focus,
.was-validated .formSelect:invalid:focus {
  border-color: #f64e60;
  box-shadow: 0 0 0 0.25rem rgba(246, 78, 96, 0.25);
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #f64e60;
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #f64e60;
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 78, 96, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #f64e60;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.input-group > .formControl:not(:focus).is-invalid,
.input-group > .formFloating:not(:focus-within).is-invalid,
.input-group > .formSelect:not(:focus).is-invalid,
.was-validated .input-group > .formControl:not(:focus):invalid,
.was-validated .input-group > .formFloating:not(:focus-within):invalid,
.was-validated .input-group > .formSelect:not(:focus):invalid {
  z-index: 4;
}
.btn {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.78rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #1c0950;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.35rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  background-color: var(--bs-btn-bg);
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  color: var(--bs-btn-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    transform 0.25s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  color: var(--bs-btn-hover-color);
}
.btn-check + .btn:hover {
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
  color: var(--bs-btn-color);
}
.btn:focus-visible {
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  color: var(--bs-btn-hover-color);
  outline: 0;
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  color: var(--bs-btn-active-color);
}
.btn-check:checked + .btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check) + .btn:active:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  color: var(--bs-btn-disabled-color);
  opacity: var(--bs-btn-disabled-opacity);
  pointer-events: none;
}
.btnPrimary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg:var(--bs-primary-hoover);
  --bs-btn-hover-border-color: var(--bs-primary-hoover);
  --bs-btn-focus-shadow-rgb: 112, 105, 239;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary-hoover);
  --bs-btn-active-border-color: var(--bs-primary-hoover);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}
.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}
.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #6dbb30;
  --bs-btn-border-color: #6dbb30;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #83c54f;
  --bs-btn-hover-border-color: #7cc245;
  --bs-btn-focus-shadow-rgb: 93, 159, 41;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #8ac959;
  --bs-btn-active-border-color: #7cc245;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #6dbb30;
  --bs-btn-disabled-border-color: #6dbb30;
}
.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #73e7f7;
  --bs-btn-border-color: #73e7f7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #88ebf8;
  --bs-btn-hover-border-color: #81e9f8;
  --bs-btn-focus-shadow-rgb: 98, 196, 210;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #8fecf9;
  --bs-btn-active-border-color: #81e9f8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #73e7f7;
  --bs-btn-disabled-border-color: #73e7f7;
}
.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #fad776;
  --bs-btn-border-color: #fad776;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fbdd8b;
  --bs-btn-hover-border-color: #fbdb84;
  --bs-btn-focus-shadow-rgb: 213, 183, 100;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fbdf91;
  --bs-btn-active-border-color: #fbdb84;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fad776;
  --bs-btn-disabled-border-color: #fad776;
}
.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f64e60;
  --bs-btn-border-color: #f64e60;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d14252;
  --bs-btn-hover-border-color: #c53e4d;
  --bs-btn-focus-shadow-rgb: 247, 105, 120;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c53e4d;
  --bs-btn-active-border-color: #b93b48;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f64e60;
  --bs-btn-disabled-border-color: #f64e60;
}
.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}
.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2e2e2e;
  --bs-btn-border-color: #2e2e2e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4d4d4d;
  --bs-btn-hover-border-color: #434343;
  --bs-btn-focus-shadow-rgb: 77, 77, 77;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #585858;
  --bs-btn-active-border-color: #434343;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2e2e2e;
  --bs-btn-disabled-border-color: #2e2e2e;
}
.btn-outline-primary {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 87, 79, 236;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-primary);
  --bs-gradient: none;
}
.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}
.btn-outline-success {
  --bs-btn-color: #6dbb30;
  --bs-btn-border-color: #6dbb30;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #6dbb30;
  --bs-btn-hover-border-color: #6dbb30;
  --bs-btn-focus-shadow-rgb: 109, 187, 48;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #6dbb30;
  --bs-btn-active-border-color: #6dbb30;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6dbb30;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6dbb30;
  --bs-gradient: none;
}
.btn-outline-info {
  --bs-btn-color: #73e7f7;
  --bs-btn-border-color: #73e7f7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #73e7f7;
  --bs-btn-hover-border-color: #73e7f7;
  --bs-btn-focus-shadow-rgb: 115, 231, 247;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #73e7f7;
  --bs-btn-active-border-color: #73e7f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #73e7f7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #73e7f7;
  --bs-gradient: none;
}
.btn-outline-warning {
  --bs-btn-color: #fad776;
  --bs-btn-border-color: #fad776;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fad776;
  --bs-btn-hover-border-color: #fad776;
  --bs-btn-focus-shadow-rgb: 250, 215, 118;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fad776;
  --bs-btn-active-border-color: #fad776;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fad776;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fad776;
  --bs-gradient: none;
}
.btn-outline-danger {
  --bs-btn-color: #f64e60;
  --bs-btn-border-color: #f64e60;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f64e60;
  --bs-btn-hover-border-color: #f64e60;
  --bs-btn-focus-shadow-rgb: 246, 78, 96;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f64e60;
  --bs-btn-active-border-color: #f64e60;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f64e60;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f64e60;
  --bs-gradient: none;
}
.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}
.btn-outline-dark {
  --bs-btn-color: #2e2e2e;
  --bs-btn-border-color: #2e2e2e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2e2e2e;
  --bs-btn-hover-border-color: #2e2e2e;
  --bs-btn-focus-shadow-rgb: 46, 46, 46;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2e2e2e;
  --bs-btn-active-border-color: #2e2e2e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2e2e2e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2e2e2e;
  --bs-gradient: none;
}
.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #869ab8;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 112, 105, 239;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}
.btn-group-lg > .btn,
.btn-lg {
  --bs-btn-padding-y: 1.09rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 0.45rem;
}
.btn-group-sm > .btn,
.btnSm {
  --bs-btn-padding-y: 0.46rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 0.2rem;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  height: auto;
  transition: width 0.35s ease;
  width: 0;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdownMenu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0.5rem;
  --bs-dropdown-padding-y: 1rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.9375rem;
  --bs-dropdown-color: #1c0950;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: transparent;
  --bs-dropdown-border-radius: 0.6rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.6rem - 1px);
  --bs-dropdown-divider-bg: #f1f6fa;
  --bs-dropdown-divider-margin-y: 0;
  --bs-dropdown-box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  --bs-dropdown-link-color: #1b2a4e;
  --bs-dropdown-link-hover-color: #182646;
  --bs-dropdown-link-hover-bg: #f1f4f8;
  --bs-dropdown-link-active-color: var(--bs-primary);
  --bs-dropdown-link-active-bg: #f9fbfd;
  --bs-dropdown-link-disabled-color: #abbcd5;
  --bs-dropdown-item-padding-x: 25px;
  --bs-dropdown-item-padding-y: 8px;
  --bs-dropdown-header-color: inherit;
  --bs-dropdown-header-padding-x: 25px;
  --bs-dropdown-header-padding-y: 1rem;
  background-clip: padding-box;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  color: var(--bs-dropdown-color);
  display: none;
  font-size: var(--bs-dropdown-font-size);
  list-style: none;
  margin: 0;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
}
.dropdownMenu[data-bs-popper] {
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
.dropup .dropdownMenu[data-bs-popper] {
  bottom: 100%;
  margin-bottom: var(--bs-dropdown-spacer);
  margin-top: 0;
  top: auto;
}
.dropend .dropdownMenu[data-bs-popper] {
  left: 100%;
  margin-left: var(--bs-dropdown-spacer);
  margin-top: 0;
  right: auto;
  top: 0;
}
.dropend .dropdown-toggle:after {
  vertical-align: 0;
}
.dropstart .dropdownMenu[data-bs-popper] {
  left: auto;
  margin-right: var(--bs-dropdown-spacer);
  margin-top: 0;
  right: 100%;
  top: 0;
}
.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}
.dropdown-divider {
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  opacity: 1;
  overflow: hidden;
}
.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: var(--bs-dropdown-link-color);
  display: block;
  font-weight: 400;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--bs-dropdown-link-hover-bg);
  color: var(--bs-dropdown-link-hover-color);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--bs-dropdown-link-active-bg);
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  background-color: transparent;
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
}
.dropdownMenu.show {
  display: block;
}
.dropdown-header {
  color: var(--bs-dropdown-header-color);
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0;
  padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  white-space: nowrap;
}
.dropdown-item-text {
  color: var(--bs-dropdown-link-color);
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}
.dropdown-menu-dark {
  --bs-dropdown-color: #d9e2ef;
  --bs-dropdown-bg: #384c74;
  --bs-dropdown-border-color: transparent;
  --bs-dropdown-link-color: #d9e2ef;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #f1f6fa;
  --bs-dropdown-link-hover-bg: hsla(0, 0%, 100%, 0.15);
  --bs-dropdown-link-active-color: var(--bs-primary);
  --bs-dropdown-link-active-bg: #f9fbfd;
  --bs-dropdown-link-disabled-color: #abbcd5;
  --bs-dropdown-header-color: #abbcd5;
}
.btn-group,
.btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: 1 1 auto;
  position: relative;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group {
  border-radius: 0.35rem;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > :not(.btn-check:first-child) + .btn {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split,
.btnSm + .dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-link-hover-color);
  ;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.navLink {
  color: var(--bs-nav-link-color);
  display: block;
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navLink {
    transition: none;
  }
}
.navLink:focus,
.navLink:hover {
  color: var(--bs-nav-link-hover-color);
}
.navLink.disabled {
  color: var(--bs-nav-link-disabled-color);
  cursor: default;
  pointer-events: none;
}
.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #d9e2ef;
  --bs-nav-tabs-border-radius: 0.35rem;
  --bs-nav-tabs-link-hover-border-color: #f1f4f8 #f1f4f8 #d9e2ef;
  --bs-nav-tabs-link-active-color: #506690;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #d9e2ef #d9e2ef #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .navLink {
  background: 0 0;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
}
.nav-tabs .navLink:focus,
.nav-tabs .navLink:hover {
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  isolation: isolate;
}
.nav-tabs .navLink.disabled,
.nav-tabs .navLink:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--bs-nav-link-disabled-color);
}
.nav-tabs .navItem.show .navLink,
.nav-tabs .navLink.active {
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  color: var(--bs-nav-tabs-link-active-color);
}
.nav-tabs .dropdownMenu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
}
.nav-pills {
  --bs-nav-pills-border-radius: 50rem;
  --bs-nav-pills-link-active-color: #1b2a4e;
  --bs-nav-pills-link-active-bg: #f1f4f8;
}
.nav-pills .navLink {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .navLink:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--bs-nav-link-disabled-color);
}
.nav-pills .navLink.active,
.nav-pills .show > .navLink {
  background-color: var(--bs-nav-pills-link-active-bg);
  color: var(--bs-nav-pills-link-active-color);
}
.nav-fill .navItem,
.nav-fill > .navLink {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .navItem,
.nav-justified > .navLink {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .navItem .navLink,
.nav-justified .navItem .navLink {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0;
  --bs-navbar-toggler-padding-x: 0;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  position: relative;
}
.navbar > .container,
.navbar > .containerFluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl {
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
}
.navbarBrand {
  color: var(--bs-navbar-brand-color);
  font-size: var(--bs-navbar-brand-font-size);
  margin-right: var(--bs-navbar-brand-margin-end);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  padding-top: var(--bs-navbar-brand-padding-y);
  white-space: nowrap;
}
.navbarBrand:focus,
.navbarBrand:hover {
  color: var(--bs-navbar-brand-hover-color);
}
.navbarNav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.navbarNav .navLink.active,
.navbarNav .show > .navLink {
  color: var(--bs-navbar-active-color);
}
.navbarNav .dropdownMenu {
  position: static;
}
.navbar-text {
  color: var(--bs-navbar-color);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
  color: var(--bs-navbar-active-color);
}
.navbarCollapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}
.navbarToggler {
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  color: var(--bs-navbar-color);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbarToggler {
    transition: none;
  }
}
.navbarToggler:hover {
  text-decoration: none;
}
.navbarToggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}
.navbar-toggler-icon {
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbarNav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbarNav .dropdownMenu {
    position: absolute;
  }
  .navbar-expand-sm .navbarNav .navLink {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbarCollapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbarToggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbarNav {
    flex-direction: row;
  }
  .navbar-expand-md .navbarNav .dropdownMenu {
    position: absolute;
  }
  .navbar-expand-md .navbarNav .navLink {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbarCollapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbarToggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 992px) {
  .navbarExpandLg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbarExpandLg .navbarNav {
    flex-direction: row;
  }
  .navbarExpandLg .navbarNav .dropdownMenu {
    position: absolute;
  }
  .navbarExpandLg .navbarNav .navLink {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbarExpandLg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbarExpandLg .navbarCollapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbarExpandLg .navbarToggler {
    display: none;
  }
  .navbarExpandLg .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbarExpandLg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbarExpandLg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbarNav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbarNav .dropdownMenu {
    position: absolute;
  }
  .navbar-expand-xl .navbarNav .navLink {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbarCollapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbarToggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbarNav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbarNav .dropdownMenu {
    position: absolute;
  }
  .navbar-expand-xxl .navbarNav .navLink {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbarCollapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbarToggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbarNav {
  flex-direction: row;
}
.navbar-expand .navbarNav .dropdownMenu {
  position: absolute;
}
.navbar-expand .navbarNav .navLink {
  padding-left: var(--bs-navbar-nav-link-padding-x);
  padding-right: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbarCollapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbarToggler {
  display: none;
}
.navbar-expand .offcanvas {
  background-color: transparent !important;
  border: 0 !important;
  flex-grow: 1;
  height: auto !important;
  position: static;
  transform: none !important;
  transition: none;
  visibility: visible !important;
  width: auto !important;
  z-index: auto;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  overflow-y: visible;
  padding: 0;
}
.navbar-dark {
  --bs-navbar-color: hsla(0, 0%, 100%, 0.9);
  --bs-navbar-hover-color: #fff;
  --bs-navbar-disabled-color: hsla(0, 0%, 100%, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: hsla(0, 0%, 100%, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0;
  --bs-card-border-width: 1px;
  --bs-card-border-color: #dde4ea;
  --bs-card-border-radius: 0.35rem;
  --bs-card-box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);
  --bs-card-inner-border-radius: calc(0.35rem - 1px);
  --bs-card-cap-padding-y: 1rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: inherit;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  word-wrap: break-word;
  background-clip: border-box;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  display: flex;
  flex-direction: column;
  height: var(--bs-card-height);
  min-width: 0;
  position: relative;
}
.card > hr {
  margin-left: 0;
  margin-right: 0;
}
.card > .list-group {
  border-bottom: inherit;
  border-top: inherit;
}
.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}
.card > .cardHeader + .list-group,
.card > .list-group + .cardFooter {
  border-top: 0;
}
.card-body {
  color: var(--bs-card-color);
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}
.card-subtitle {
  margin-top: calc(var(--bs-card-title-spacer-y) * -0.5);
}
.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}
.cardHeader {
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  color: var(--bs-card-cap-color);
  margin-bottom: 0;
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
}
.cardHeader:first-child {
  border-radius: var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius) 0 0;
}
.cardFooter {
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
  color: var(--bs-card-cap-color);
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
}
.cardFooter:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius);
}
.card-header-tabs {
  border-bottom: 0;
  margin-bottom: calc(var(--bs-card-cap-padding-y) * -1);
  margin-left: calc(var(--bs-card-cap-padding-x) * -0.5);
  margin-right: calc(var(--bs-card-cap-padding-x) * -0.5);
}
.card-header-tabs .navLink.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}
.card-header-pills {
  margin-left: calc(var(--bs-card-cap-padding-x) * -0.5);
  margin-right: calc(var(--bs-card-cap-padding-x) * -0.5);
}
.card-img-overlay {
  border-radius: var(--bs-card-inner-border-radius);
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  position: absolute;
  right: 0;
  top: 0;
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-bottom {
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
}
.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .cardHeader,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .cardFooter,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .cardHeader,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .cardFooter,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion {
  --bs-accordion-color: #1c0950;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, transform 0.25s ease-in-out,
    border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.35rem;
  --bs-accordion-inner-border-radius: calc(0.35rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #1c0950;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231c0950'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234e47d4'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --bs-accordion-btn-focus-border-color: rgba(87, 79, 236, 0.5);
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #4e47d4;
  --bs-accordion-active-bg: none;
}
.accordion-button {
  align-items: center;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  color: var(--bs-accordion-btn-color);
  display: flex;
  font-size: 1rem;
  overflow-anchor: none;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  position: relative;
  text-align: left;
  transition: var(--bs-accordion-transition);
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0
    var(--bs-accordion-border-color);
  color: var(--bs-accordion-active-color);
}
.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button:after {
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  content: "";
  flex-shrink: 0;
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  transition: var(--bs-accordion-btn-icon-transition);
  width: var(--bs-accordion-btn-icon-width);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
  z-index: 3;
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
  color: var(--bs-accordion-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-left-radius: var(--bs-accordion-border-radius);
  border-bottom-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: var(--bs-accordion-border-radius);
  border-bottom-right-radius: var(--bs-accordion-border-radius);
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}
.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 0;
  --bs-breadcrumb-bg: transparent;
  --bs-breadcrumb-divider-color: #d9e2ef;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #869ab8;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  display: flex;
  flex-wrap: wrap;
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
}
.breadcrumbItem + .breadcrumbItem {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumbItem + .breadcrumbItem:before {
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumbItem.active {
  color: var(--bs-breadcrumb-item-active-color);
}
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #869ab8;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #eef2f5;
  --bs-pagination-border-radius: 0.35rem;
  --bs-pagination-hover-color: #130d7b;
  --bs-pagination-hover-bg: #fdfefe;
  --bs-pagination-hover-border-color: #eef2f5;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #f1f4f8;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: var(--bs-primary);
  --bs-pagination-active-bg: #f1f0fd;
  --bs-pagination-active-border-color: #f1f0fd;
  --bs-pagination-disabled-color: #c6d3e6;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #eef2f5;
  display: flex;
  list-style: none;
  padding-left: 0;
}
.page-link {
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  color: var(--bs-pagination-color);
  display: block;
  font-size: var(--bs-pagination-font-size);
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
  color: var(--bs-pagination-hover-color);
  z-index: 2;
}
.page-link:focus {
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  color: var(--bs-pagination-focus-color);
  outline: 0;
  z-index: 3;
}
.active > .page-link,
.page-link.active {
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
  color: var(--bs-pagination-active-color);
  z-index: 3;
}
.disabled > .page-link,
.page-link.disabled {
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-bottom-left-radius: var(--bs-pagination-border-radius);
  border-top-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-bottom-right-radius: var(--bs-pagination-border-radius);
  border-top-right-radius: var(--bs-pagination-border-radius);
}
.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.45rem;
}
.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.2rem;
}
.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.35rem;
  border-radius: var(--bs-badge-border-radius);
  color: var(--bs-badge-color);
  display: inline-block;
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.35rem;
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  color: var(--bs-alert-color);
  margin-bottom: var(--bs-alert-margin-bottom);
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  position: relative;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  padding: 1.25rem 1rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.alert-primary {
  --bs-alert-color: #342f8e;
  --bs-alert-bg: #dddcfb;
  --bs-alert-border-color: #cdcaf9;
}
.alert-primary .alert-link {
  color: #2a2672;
}
.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}
.alert-success {
  --bs-alert-color: #41701d;
  --bs-alert-bg: #e2f1d6;
  --bs-alert-border-color: #d3ebc1;
}
.alert-success .alert-link {
  color: #345a17;
}
.alert-info {
  --bs-alert-color: #458b94;
  --bs-alert-bg: #e3fafd;
  --bs-alert-border-color: #d5f8fd;
}
.alert-info .alert-link {
  color: #376f76;
}
.alert-warning {
  --bs-alert-color: #968147;
  --bs-alert-bg: #fef7e4;
  --bs-alert-border-color: #fef3d6;
}
.alert-warning .alert-link {
  color: #786739;
}
.alert-danger {
  --bs-alert-color: #942f3a;
  --bs-alert-bg: #fddcdf;
  --bs-alert-border-color: #fccacf;
}
.alert-danger .alert-link {
  color: #76262e;
}
.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}
.alert-dark {
  --bs-alert-color: #1c1c1c;
  --bs-alert-bg: #d5d5d5;
  --bs-alert-border-color: silver;
}
.alert-dark .alert-link {
  color: #161616;
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #f1f4f8;
  --bs-progress-border-radius: 0.35rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: var(--bs-primary);
  --bs-progress-bar-transition: width 0.6s ease;
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  font-size: var(--bs-progress-font-size);
  height: var(--bs-progress-height);
}
.progress,
.progress-bar {
  display: flex;
  overflow: hidden;
}
.progress-bar {
  background-color: var(--bs-progress-bar-bg);
  color: var(--bs-progress-bar-color);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: var(--bs-progress-bar-transition);
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.list-group {
  --bs-list-group-color: #1b2a4e;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.35rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #506690;
  --bs-list-group-action-hover-color: #506690;
  --bs-list-group-action-hover-bg: #f9fbfd;
  --bs-list-group-action-active-color: #1c0950;
  --bs-list-group-action-active-bg: #f1f4f8;
  --bs-list-group-disabled-color: #869ab8;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: var(--bs-primary);
  --bs-list-group-active-border-color: var(--bs-primary);
  border-radius: var(--bs-list-group-border-radius);
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}
.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}
.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  color: var(--bs-list-group-action-color);
  text-align: inherit;
  width: 100%;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  background-color: var(--bs-list-group-action-hover-bg);
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  z-index: 1;
}
.list-group-item-action:active {
  background-color: var(--bs-list-group-action-active-bg);
  color: var(--bs-list-group-action-active-color);
}
.list-group-item {
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid
    var(--bs-list-group-border-color);
  color: var(--bs-list-group-color);
  display: block;
  padding: var(--bs-list-group-item-padding-y)
    var(--bs-list-group-item-padding-x);
  position: relative;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  background-color: var(--bs-list-group-disabled-bg);
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
}
.list-group-item.active {
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
  color: var(--bs-list-group-active-color);
  z-index: 2;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  border-top-width: var(--bs-list-group-border-width);
  margin-top: calc(var(--bs-list-group-border-width) * -1);
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bs-list-group-border-radius);
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-left-width: 0;
  border-top-width: var(--bs-list-group-border-width);
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: var(--bs-list-group-border-width);
  margin-left: calc(var(--bs-list-group-border-width) * -1);
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  background-color: #dddcfb;
  color: #342f8e;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  background-color: #c7c6e2;
  color: #342f8e;
}
.list-group-item-primary.list-group-item-action.active {
  background-color: #342f8e;
  border-color: #342f8e;
  color: #fff;
}
.list-group-item-secondary {
  background-color: #e2e3e5;
  color: #41464b;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  background-color: #cbccce;
  color: #41464b;
}
.list-group-item-secondary.list-group-item-action.active {
  background-color: #41464b;
  border-color: #41464b;
  color: #fff;
}
.list-group-item-success {
  background-color: #e2f1d6;
  color: #41701d;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  background-color: #cbd9c1;
  color: #41701d;
}
.list-group-item-success.list-group-item-action.active {
  background-color: #41701d;
  border-color: #41701d;
  color: #fff;
}
.list-group-item-info {
  background-color: #e3fafd;
  color: #458b94;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  background-color: #cce1e4;
  color: #458b94;
}
.list-group-item-info.list-group-item-action.active {
  background-color: #458b94;
  border-color: #458b94;
  color: #fff;
}
.list-group-item-warning {
  background-color: #fef7e4;
  color: #968147;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  background-color: #e5decd;
  color: #968147;
}
.list-group-item-warning.list-group-item-action.active {
  background-color: #968147;
  border-color: #968147;
  color: #fff;
}
.list-group-item-danger {
  background-color: #fddcdf;
  color: #942f3a;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  background-color: #e4c6c9;
  color: #942f3a;
}
.list-group-item-danger.list-group-item-action.active {
  background-color: #942f3a;
  border-color: #942f3a;
  color: #fff;
}
.list-group-item-light {
  background-color: #fefefe;
  color: #636464;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  background-color: #e5e5e5;
  color: #636464;
}
.list-group-item-light.list-group-item-action.active {
  background-color: #636464;
  border-color: #636464;
  color: #fff;
}
.list-group-item-dark {
  background-color: #d5d5d5;
  color: #1c1c1c;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  background-color: silver;
  color: #1c1c1c;
}
.list-group-item-dark.list-group-item-action.active {
  background-color: #1c1c1c;
  border-color: #1c1c1c;
  color: #fff;
}
.btn-close {
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.35rem;
  box-sizing: content-box;
  color: #000;
  height: 1em;
  opacity: 0.5;
  padding: 0.25em;
  width: 1em;
}
.btn-close:hover {
  color: #000;
  opacity: 0.75;
  text-decoration: none;
}
.btn-close:focus {
  box-shadow: none;
  opacity: 1;
  outline: 0;
}
.btn-close.disabled,
.btn-close:disabled {
  opacity: 0.25;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 300px;
  --bs-toast-font-size: 0.935rem;
  --bs-toast-bg: hsla(0, 0%, 100%, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: #f1f4f8;
  --bs-toast-border-radius: 0.3rem;
  --bs-toast-box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15);
  --bs-toast-header-color: #869ab8;
  --bs-toast-header-bg: hsla(0, 0%, 100%, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  border-radius: var(--bs-toast-border-radius);
  box-shadow: var(--bs-toast-box-shadow);
  color: var(--bs-toast-color);
  font-size: var(--bs-toast-font-size);
  max-width: 100%;
  pointer-events: auto;
  width: var(--bs-toast-max-width);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  --bs-toast-zindex: 1090;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  z-index: var(--bs-toast-zindex);
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}
.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid
    var(--bs-toast-header-border-color);
  border-top-left-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  border-top-right-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  color: var(--bs-toast-header-color);
  display: flex;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
}
.toast-header .btn-close {
  margin-left: var(--bs-toast-padding-x);
  margin-right: calc(var(--bs-toast-padding-x) * -0.5);
}
.toast-body {
  word-wrap: break-word;
  padding: var(--bs-toast-padding-x);
}
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: transparent;
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.6rem;
  --bs-modal-box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
  --bs-modal-inner-border-radius: calc(0.6rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: #eef2f5;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-border-color: #eef2f5;
  --bs-modal-footer-border-width: 1px;
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--bs-modal-zindex);
}
.modal-dialog {
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
  width: auto;
}
.modal.fade .modal-dialog {
  transform: translateY(-50px);
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-content {
  background-clip: padding-box;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  color: var(--bs-modal-color);
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: rgba(19, 31, 67, 0.26);
  --bs-backdrop-opacity: 0.26;
  background-color: var(--bs-backdrop-bg);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--bs-backdrop-zindex);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}
.modal-header {
  align-items: center;
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
}
.modal-header .btn-close {
  margin: calc(var(--bs-modal-header-padding-y) * -0.5)
    calc(var(--bs-modal-header-padding-x) * -0.5)
    calc(var(--bs-modal-header-padding-y) * -0.5) auto;
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
}
.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}
.modal-body {
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  position: relative;
}
.modal-footer {
  align-items: center;
  background-color: var(--bs-modal-footer-bg);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}
@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
      0 4px 6px 0 rgba(112, 157, 199, 0.15);
  }
  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--bs-modal-width);
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  height: 100%;
  margin: 0;
  max-width: none;
  width: 100vw;
}
.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}
.modal-fullscreen .modal-footer,
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-sm-down .modal-footer,
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-md-down .modal-footer,
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-lg-down .modal-footer,
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xl-down .modal-footer,
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xxl-down .modal-footer,
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.6rem;
  --bs-tooltip-padding-y: 0.4rem;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #d9e2ef;
  --bs-tooltip-bg: #384c74;
  --bs-tooltip-border-radius: 0.25rem;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  word-wrap: break-word;
  display: block;
  font-family: Inter, Rubik, Open Sans, Roboto, sans-serif;
  font-size: var(--bs-tooltip-font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  margin: var(--bs-tooltip-margin);
  opacity: 0;
  padding: var(--bs-tooltip-arrow-height);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: var(--bs-tooltip-zindex);
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  height: var(--bs-tooltip-arrow-height);
  width: var(--bs-tooltip-arrow-width);
}
.tooltip .tooltip-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: var(--bs-tooltip-bg);
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  top: -1px;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  height: var(--bs-tooltip-arrow-width);
  left: 0;
  width: var(--bs-tooltip-arrow-height);
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: var(--bs-tooltip-bg);
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  right: -1px;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: var(--bs-tooltip-bg);
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height);
  bottom: -1px;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  height: var(--bs-tooltip-arrow-width);
  right: 0;
  width: var(--bs-tooltip-arrow-height);
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
  border-left-color: var(--bs-tooltip-bg);
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0
    calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  left: -1px;
}
.tooltip-inner {
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
  color: var(--bs-tooltip-color);
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  text-align: center;
}
.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: #e6e6e6;
  --bs-popover-border-radius: 0.45rem;
  --bs-popover-inner-border-radius: calc(0.45rem - 1px);
  --bs-popover-box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.8rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #506690;
  --bs-popover-header-bg: #fff;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #1c0950;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  word-wrap: break-word;
  background-clip: padding-box;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  display: block;
  font-family: Inter, Rubik, Open Sans, Roboto, sans-serif;
  font-size: var(--bs-popover-font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  max-width: var(--bs-popover-max-width);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: var(--bs-popover-zindex);
}
.popover .popover-arrow {
  display: block;
  height: var(--bs-popover-arrow-height);
  width: var(--bs-popover-arrow-width);
}
.popover .popover-arrow:after,
.popover .popover-arrow:before {
  border: 0 solid transparent;
  content: "";
  display: block;
  position: absolute;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(
    (var(--bs-popover-arrow-height)) * -1 - var(--bs-popover-border-width)
  );
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:after,
.bs-popover-top > .popover-arrow:before {
  border-width: var(--bs-popover-arrow-height)
    calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
.bs-popover-top > .popover-arrow:after {
  border-top-color: var(--bs-popover-bg);
  bottom: var(--bs-popover-border-width);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  height: var(--bs-popover-arrow-width);
  left: calc(
    (var(--bs-popover-arrow-height)) * -1 - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:after,
.bs-popover-end > .popover-arrow:before {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
.bs-popover-end > .popover-arrow:after {
  border-right-color: var(--bs-popover-bg);
  left: var(--bs-popover-border-width);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(
    (var(--bs-popover-arrow-height)) * -1 - var(--bs-popover-border-width)
  );
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:before {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:after {
  border-bottom-color: var(--bs-popover-bg);
  top: var(--bs-popover-border-width);
}
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-header-bg);
  content: "";
  display: block;
  left: 50%;
  margin-left: calc(var(--bs-popover-arrow-width) * -0.5);
  position: absolute;
  top: 0;
  width: var(--bs-popover-arrow-width);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  height: var(--bs-popover-arrow-width);
  right: calc(
    (var(--bs-popover-arrow-height)) * -1 - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:after,
.bs-popover-start > .popover-arrow:before {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0
    calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
.bs-popover-start > .popover-arrow:after {
  border-left-color: var(--bs-popover-bg);
  right: var(--bs-popover-border-width);
}
.popover-header {
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  color: var(--bs-popover-header-color);
  font-size: var(--bs-popover-header-font-size);
  margin-bottom: 0;
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  color: var(--bs-popover-body-color);
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}
.carousel-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  transition: transform 0.6s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}
.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transform: none;
  transition-property: opacity;
}
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  opacity: 1;
  z-index: 1;
}
.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  opacity: 0;
  transition: opacity 0s 0.6s;
  z-index: 0;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  align-items: center;
  background: 0 0;
  border: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 0.15s ease;
  width: 15%;
  z-index: 1;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  opacity: 0.9;
  outline: 0;
  text-decoration: none;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.carousel-indicators {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}
.carousel-indicators [data-bs-target] {
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  box-sizing: content-box;
  cursor: pointer;
  flex: 0 1 auto;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 0.5;
  padding: 0;
  text-indent: -999px;
  transition: opacity 0.6s ease;
  width: 30px;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  bottom: 1.25rem;
  color: #fff;
  left: 15%;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  position: absolute;
  right: 15%;
  text-align: center;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
.spinner-border,
.spinner-grow {
  animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  width: var(--bs-spinner-width);
}
@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border-right-color: currentcolor;
  border: var(--bs-spinner-border-width) solid;
  border-right: var(--bs-spinner-border-width) solid transparent;
}
.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}
.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: transparent;
  --bs-offcanvas-box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
}
@media (max-width: 575.98px) {
  .offcanvas-sm {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: var(--bs-offcanvas-zindex);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-sm.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-sm.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom,
  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-sm.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.show:not(.hiding),
  .offcanvas-sm.showing {
    transform: none;
  }
  .offcanvas-sm.hiding,
  .offcanvas-sm.show,
  .offcanvas-sm.showing {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: var(--bs-offcanvas-zindex);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-md.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-md.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom,
  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-md.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.show:not(.hiding),
  .offcanvas-md.showing {
    transform: none;
  }
  .offcanvas-md.hiding,
  .offcanvas-md.show,
  .offcanvas-md.showing {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: var(--bs-offcanvas-zindex);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-lg.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-lg.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom,
  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-lg.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.show:not(.hiding),
  .offcanvas-lg.showing {
    transform: none;
  }
  .offcanvas-lg.hiding,
  .offcanvas-lg.show,
  .offcanvas-lg.showing {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: var(--bs-offcanvas-zindex);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-xl.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-xl.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom,
  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-xl.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.show:not(.hiding),
  .offcanvas-xl.showing {
    transform: none;
  }
  .offcanvas-xl.hiding,
  .offcanvas-xl.show,
  .offcanvas-xl.showing {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: var(--bs-offcanvas-zindex);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-xxl.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-xxl.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom,
  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-xxl.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.show:not(.hiding),
  .offcanvas-xxl.showing {
    transform: none;
  }
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show,
  .offcanvas-xxl.showing {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
.offcanvas {
  background-clip: padding-box;
  background-color: var(--bs-offcanvas-bg);
  bottom: 0;
  color: var(--bs-offcanvas-color);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  position: fixed;
  transition: transform 0.3s ease-in-out;
  visibility: hidden;
  z-index: var(--bs-offcanvas-zindex);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  left: 0;
  top: 0;
  transform: translateX(-100%);
  width: var(--bs-offcanvas-width);
}
.offcanvas.offcanvas-end {
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: var(--bs-offcanvas-width);
}
.offcanvas.offcanvas-top {
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  top: 0;
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom,
.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  left: 0;
  max-height: 100%;
  right: 0;
}
.offcanvas.offcanvas-bottom {
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none;
}
.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
  visibility: visible;
}
.offcanvas-backdrop {
  background-color: rgba(19, 31, 67, 0.26);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.26;
}
.offcanvas-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -0.5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -0.5);
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
}
.offcanvas-title {
  line-height: 1.5;
  margin-bottom: 0;
}
.offcanvas-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.placeholder {
  background-color: currentcolor;
  cursor: wait;
  display: inline-block;
  min-height: 1em;
  opacity: 0.5;
  vertical-align: middle;
}
.placeholder.btn:before {
  content: "";
  display: inline-block;
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  animation: placeholder-wave 2s linear infinite;
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}
@keyframes placeholder-wave {
  to {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
.clearfix:after {
  clear: both;
  content: "";
  display: block;
}
.textBgPrimary {
  background-color: var(--bs-primary) !important;
  color: #fff !important;
}
.text-bg-secondary {
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-success {
  background-color: RGBA(109, 187, 48, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-info {
  background-color: RGBA(115, 231, 247, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-warning {
  background-color: RGBA(250, 215, 118, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-danger {
  background-color: RGBA(246, 78, 96, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-light {
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.textBgDark {
  background-color: RGBA(46, 46, 46, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.link-primary {
  color: var(--bs-primary) !important;
}
.link-primary:focus,
.link-primary:hover {
  color: #463fbd !important;
}
.link-secondary {
  color: #6c757d !important;
}
.link-secondary:focus,
.link-secondary:hover {
  color: #565e64 !important;
}
.link-success {
  color: #6dbb30 !important;
}
.link-success:focus,
.link-success:hover {
  color: #8ac959 !important;
}
.link-info {
  color: #73e7f7 !important;
}
.link-info:focus,
.link-info:hover {
  color: #8fecf9 !important;
}
.link-warning {
  color: #fad776 !important;
}
.link-warning:focus,
.link-warning:hover {
  color: #fbdf91 !important;
}
.link-danger {
  color: #f64e60 !important;
}
.link-danger:focus,
.link-danger:hover {
  color: #c53e4d !important;
}
.link-light {
  color: #f8f9fa !important;
}
.link-light:focus,
.link-light:hover {
  color: #f9fafb !important;
}
.link-dark {
  color: #2e2e2e !important;
}
.link-dark:focus,
.link-dark:hover {
  color: #252525 !important;
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio:before {
  content: "";
  display: block;
  padding-top: var(--bs-aspect-ratio);
}
.ratio > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.ratio-21x9 {
  --bs-aspect-ratio: 42.85714%;
}
.fixed-top {
  top: 0;
}
.fixed-bottom,
.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}
.fixed-bottom {
  bottom: 0;
}
.sticky-top {
  top: 0;
}
.sticky-bottom,
.sticky-top {
  position: sticky;
  z-index: 1020;
}
.sticky-bottom {
  bottom: 0;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
.hstack {
  align-items: center;
  flex-direction: row;
}
.hstack,
.vstack {
  align-self: stretch;
  display: flex;
}
.vstack {
  flex: 1 1 auto;
  flex-direction: column;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
.stretched-link:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.textTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vr {
  align-self: stretch;
  background-color: currentcolor;
  display: inline-block;
  min-height: 1em;
  opacity: 0.25;
  width: 1px;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-none {
  float: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.dInlineBlock {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.dFlex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.dNone {
  display: none !important;
}
.shadow {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15) !important;
}
.shadowSm {
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125) !important;
}
.shadow-lg {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important;
}
.position-static {
  position: static !important;
}
.positionRelative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.border-0 {
  border: 0 !important;
}
.borderTop{
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}
.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}
.borderLight {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}
.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}
.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}
.border-1 {
  --bs-border-width: 1px;
}
.border-2 {
  --bs-border-width: 2px;
}
.border-3 {
  --bs-border-width: 3px;
}
.border-4 {
  --bs-border-width: 4px;
}
.border-5 {
  --bs-border-width: 5px;
}
.border-opacity-10 {
  --bs-border-opacity: 0.1;
}
.border-opacity-25 {
  --bs-border-opacity: 0.25;
}
.border-opacity-50 {
  --bs-border-opacity: 0.5;
}
.border-opacity-75 {
  --bs-border-opacity: 0.75;
}
.border-opacity-100 {
  --bs-border-opacity: 1;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h100 {
  height: 100% !important;
}
.hAuto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.flexFill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flexColumn {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justifyContentCenter {
  justify-content: center !important;
}
.justifyContentBetween {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.alignItemsStart {
  align-items: flex-start !important;
}
.alignItemsEnd {
  align-items: flex-end !important;
}
.alignItemsCenter {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.alignItemsStretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.alignSelfBaseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order1 {
  order: 1 !important;
}
.order2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.m-6 {
  margin: 4.5rem !important;
}
.m-7 {
  margin: 6rem !important;
}
.m-8 {
  margin: 8rem !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.listInlineItem {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.mx-6 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}
.mx-7 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}
.mx-8 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}
.mxAuto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.my-1 {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}
.my-2 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.my3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}
.my-5 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}
.my-6 {
  margin-bottom: 4.5rem !important;
  margin-top: 4.5rem !important;
}
.my-7 {
  margin-bottom: 6rem !important;
  margin-top: 6rem !important;
}
.my-8 {
  margin-bottom: 8rem !important;
  margin-top: 8rem !important;
}
.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt1 {
  margin-top: 0.25rem !important;
}
.mt2 {
  margin-top: 0.5rem !important;
}
.mt3 {
  margin-top: 1rem !important;
}
.mt4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-6 {
  margin-top: 4.5rem !important;
}
.mt-7 {
  margin-top: 6rem !important;
}
.mt-8 {
  margin-top: 8rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.me-6 {
  margin-right: 4.5rem !important;
}
.me-7 {
  margin-right: 6rem !important;
}
.me-8 {
  margin-right: 8rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb3 {
  margin-bottom: 1rem !important;
}
.mb4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-6 {
  margin-bottom: 4.5rem !important;
}
.mb-7 {
  margin-bottom: 6rem !important;
}
.mb-8 {
  margin-bottom: 8rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.ms-6 {
  margin-left: 4.5rem !important;
}
.ms-7 {
  margin-left: 6rem !important;
}
.ms-8 {
  margin-left: 8rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.m-n6 {
  margin: -4.5rem !important;
}
.m-n7 {
  margin: -6rem !important;
}
.m-n8 {
  margin: -8rem !important;
}
.mx-n1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}
.mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}
.mx-n6 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}
.mx-n7 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}
.mx-n8 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}
.my-n1 {
  margin-bottom: -0.25rem !important;
  margin-top: -0.25rem !important;
}
.my-n2 {
  margin-bottom: -0.5rem !important;
  margin-top: -0.5rem !important;
}
.my-n3 {
  margin-bottom: -1rem !important;
  margin-top: -1rem !important;
}
.my-n4 {
  margin-bottom: -1.5rem !important;
  margin-top: -1.5rem !important;
}
.my-n5 {
  margin-bottom: -3rem !important;
  margin-top: -3rem !important;
}
.my-n6 {
  margin-bottom: -4.5rem !important;
  margin-top: -4.5rem !important;
}
.my-n7 {
  margin-bottom: -6rem !important;
  margin-top: -6rem !important;
}
.my-n8 {
  margin-bottom: -8rem !important;
  margin-top: -8rem !important;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}
.mt-n2 {
  margin-top: -0.5rem !important;
}
.mt-n3 {
  margin-top: -1rem !important;
}
.mt-n4 {
  margin-top: -1.5rem !important;
}
.mt-n5 {
  margin-top: -3rem !important;
}
.mt-n6 {
  margin-top: -4.5rem !important;
}
.mt-n7 {
  margin-top: -6rem !important;
}
.mt-n8 {
  margin-top: -8rem !important;
}
.me-n1 {
  margin-right: -0.25rem !important;
}
.me-n2 {
  margin-right: -0.5rem !important;
}
.me-n3 {
  margin-right: -1rem !important;
}
.me-n4 {
  margin-right: -1.5rem !important;
}
.me-n5 {
  margin-right: -3rem !important;
}
.me-n6 {
  margin-right: -4.5rem !important;
}
.me-n7 {
  margin-right: -6rem !important;
}
.me-n8 {
  margin-right: -8rem !important;
}
.mb-n1 {
  margin-bottom: -0.25rem !important;
}
.mb-n2 {
  margin-bottom: -0.5rem !important;
}
.mb-n3 {
  margin-bottom: -1rem !important;
}
.mb-n4 {
  margin-bottom: -1.5rem !important;
}
.mb-n5 {
  margin-bottom: -3rem !important;
}
.mb-n6 {
  margin-bottom: -4.5rem !important;
}
.mb-n7 {
  margin-bottom: -6rem !important;
}
.mb-n8 {
  margin-bottom: -8rem !important;
}
.ms-n1 {
  margin-left: -0.25rem !important;
}
.ms-n2 {
  margin-left: -0.5rem !important;
}
.ms-n3 {
  margin-left: -1rem !important;
}
.ms-n4 {
  margin-left: -1.5rem !important;
}
.ms-n5 {
  margin-left: -3rem !important;
}
.ms-n6 {
  margin-left: -4.5rem !important;
}
.ms-n7 {
  margin-left: -6rem !important;
}
.ms-n8 {
  margin-left: -8rem !important;
}
.p0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.p-6 {
  padding: 4.5rem !important;
}
.p-7 {
  padding: 6rem !important;
}
.p-8 {
  padding: 8rem !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.px-6 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}
.px-7 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}
.px-8 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}
.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.py-1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}
.py2 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}
.py3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}
.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}
.py-6 {
  padding-bottom: 4.5rem !important;
  padding-top: 4.5rem !important;
}
.py-7 {
  padding-bottom: 6rem !important;
  padding-top: 6rem !important;
}
.py-8 {
  padding-bottom: 8rem !important;
  padding-top: 8rem !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pt1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pt-6 {
  padding-top: 4.5rem !important;
}
.pt-7 {
  padding-top: 6rem !important;
}
.pt-8 {
  padding-top: 8rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pe-6 {
  padding-right: 4.5rem !important;
}
.pe-7 {
  padding-right: 6rem !important;
}
.pe-8 {
  padding-right: 8rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pb-6 {
  padding-bottom: 4.5rem !important;
}
.pb-7 {
  padding-bottom: 6rem !important;
}
.pb-8 {
  padding-bottom: 8rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}
.ps-6 {
  padding-left: 4.5rem !important;
}
.ps-7 {
  padding-left: 6rem !important;
}
.ps-8 {
  padding-left: 8rem !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 3rem !important;
}
.gap-6 {
  gap: 4.5rem !important;
}
.gap-7 {
  gap: 6rem !important;
}
.gap-8 {
  gap: 8rem !important;
}
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}
.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}
.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.fs-5 {
  font-size: 1.25rem !important;
}
.fs-6 {
  font-size: 1rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fwBold {
  font-weight: 700 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.textCenter {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.textUppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.textMuted {
  --bs-text-opacity: 1;
  color: #506690 !important;
}
.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: hsla(0, 0%, 100%, 0.5) !important;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bgWhite {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}
.roundedCircle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
}
.rounded-end,
.rounded-top {
  border-top-right-radius: var(--bs-border-radius) !important;
}
.rounded-bottom,
.rounded-end {
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.rounded-bottom,
.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.rounded-start {
  border-top-left-radius: var(--bs-border-radius) !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .m-sm-8 {
    margin: 8rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-sm-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-sm-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-sm-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-sm-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-sm-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-sm-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-sm-8 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-sm-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 6rem !important;
  }
  .mt-sm-8 {
    margin-top: 8rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 4.5rem !important;
  }
  .me-sm-7 {
    margin-right: 6rem !important;
  }
  .me-sm-8 {
    margin-right: 8rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 6rem !important;
  }
  .ms-sm-8 {
    margin-left: 8rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6rem !important;
  }
  .m-sm-n8 {
    margin: -8rem !important;
  }
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-sm-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-sm-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-sm-n8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-sm-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-sm-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-sm-n8 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -6rem !important;
  }
  .mt-sm-n8 {
    margin-top: -8rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -6rem !important;
  }
  .me-sm-n8 {
    margin-right: -8rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -8rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -6rem !important;
  }
  .ms-sm-n8 {
    margin-left: -8rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .p-sm-8 {
    padding: 8rem !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-sm-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-sm-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-sm-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-sm-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-sm-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-sm-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-sm-8 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 6rem !important;
  }
  .pt-sm-8 {
    padding-top: 8rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 6rem !important;
  }
  .pe-sm-8 {
    padding-right: 8rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 8rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 6rem !important;
  }
  .ps-sm-8 {
    padding-left: 8rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 4.5rem !important;
  }
  .gap-sm-7 {
    gap: 6rem !important;
  }
  .gap-sm-8 {
    gap: 8rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .floatMdEnd {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .dMdFlex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .orderMd1 {
    order: 1 !important;
  }
  .orderMd2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .m-md-8 {
    margin: 8rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-md-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-md-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-md-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-md-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-md-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-md-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-md-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-md-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-md-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-md-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-md-8 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-md-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mtMd0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 4.5rem !important;
  }
  .mt-md-7 {
    margin-top: 6rem !important;
  }
  .mt-md-8 {
    margin-top: 8rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 4.5rem !important;
  }
  .me-md-7 {
    margin-right: 6rem !important;
  }
  .me-md-8 {
    margin-right: 8rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 6rem !important;
  }
  .mb-md-8 {
    margin-bottom: 8rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 4.5rem !important;
  }
  .ms-md-7 {
    margin-left: 6rem !important;
  }
  .ms-md-8 {
    margin-left: 8rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6rem !important;
  }
  .m-md-n8 {
    margin: -8rem !important;
  }
  .mx-md-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-md-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-md-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-md-n8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-md-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-md-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-md-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-md-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-md-n8 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -6rem !important;
  }
  .mt-md-n8 {
    margin-top: -8rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -4.5rem !important;
  }
  .me-md-n7 {
    margin-right: -6rem !important;
  }
  .me-md-n8 {
    margin-right: -8rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -8rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -6rem !important;
  }
  .ms-md-n8 {
    margin-left: -8rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .p-md-8 {
    padding: 8rem !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-md-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-md-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-md-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-md-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-md-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-md-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-md-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-md-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-md-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-md-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-md-8 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4.5rem !important;
  }
  .pt-md-7 {
    padding-top: 6rem !important;
  }
  .pt-md-8 {
    padding-top: 8rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 4.5rem !important;
  }
  .pe-md-7 {
    padding-right: 6rem !important;
  }
  .pe-md-8 {
    padding-right: 8rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 6rem !important;
  }
  .pb-md-8 {
    padding-bottom: 8rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 4.5rem !important;
  }
  .ps-md-7 {
    padding-left: 6rem !important;
  }
  .ps-md-8 {
    padding-left: 8rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 4.5rem !important;
  }
  .gap-md-7 {
    gap: 6rem !important;
  }
  .gap-md-8 {
    gap: 8rem !important;
  }
  .textMdStart {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .dLgNone {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .m-lg-8 {
    margin: 8rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-lg-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-lg-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-lg-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-lg-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-lg-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-lg-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-lg-8 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-lg-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 6rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 4.5rem !important;
  }
  .me-lg-7 {
    margin-right: 6rem !important;
  }
  .me-lg-8 {
    margin-right: 8rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 6rem !important;
  }
  .ms-lg-8 {
    margin-left: 8rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6rem !important;
  }
  .m-lg-n8 {
    margin: -8rem !important;
  }
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-lg-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-lg-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-lg-n8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-lg-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-lg-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-lg-n8 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -6rem !important;
  }
  .mt-lg-n8 {
    margin-top: -8rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -6rem !important;
  }
  .me-lg-n8 {
    margin-right: -8rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -8rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -6rem !important;
  }
  .ms-lg-n8 {
    margin-left: -8rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .p-lg-8 {
    padding: 8rem !important;
  }
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-lg-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-lg-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-lg-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-lg-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-lg-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-lg-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-lg-8 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 6rem !important;
  }
  .pt-lg-8 {
    padding-top: 8rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6rem !important;
  }
  .pe-lg-8 {
    padding-right: 8rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6rem !important;
  }
  .ps-lg-8 {
    padding-left: 8rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 4.5rem !important;
  }
  .gap-lg-7 {
    gap: 6rem !important;
  }
  .gap-lg-8 {
    gap: 8rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .m-xl-8 {
    margin: 8rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xl-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xl-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-xl-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-xl-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xl-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xl-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-xl-8 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-xl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 6rem !important;
  }
  .mt-xl-8 {
    margin-top: 8rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xl-7 {
    margin-right: 6rem !important;
  }
  .me-xl-8 {
    margin-right: 8rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 6rem !important;
  }
  .ms-xl-8 {
    margin-left: 8rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6rem !important;
  }
  .m-xl-n8 {
    margin: -8rem !important;
  }
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-xl-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xl-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-xl-n8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-xl-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xl-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-xl-n8 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xl-n8 {
    margin-top: -8rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -6rem !important;
  }
  .me-xl-n8 {
    margin-right: -8rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -8rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xl-n8 {
    margin-left: -8rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .p-xl-8 {
    padding: 8rem !important;
  }
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xl-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xl-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-xl-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xl-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xl-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xl-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-xl-8 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 6rem !important;
  }
  .pt-xl-8 {
    padding-top: 8rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 6rem !important;
  }
  .pe-xl-8 {
    padding-right: 8rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 8rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 6rem !important;
  }
  .ps-xl-8 {
    padding-left: 8rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 4.5rem !important;
  }
  .gap-xl-7 {
    gap: 6rem !important;
  }
  .gap-xl-8 {
    gap: 8rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4.5rem !important;
  }
  .m-xxl-7 {
    margin: 6rem !important;
  }
  .m-xxl-8 {
    margin: 8rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xxl-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xxl-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-xxl-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xxl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xxl-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xxl-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-xxl-8 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-xxl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 6rem !important;
  }
  .mt-xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 6rem !important;
  }
  .me-xxl-8 {
    margin-right: 8rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 6rem !important;
  }
  .ms-xxl-8 {
    margin-left: 8rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -4.5rem !important;
  }
  .m-xxl-n7 {
    margin: -6rem !important;
  }
  .m-xxl-n8 {
    margin: -8rem !important;
  }
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xxl-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-xxl-n8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-xxl-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xxl-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-xxl-n8 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -8rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -6rem !important;
  }
  .me-xxl-n8 {
    margin-right: -8rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -8rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -8rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4.5rem !important;
  }
  .p-xxl-7 {
    padding: 6rem !important;
  }
  .p-xxl-8 {
    padding: 8rem !important;
  }
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xxl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xxl-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xxl-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-xxl-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .py-xxl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xxl-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xxl-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-xxl-8 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 6rem !important;
  }
  .pt-xxl-8 {
    padding-top: 8rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 6rem !important;
  }
  .pe-xxl-8 {
    padding-right: 8rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 8rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 6rem !important;
  }
  .ps-xxl-8 {
    padding-left: 8rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 4.5rem !important;
  }
  .gap-xxl-7 {
    gap: 6rem !important;
  }
  .gap-xxl-8 {
    gap: 8rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
body,
html {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  direction: ltr;
  min-height: 100vh;
}
:active,
:focus,
a,
button,
input,
textarea {
  outline: 0 !important;
}
textarea {
  min-height: 36px;
  resize: vertical;
  transition: none;
}
.section,
section {
  position: relative;
  word-break: break-word;
}
.section table,
.section table-responsive,
section .table-responsive,
section table {
  word-break: normal;
}
select {
  text-indent: 0.01px;
  text-overflow: "";
}
input[type="number"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="time"],
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
select {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
}
summary::-webkit-details-marker,
summary::marker {
  color: #869ab8;
}
.bg-dark p {
  color: #abbcd5;
}
.text-white p {
  color: #fff;
}
.alert p {
  color: inherit;
}
.bg-white-alt {
  background-color: #fff;
}
.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}
.hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  height: 1px;
}
.article-format .h2,
.article-format .h3,
.article-format h2,
.article-format h3 {
  margin-bottom: 20px;
}
.article-format blockquote + .h2,
.article-format blockquote + .h3,
.article-format blockquote + h2,
.article-format blockquote + h3,
.article-format p:not(:empty) + .h2,
.article-format p:not(:empty) + .h3,
.article-format p:not(:empty) + h2,
.article-format p:not(:empty) + h3 {
  margin-top: 50px;
}
.article-format .embed-responsive,
.article-format iframe,
.article-format img,
.article-format p {
  margin-bottom: 25px;
}
.article-format blockquote {
  background-color: #f9fbfd;
  border-radius: 0.35rem;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 300;
  margin: 0 0 2rem;
  padding: 1.5rem;
}
.article-format blockquote p {
  margin-bottom: 0;
}
input.text-white::-webkit-input-placeholder,
textarea.text-white::-webkit-input-placeholder {
  color: hsla(0, 0%, 100%, 0.5) !important;
}
input.text-white::-moz-placeholder,
textarea.text-white::-moz-placeholder {
  color: hsla(0, 0%, 100%, 0.5) !important;
}
.p-auto {
  padding: auto !important;
}
.p-inherit {
  padding: inherit !important;
}
.m-auto {
  margin: auto !important;
}
.m-inherit {
  margin: inherit !important;
}
.border-solid {
  border-style: solid !important;
  border-width: 1px;
}
.border-dashed {
  border-style: dashed !important;
  border-width: 1px;
}
.border-dotted {
  border-style: dotted !important;
  border-width: 1px;
}
.border-transparent {
  border-color: transparent !important;
}
.border-theme-color-light {
  border-color: rgba(87, 79, 236, 0.04) !important;
}
.border-gray-100 {
  border: 1px solid #f9fbfd;
}
.border-gray-200 {
  border: 1px solid #f1f4f8;
}
.border-gray-300 {
  border: 1px solid #d9e2ef;
}
.border-gray-400 {
  border: 1px solid #c6d3e6;
}
.border-gray-500 {
  border: 1px solid #abbcd5;
}
.border-white {
  border: 1px solid #fff;
}
.border-input {
  border: 1px solid #dde4ea;
}
.row-pill,
.row-pill > .bg-cover,
.row-pill > img {
  border-radius: 50rem !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded-lg {
  border-radius: 0.45rem !important;
}
.rounded-xl {
  border-radius: 0.6rem !important;
}
.rounded-xlg {
  border-radius: 0.1 0.3rem !important;
}
.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
}
.rounded-bottom-0,
.rounded-left-0 {
  border-bottom-left-radius: 0 !important;
}
.rounded-left-0 {
  border-top-left-radius: 0 !important;
}
.rounded-right-0 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.rounded-end-0 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.fwMedium {
  font-weight: 500;
}
.section .shadow,
.section .shadow-3d,
.section .shadow-3d-down,
.section .shadow-3d-down-hover,
.section .shadow-3d-hover,
.section .shadow-3d-up,
.section .shadow-3d-up-hover,
.section .shadow-hover,
.section .shadow-lg,
.section .shadow-lg-hover,
.section .shadow-md,
.section .shadow-md-hover,
.section .shadowSm,
.section .shadow-sm-hover,
.section .shadow-xlg,
.section .shadow-xlg-hover,
.section .shadowXs,
.section .shadow-xs-hover {
  animation: __shadowPageLoadFix;
  animation-duration: 10ms;
  transition-property: box-shadow, transform;
}
@keyframes __shadowPageLoadFix {
  to {
    box-shadow: none;
  }
}
.shadowNone {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15) !important;
}
.shadowXs,
.shadow-xs-hover:hover {
  box-shadow: 0 0 25px rgba(140, 152, 164, 0.1) !important;
}
.shadow-sm-hover:hover {
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125) !important;
}
.shadow-md,
.shadow-md-hover:hover {
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.15) !important;
}
.shadow-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important;
}
.shadow-xlg,
.shadow-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.3) !important;
}
.shadow-soft,
.shadow-soft-hover:hover {
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075) !important;
}
.shadow-3d-up,
.shadow-3d-up-hover:hover {
  box-shadow: 0 -2rem 3.5rem rgba(22, 28, 45, 0.1) !important;
}
.shadow-3d,
.shadow-3d-down,
.shadow-3d-down-hover:hover,
.shadow-3d-hover:hover {
  box-shadow: 0 2.5rem 4rem rgba(22, 28, 45, 0.1) !important;
}
.text-shadow {
  text-shadow: -1px 2px 1px rgba(0, 0, 0, 0.4);
}
.text-shadow-lg {
  text-shadow: -1px 2px 22px rgba(0, 0, 0, 0.4);
}
.text-shadow-xl {
  text-shadow: -1px 1px 18px rgba(0, 0, 0, 0.6);
}
.shadow-primary-xs,
.shadow-primary-xs-hover:hover {
  box-shadow: 0 0 25px rgba(87, 79, 236, 0.1) !important;
}
.shadow-primary-sm,
.shadow-primary-sm-hover:hover {
  box-shadow: 0 0 35px rgba(87, 79, 236, 0.125) !important;
}
.shadow-primary-md,
.shadow-primary-md-hover:hover {
  box-shadow: 0 0 35px rgba(87, 79, 236, 0.15) !important;
}
.shadow-primary-lg,
.shadow-primary-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(87, 79, 236, 0.175) !important;
}
.shadow-primary-xlg,
.shadow-primary-xlg-hover:hover {
  box-shadow: 0 20px 40px 0 rgba(87, 79, 236, 0.25) !important;
}
.shadow-secondary-xs,
.shadow-secondary-xs-hover:hover {
  box-shadow: 0 0 25px hsla(208, 7%, 46%, 0.1) !important;
}
.shadow-secondary-sm,
.shadow-secondary-sm-hover:hover {
  box-shadow: 0 0 35px hsla(208, 7%, 46%, 0.125) !important;
}
.shadow-secondary-md,
.shadow-secondary-md-hover:hover {
  box-shadow: 0 0 35px hsla(208, 7%, 46%, 0.15) !important;
}
.shadow-secondary-lg,
.shadow-secondary-lg-hover:hover {
  box-shadow: 0 10px 40px 10px hsla(208, 7%, 46%, 0.175) !important;
}
.shadow-secondary-xlg,
.shadow-secondary-xlg-hover:hover {
  box-shadow: 0 20px 40px 0 hsla(208, 7%, 46%, 0.25) !important;
}
.shadow-success-xs,
.shadow-success-xs-hover:hover {
  box-shadow: 0 0 25px rgba(109, 187, 48, 0.1) !important;
}
.shadow-success-sm,
.shadow-success-sm-hover:hover {
  box-shadow: 0 0 35px rgba(109, 187, 48, 0.125) !important;
}
.shadow-success-md,
.shadow-success-md-hover:hover {
  box-shadow: 0 0 35px rgba(109, 187, 48, 0.15) !important;
}
.shadow-success-lg,
.shadow-success-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(109, 187, 48, 0.175) !important;
}
.shadow-success-xlg,
.shadow-success-xlg-hover:hover {
  box-shadow: 0 20px 40px 0 rgba(109, 187, 48, 0.25) !important;
}
.shadow-info-xs,
.shadow-info-xs-hover:hover {
  box-shadow: 0 0 25px rgba(115, 231, 247, 0.1) !important;
}
.shadow-info-sm,
.shadow-info-sm-hover:hover {
  box-shadow: 0 0 35px rgba(115, 231, 247, 0.125) !important;
}
.shadow-info-md,
.shadow-info-md-hover:hover {
  box-shadow: 0 0 35px rgba(115, 231, 247, 0.15) !important;
}
.shadow-info-lg,
.shadow-info-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(115, 231, 247, 0.175) !important;
}
.shadow-info-xlg,
.shadow-info-xlg-hover:hover {
  box-shadow: 0 20px 40px 0 rgba(115, 231, 247, 0.25) !important;
}
.shadow-warning-xs,
.shadow-warning-xs-hover:hover {
  box-shadow: 0 0 25px hsla(44, 93%, 72%, 0.1) !important;
}
.shadow-warning-sm,
.shadow-warning-sm-hover:hover {
  box-shadow: 0 0 35px hsla(44, 93%, 72%, 0.125) !important;
}
.shadow-warning-md,
.shadow-warning-md-hover:hover {
  box-shadow: 0 0 35px hsla(44, 93%, 72%, 0.15) !important;
}
.shadow-warning-lg,
.shadow-warning-lg-hover:hover {
  box-shadow: 0 10px 40px 10px hsla(44, 93%, 72%, 0.175) !important;
}
.shadow-warning-xlg,
.shadow-warning-xlg-hover:hover {
  box-shadow: 0 20px 40px 0 hsla(44, 93%, 72%, 0.25) !important;
}
.shadow-danger-xs,
.shadow-danger-xs-hover:hover {
  box-shadow: 0 0 25px rgba(246, 78, 96, 0.1) !important;
}
.shadow-danger-sm,
.shadow-danger-sm-hover:hover {
  box-shadow: 0 0 35px rgba(246, 78, 96, 0.125) !important;
}
.shadow-danger-md,
.shadow-danger-md-hover:hover {
  box-shadow: 0 0 35px rgba(246, 78, 96, 0.15) !important;
}
.shadow-danger-lg,
.shadow-danger-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(246, 78, 96, 0.175) !important;
}
.shadow-danger-xlg,
.shadow-danger-xlg-hover:hover {
  box-shadow: 0 20px 40px 0 rgba(246, 78, 96, 0.25) !important;
}
.shadow-light-xs,
.shadow-light-xs-hover:hover {
  box-shadow: 0 0 25px rgba(248, 249, 250, 0.1) !important;
}
.shadow-light-sm,
.shadow-light-sm-hover:hover {
  box-shadow: 0 0 35px rgba(248, 249, 250, 0.125) !important;
}
.shadow-light-md,
.shadow-light-md-hover:hover {
  box-shadow: 0 0 35px rgba(248, 249, 250, 0.15) !important;
}
.shadow-light-lg,
.shadow-light-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(248, 249, 250, 0.175) !important;
}
.shadow-light-xlg,
.shadow-light-xlg-hover:hover {
  box-shadow: 0 20px 40px 0 rgba(248, 249, 250, 0.25) !important;
}
.shadow-dark-xs,
.shadow-dark-xs-hover:hover {
  box-shadow: 0 0 25px rgba(46, 46, 46, 0.1) !important;
}
.shadow-dark-sm,
.shadow-dark-sm-hover:hover {
  box-shadow: 0 0 35px rgba(46, 46, 46, 0.125) !important;
}
.shadow-dark-md,
.shadow-dark-md-hover:hover {
  box-shadow: 0 0 35px rgba(46, 46, 46, 0.15) !important;
}
.shadow-dark-lg,
.shadow-dark-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(46, 46, 46, 0.175) !important;
}
.shadow-dark-xlg,
.shadow-dark-xlg-hover:hover {
  box-shadow: 0 20px 40px 0 rgba(46, 46, 46, 0.25) !important;
}
.transition-all-ease-150 {
  transition-duration: 0.15s !important;
  transition-property: all;
  transition-timing-function: ease;
}
.transition-all-ease-200 {
  transition-duration: 0.2s !important;
  transition-property: all;
  transition-timing-function: ease;
}
.transition-all-ease-250 {
  transition-duration: 0.25s !important;
  transition-property: all;
  transition-timing-function: ease;
}
.transition-all-ease-500 {
  transition-duration: 0.5s !important;
  transition-property: all;
  transition-timing-function: ease;
}
.transition-bg-ease-150 {
  transition-duration: 0.15s !important;
  transition-property: background-color !important;
  transition-timing-function: linear;
}
.transition-bg-ease-200 {
  transition-duration: 0.2s !important;
  transition-property: background-color !important;
  transition-timing-function: linear;
}
.transition-bg-ease-250 {
  transition-duration: 0.25s !important;
  transition-property: background-color !important;
  transition-timing-function: linear;
}
.transition-bg-ease-500 {
  transition-duration: 0.5s !important;
  transition-property: background-color !important;
  transition-timing-function: linear;
}
.transition-hover-bottom,
.transition-hover-left,
.transition-hover-right,
.transition-hover-top {
  transform: translateX(0);
  transform: translateY(0);
  transform: translateZ(0);
}
.transition-hover-top:focus,
.transition-hover-top:hover {
  transform: translateY(-3px);
}
.transition-hover-right:focus,
.transition-hover-right:hover {
  transform: translateX(3px);
}
.transition-hover-bottom:focus,
.transition-hover-bottom:hover {
  transform: translateY(3px);
}
.transition-hover-left:focus,
.transition-hover-left:hover,
.transition-hover-start:focus,
.transition-hover-start:hover {
  transform: translateX(-3px);
}
.transition-hover-end:focus,
.transition-hover-end:hover {
  transform: translateX(3px);
}
.transition-none {
  transition: none !important;
}
.transition-hover-zoom,
.transition-hover-zoom-img img {
  transition: all 0.3s ease-in-out;
}
.transition-hover-zoom-img:hover img,
.transition-hover-zoom:hover {
  transform: scale(1.1);
}
.transform-3d-left,
.transform-3d-right {
  perspective: 1024px;
  perspective-origin: 50%;
  transform-style: preserve-3d;
}
.transform-3d-left > div,
.transform-3d-left > figure,
.transform-3d-left > img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(35deg) rotateX(15deg) translateZ(0);
}
.transform-3d-right > div,
.transform-3d-right > figure,
.transform-3d-right > img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(-35deg) rotateX(15deg) translateZ(0);
}
.block-over-start-top {
  transform: translate(25px, -25px);
}
.block-over-start-bottom {
  transform: translate(25px, 25px);
}
.block-over-end-top {
  transform: translate(-25px, -25px);
}
.block-over-end-bottom {
  transform: translate(-25px, 25px);
}
.text-rotate {
  writing-mode: vertical-lr;
}
.text-rotate-180,
.text-rotate-90 {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  right: auto;
  top: 0;
  width: 100%;
  writing-mode: vertical-lr;
}
.text-rotate-180 {
  bottom: -100%;
  left: auto;
  right: -100%;
  top: auto;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
}
.transform-center-fixed {
  left: 50% !important;
  position: fixed !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 100;
}
.absolute-full {
  position: absolute !important;
}
.absolute-full,
.fixed-full {
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.fixed-full {
  position: fixed !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.left-0 {
  left: 0 !important;
  right: auto;
}
.left-50 {
  left: 50% !important;
  right: auto;
}
.right-0 {
  left: auto;
  right: 0 !important;
}
.right-50 {
  right: 50% !important;
  right: auto;
}
.start-0 {
  left: 0 !important;
  right: auto !important;
}
.end-0 {
  right: 0 !important;
}
.end-0,
.start-auto {
  left: auto !important;
}
.end-auto {
  right: auto !important;
}
.viewport-out {
  left: -9999px !important;
  position: fixed !important;
  top: -9999px !important;
}
.valign-middle,
.y-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.x-middle {
  position: relative;
  top: 50%;
  transform: translateX(-50%);
}
.d-middle {
  align-items: center;
  display: flex;
  justify-content: center;
}
#overlay-default,
.overlay-dark,
.overlay-dark-hover:after,
.overlay-default,
.overlay-light,
.overlay-light-hover:after {
  transition: all 0.2s ease;
}
#overlay-default,
.overlay-default {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(19, 31, 67, 0.26);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}
.overlay-dark,
.overlay-dark-hover,
.overlay-light,
.overlay-light-hover {
  position: relative;
}
.overlay-dark-hover > *,
.overlay-light-hover > * {
  position: relative;
  z-index: 0;
}
.overlay-dark > *,
.overlay-light > * {
  position: relative;
  z-index: 1;
}
.overlay-over:after {
  z-index: 10 !important;
}
.overlay-dark-hover:after,
.overlay-light-hover:after {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.rounded.overlay-dark-hover:hover,
.rounded.overlay-dark:after,
.rounded.overlay-light-hover:hover,
.rounded.overlay-light:after {
  border-radius: 0.35rem;
}
.overlay-dark-hover:after,
.overlay-dark:after,
.overlay-light-hover:after,
.overlay-light:after {
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}
.overlay-light-hover:hover:after,
.overlay-light:after {
  background-color: #fff;
}
.overlay-dark-hover:hover:after,
.overlay-dark:after {
  background: #131f43;
}
.opacity-0,
.overlay-opacity-0:after {
  opacity: 0;
}
.opacity-1,
.overlay-opacity-1:after {
  opacity: 0.1;
}
.opacity-2,
.overlay-opacity-2:after {
  opacity: 0.2;
}
.opacity-3,
.overlay-opacity-3:after {
  opacity: 0.3;
}
.opacity-4,
.overlay-opacity-4:after {
  opacity: 0.4;
}
.opacity-5,
.overlay-opacity-5:after {
  opacity: 0.5;
}
.opacity-6,
.overlay-opacity-6:after {
  opacity: 0.6;
}
.opacity-7,
.overlay-opacity-7:after {
  opacity: 0.7;
}
.opacity-8,
.overlay-opacity-8:after {
  opacity: 0.8;
}
.opacity-9,
.overlay-opacity-9:after {
  opacity: 0.9;
}
.opacity-none {
  opacity: 1 !important;
}
.opacity-99 {
  opacity: 0.99;
}
.overlay-opacity-0-hover:hover:after {
  opacity: 0;
}
.overlay-opacity-1-hover:hover:after {
  opacity: 0.1;
}
.overlay-opacity-2-hover:hover:after {
  opacity: 0.2;
}
.overlay-opacity-3-hover:hover:after {
  opacity: 0.3;
}
.overlay-opacity-4-hover:hover:after {
  opacity: 0.4;
}
.overlay-opacity-5-hover:hover:after {
  opacity: 0.5;
}
.overlay-opacity-6-hover:hover:after {
  opacity: 0.6;
}
.overlay-opacity-7-hover:hover:after {
  opacity: 0.7;
}
.overlay-opacity-8-hover:hover:after {
  opacity: 0.8;
}
.overlay-opacity-9-hover:hover:after {
  opacity: 0.9;
}
.overlay-opacity-0-hover:hover:after,
.overlay-opacity-0:after {
  opacity: 0;
}
.overlay-opacity-25-hover:hover:after,
.overlay-opacity-25:after {
  opacity: 0.25;
}
.overlay-opacity-50-hover:hover:after,
.overlay-opacity-50:after {
  opacity: 0.5;
}
.overlay-opacity-75-hover:hover:after,
.overlay-opacity-75:after {
  opacity: 0.75;
}
.overlay-opacity-100-hover:hover:after,
.overlay-opacity-100:after {
  opacity: 0.1;
}
#backdrop,
#backdrop-dark,
#backdrop-light,
.backdrop,
.backdrop-dark,
.backdrop-light {
  animation: _bkdFadeIn;
  animation-duration: 0.25s;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}
#backdrop,
#backdrop-dark,
.backdrop,
.backdrop-dark {
  background: rgba(19, 31, 67, 0.26);
}
#backdrop-light,
.backdrop-light {
  background: hsla(0, 0%, 100%, 0.26);
}
@keyframes _bkdFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bg-cover {
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.bg-fixed {
  background-attachment: fixed !important;
}
.bg-dots {
  background-image: radial-gradient(currentColor 1px, transparent 0);
  background-size: 10px 10px;
}
.bg-blur {
  filter: blur(5px);
}
.bg-suprime {
  mix-blend-mode: multiply;
}
.bg-inherit {
  background: inherit !important;
}
.bg-gradient-default {
  background: linear-gradient(180deg, #2f2e80, #4c4bb5);
}
.bgLime {
  background-color: #fefaf4 !important;
}
.bg-gradient-light-top {
  background-image: linear-gradient(0deg, rgba(87, 79, 236, 0.05), transparent);
}
.bg-gradient-light-bottom {
  background-image: linear-gradient(0deg, #fff, rgba(87, 79, 236, 0.05));
}
.bg-gradient-light-left {
  background-image: linear-gradient(90deg, rgba(87, 79, 236, 0.05), #fff);
}
.bg-gradient-light-right {
  background-image: linear-gradient(270deg, #fff, rgba(87, 79, 236, 0.05));
}
.bg-gradient-dark {
  background: #313131 !important;
  background: linear-gradient(180deg, #454545 0, #313131) !important;
  color: #fff;
}
.bg-gradient-dark p {
  color: #abbcd5;
}
.bg-gradient-primary {
  background: var(--bs-primary);
  background: linear-gradient(180deg, #7a74f0 0, var(--bs-primary)) !important;
}
.bg-gradient-secondary {
  background: #6c757d;
  background: linear-gradient(180deg, #808992 0, #6c757d) !important;
}
.bg-gradient-success {
  background: #6dbb30;
  background: linear-gradient(180deg, #81cf45 0, #6dbb30) !important;
}
.bg-gradient-info {
  background: #73e7f7;
  background: linear-gradient(180deg, #9aeef9 0, #73e7f7) !important;
}
.bg-gradient-warning {
  background: #fad776;
  background: linear-gradient(180deg, #fbe29d 0, #fad776) !important;
}
.bg-gradient-danger {
  background: #f64e60;
  background: linear-gradient(180deg, #f87583 0, #f64e60) !important;
}
.bg-gradient-light {
  background: #f8f9fa;
  background: linear-gradient(180deg, #fff 0, #f8f9fa) !important;
}
.bg-light-active,
.bg-light-hover:hover {
  background: #f9fbfd;
}
.bg-theme-color-light {
  background-color: rgba(87, 79, 236, 0.04) !important;
}
.bgGray100 {
  background-color: #f9fbfd !important;
}
.bg-gray-200 {
  background-color: #f1f4f8 !important;
}
.bg-gray-300 {
  background-color: #d9e2ef !important;
}
.bg-gray-400 {
  background-color: #c6d3e6 !important;
}
.bg-gray-500 {
  background-color: #abbcd5 !important;
  color: #fff;
}
.bg-gray-600 {
  background-color: #869ab8 !important;
  color: #fff;
}
.bg-gray-700 {
  background-color: #506690 !important;
  color: #fff;
}
.bg-gray-800 {
  background-color: #384c74 !important;
  color: #fff;
}
.bg-gray-900 {
  background-color: #1b2a4e !important;
  color: #fff;
}
.bg-darkblue {
  background: #2b354f !important;
  color: #fff;
}
.bg-diff {
  background-color: rgba(0, 0, 0, 0.07);
}
input.bg-diff:focus,
textarea.bg-diff:focus {
  background-color: rgba(0, 0, 0, 0.11);
}
.active.bg-primary-active-soft,
.bg-primary-soft {
  background-color: #f1f0fd !important;
  color: #161092 !important;
}
.bg-primary-soft-hover:hover {
  background-color: #f1f0fd !important;
}
.active.bg-secondary-active-soft,
.bg-secondary-soft {
  background-color: #f0f2f2 !important;
  color: #25282b !important;
}
.bg-secondary-soft-hover:hover {
  background-color: #f0f2f2 !important;
}
.active.bg-success-active-soft,
.bg-success-soft {
  background-color: #e4f5d7 !important;
  color: #264111 !important;
}
.bg-success-soft-hover:hover {
  background-color: #e4f5d7 !important;
}
.active.bg-info-active-soft,
.bg-info-soft {
  background-color: #bbf3fb !important;
  color: #0bafc6 !important;
}
.bg-info-soft-hover:hover {
  background-color: #bbf3fb !important;
}
.active.bg-warning-active-soft,
.bg-warning-soft {
  background-color: #fdedc0 !important;
  color: #cf9a08 !important;
}
.bg-warning-soft-hover:hover {
  background-color: #fdedc0 !important;
}
.active.bg-danger-active-soft,
.bg-danger-soft {
  background-color: #feeef0 !important;
  color: #a30819 !important;
}
.bg-danger-soft-hover:hover {
  background-color: #feeef0 !important;
}
.active.bg-light-active-soft,
.bg-light-soft {
  background-color: #fff !important;
  color: #9fadba !important;
}
.bg-light-soft-hover:hover {
  background-color: #fff !important;
}
.active.bg-dark-active-soft,
.bg-dark-soft {
  background-color: #c4c4c4 !important;
  color: #000 !important;
}
.bg-dark-soft-hover:hover {
  background-color: #c4c4c4 !important;
}
.bg-primary-light {
  background-color: rgba(87, 79, 236, 0.04) !important;
}
.bg-secondary-light {
  background-color: hsla(208, 7%, 46%, 0.04) !important;
}
.bg-success-light {
  background-color: rgba(109, 187, 48, 0.04) !important;
}
.bg-info-light {
  background-color: rgba(115, 231, 247, 0.04) !important;
}
.bg-warning-light {
  background-color: hsla(44, 93%, 72%, 0.04) !important;
}
.bg-danger-light {
  background-color: rgba(246, 78, 96, 0.04) !important;
}
.bg-light-light {
  background-color: rgba(248, 249, 250, 0.04) !important;
}
.bg-dark-light {
  background-color: rgba(46, 46, 46, 0.04) !important;
}
.active.bg-primary-active {
  background-color: var(--bs-primary) !important;
  color: #fff !important;
}
.active.bg-secondary-active {
  background-color: #6c757d !important;
  color: #fff !important;
}
.active.bg-success-active {
  background-color: #6dbb30 !important;
  color: #fff !important;
}
.active.bg-info-active {
  background-color: #73e7f7 !important;
  color: #fff !important;
}
.active.bg-warning-active {
  background-color: #fad776 !important;
  color: #fff !important;
}
.active.bg-danger-active {
  background-color: #f64e60 !important;
  color: #fff !important;
}
.active.bg-light-active {
  background-color: #f8f9fa !important;
  color: #fff !important;
}
.active.bg-dark-active {
  background-color: #2e2e2e !important;
  color: #fff !important;
}
.bg-primary-hover:hover {
  background-color: var(--bs-primary) !important;
  color: #fff !important;
}
.bg-primary-hover:hover p {
  color: #fff !important;
}
.bg-secondary-hover:hover {
  background-color: #6c757d !important;
  color: #fff !important;
}
.bg-secondary-hover:hover p {
  color: #fff !important;
}
.bg-success-hover:hover {
  background-color: #6dbb30 !important;
  color: #fff !important;
}
.bg-success-hover:hover p {
  color: #fff !important;
}
.bg-info-hover:hover {
  background-color: #73e7f7 !important;
  color: #fff !important;
}
.bg-info-hover:hover p {
  color: #fff !important;
}
.bg-warning-hover:hover {
  background-color: #fad776 !important;
  color: #fff !important;
}
.bg-warning-hover:hover p {
  color: #fff !important;
}
.bg-danger-hover:hover {
  background-color: #f64e60 !important;
  color: #fff !important;
}
.bg-danger-hover:hover p {
  color: #fff !important;
}
.bg-light-hover:hover {
  background-color: #f8f9fa !important;
}
.bg-dark-hover:hover {
  background-color: #2e2e2e !important;
  color: #fff !important;
}
.bg-dark-hover:hover p,
.text-white-hover:hover {
  color: #fff !important;
}
.text-dark-hover:hover {
  color: #000 !important;
}
.bg-light-radial {
  background: radial-gradient(#fff, #f8f9fa) !important;
}
.bg-gradient-radial-primary {
  background-image: radial-gradient(
    circle,
    rgba(210, 208, 250, 0.4),
    var(--bs-primary) 70%
  ) !important;
}
.bg-gradient-radial-secondary {
  background-image: radial-gradient(
    circle,
    hsla(210, 7%, 74%, 0.4),
    #6c757d 70%
  ) !important;
}
.bg-gradient-radial-success {
  background-image: radial-gradient(
    circle,
    hsla(94, 59%, 74%, 0.4),
    #6dbb30 70%
  ) !important;
}
.bg-gradient-radial-info {
  background-image: radial-gradient(
    circle,
    rgba(250, 254, 255, 0.4),
    #73e7f7 70%
  ) !important;
}
.bg-gradient-radial-warning {
  background-image: radial-gradient(
    circle,
    hsla(0, 0%, 100%, 0.4),
    #fad776 70%
  ) !important;
}
.bg-gradient-radial-danger {
  background-image: radial-gradient(
    circle,
    rgba(253, 214, 218, 0.4),
    #f64e60 70%
  ) !important;
}
.bg-gradient-radial-light {
  background-image: radial-gradient(
    circle,
    hsla(0, 0%, 100%, 0.4),
    #f8f9fa 70%
  ) !important;
}
.bg-gradient-radial-dark {
  background-image: radial-gradient(
    circle,
    hsla(0, 0%, 46%, 0.4),
    #2e2e2e 70%
  ) !important;
}
.bg-gradient-linear-default {
  background-color: #9d009f !important;
  background: linear-gradient(
    90deg,
    #9d009f 0,
    #6336bd 50%,
    #10c7a8
  ) !important;
}
.bg-gradient-linear-purple {
  background-color: #7a22a3 !important;
  background-image: linear-gradient(
    163deg,
    #5d34af,
    #5d34af 25%,
    #82009f 65%,
    #f7345e
  ) !important;
}
.bg-gradient-linear-indigo {
  background-color: #6536af !important;
  background-image: linear-gradient(#783bb1, #472fad) !important;
}
.bg-gradient-linear-danger {
  background-color: #de1f1f !important;
  background-image: linear-gradient(
    163deg,
    #732a67,
    #700 25%,
    #612727 65%,
    #de1f1f
  ) !important;
}
.bg-gradient-linear-success {
  background-color: #00775c !important;
  background-image: linear-gradient(#909816, #165d0d) !important;
}
.bg-gradient-linear-primary {
  background-color: #160092 !important;
  background-image: linear-gradient(
    90deg,
    #000f77,
    #3f286d 50%,
    #111954
  ) !important;
}
.bg-facebook {
  background-color: #3b5998 !important;
  color: #fff;
}
.bg-pinterest {
  background-color: #c8232c !important;
  color: #fff;
}
.bg-paypal {
  background-color: #00588b !important;
  color: #fff;
}
.bg-linkedin {
  background-color: #0e76a8 !important;
  color: #fff;
}
.bg-skype {
  background-color: #00aff0 !important;
  color: #fff;
}
.bg-twitter {
  background-color: #00acee !important;
  color: #fff;
}
.bg-youtube {
  background-color: #c4302b !important;
  color: #fff;
}
.bg-vimeo {
  background-color: #86c9ef !important;
  color: #fff;
}
.bg-googleplay {
  background-color: #dd4b39 !important;
  color: #fff;
}
.bg-snapchat {
  background-color: #fffc00 !important;
  color: #111 !important;
}
.bg-instagram {
  background-color: #3f729b !important;
  color: #fff;
}
.fill-primary {
  fill: var(--bs-primary) !important;
}
.bg-primary-soft .fill-primary {
  fill: #6d66ee !important;
}
.fill-secondary {
  fill: #6c757d !important;
}
.bg-secondary-soft .fill-secondary {
  fill: #78828a !important;
}
.fill-success {
  fill: #6dbb30 !important;
}
.bg-success-soft .fill-success {
  fill: #79cc38 !important;
}
.fill-info {
  fill: #73e7f7 !important;
}
.bg-info-soft .fill-info {
  fill: #8bebf8 !important;
}
.fill-warning {
  fill: #fad776 !important;
}
.bg-warning-soft .fill-warning {
  fill: #f8c945 !important;
}
.fill-danger {
  fill: #f64e60 !important;
}
.bg-danger-soft .fill-danger {
  fill: #f76676 !important;
}
.fill-light {
  fill: #f8f9fa !important;
}
.bg-light-soft .fill-light {
  fill: #fff !important;
}
.fill-dark {
  fill: #2e2e2e !important;
}
.bg-dark-soft .fill-dark {
  fill: #3b3b3b !important;
}
.fill-gray-100 {
  fill: #f9fbfd !important;
}
.fill-gray-200 {
  fill: #f1f4f8 !important;
}
.fill-gray-300 {
  fill: #d9e2ef !important;
}
.fill-gray-400 {
  fill: #c6d3e6 !important;
}
.fill-gray-500 {
  fill: #abbcd5 !important;
}
.fill-gray-600 {
  fill: #869ab8 !important;
}
.fill-gray-700 {
  fill: #506690 !important;
}
.fill-gray-800 {
  fill: #384c74 !important;
}
.fill-gray-900 {
  fill: #1b2a4e !important;
}
.fill-muted {
  fill: #506690 !important;
}
.svg-drawlines {
  stroke: #f7345e;
  stroke-width: 4;
  stroke-dasharray: 960px;
  stroke-dashoffset: 960px;
  animation: __svgDraw 2s ease-out normal forwards;
  animation-delay: 0.61s;
}
@keyframes __svgDraw {
  0% {
    stroke-dashoffset: 960px;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.scrollable-horizontal,
.scrollableVertical {
  -ms-scroll-chaining: none;
  -webkit-overscroll-behavior: contain;
  overscroll-behavior: contain;
  -webkit-scroll-behavior: smooth;
  scroll-behavior: smooth;
}
.scrollableVertical {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
}
.scrollable-horizontal {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  display: block;
  overflow-x: auto;
  position: relative;
  scrollbar-width: none;
  white-space: nowrap;
  width: auto;
  z-index: 100;
}
.scrollable-horizontal::-webkit-scrollbar {
  background: 0 0 !important;
  height: 0 !important;
  width: 0 !important;
}
.scrollable-horizontal::-webkit-scrollbar-thumb,
.scrollable-horizontal::-webkit-scrollbar-track {
  background: 0 0 !important;
  -webkit-box-shadow: none !important;
}
.scrollable-horizontal::-webkit-scrollbar-button,
.scrollable-horizontal::-webkit-scrollbar-thumb {
  height: 0 !important;
  outline: 0 !important;
  width: 0 !important;
}
.scrollable-horizontal::-webkit-scrollbar-button {
  background: 0 0 !important;
}
@media only screen and (min-width: 768px) {
  .scrollableVertical {
    scrollbar-color: #121212 #eee;
    scrollbar-width: thin;
  }
  .scrollable-styled-dark::-webkit-scrollbar,
  .scrollable-styled-dark::-webkit-scrollbar-thumb,
  .scrollable-styled-dark::-webkit-scrollbar-track,
  .scrollableStyledLight::-webkit-scrollbar,
  .scrollableStyledLight::-webkit-scrollbar-thumb,
  .scrollableStyledLight::-webkit-scrollbar-track,
  .scrollableVertical::-webkit-scrollbar,
  .scrollableVertical::-webkit-scrollbar-thumb,
  .scrollableVertical::-webkit-scrollbar-track {
    border-radius: 0.35rem;
    margin: 3px 0;
  }
  .scrollable-styled-dark::-webkit-scrollbar,
  .scrollableStyledLight::-webkit-scrollbar,
  .scrollableVertical::-webkit-scrollbar {
    width: 3px;
  }
  .scrollableStyledLight::-webkit-scrollbar,
  .scrollableStyledLight::-webkit-scrollbar-thumb,
  .scrollableStyledLight::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .scrollable-styled-dark::-webkit-scrollbar-track,
  .scrollableStyledLight::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px transparent;
  }
  .scrollableVertical:hover::-webkit-scrollbar {
    background-color: hsla(0, 0%, 100%, 0.1);
  }
  .scrollableVertical:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .scrollableVertical:hover::-webkit-scrollbar-track {
    background-color: hsla(0, 0%, 100%, 0.1);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
  .scrollableStyledLight:hover::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .scrollableStyledLight:hover::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 100%, 0.4);
  }
  .scrollableStyledLight:hover::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
  .scrollable-styled-dark:hover::-webkit-scrollbar {
    background-color: hsla(0, 0%, 100%, 0.1);
  }
  .scrollable-styled-dark:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .scrollable-styled-dark:hover::-webkit-scrollbar-track {
    background-color: hsla(0, 0%, 100%, 0.1);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
}
.hide {
  display: none;
}
.hide-empty:empty,
.hide-force {
  display: none !important;
}
.d-none-cloaked {
  box-shadow: none !important;
  height: 2px;
  opacity: 0 !important;
  position: absolute;
  width: 2px;
  z-index: -1;
}
@media only screen and (min-width: 992px) {
  .show-hover-container:not(:hover) .show-hover-item {
    transition: box-shadow 0.25s ease, transform 0.25s ease;
    visibility: hidden;
  }
  .show-hover-container:hover .show-hover-item {
    visibility: visible !important;
  }
}
.show-hover-container-all:not(:hover) .show-hover-item-all {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  visibility: hidden;
}
.show-hover-container-all:hover .show-hover-item-all {
  visibility: visible !important;
}
.hidden-xs-down,
.hidden-xs-up {
  display: none !important;
}
@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .hidden-sm-down {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .hidden-md-down {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .hidden-lg-down {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .hidden-xl-down {
    display: none !important;
  }
}
.vw-25 {
  width: 25vw !important;
}
.vh-25 {
  height: 25vh !important;
}
.min-vw-25 {
  min-width: 25vw !important;
}
.max-vw-25 {
  max-width: 25vw !important;
}
.max-vh-25,
.min-vh-25 {
  min-height: 25vh !important;
}
.vw-33 {
  width: 33vw !important;
}
.vh-33 {
  height: 33vh !important;
}
.min-vw-33 {
  min-width: 33vw !important;
}
.max-vw-33 {
  max-width: 33vw !important;
}
.max-vh-33,
.min-vh-33 {
  min-height: 33vh !important;
}
.vw-50 {
  width: 50vw !important;
}
.vh-50 {
  height: 50vh !important;
}
.min-vw-50 {
  min-width: 50vw !important;
}
.max-vw-50 {
  max-width: 50vw !important;
}
.max-vh-50,
.min-vh-50 {
  min-height: 50vh !important;
}
.vw-75 {
  width: 75vw !important;
}
.vh-75 {
  height: 75vh !important;
}
.min-vw-75 {
  min-width: 75vw !important;
}
.max-vw-75 {
  max-width: 75vw !important;
}
.max-vh-75,
.min-vh-75 {
  min-height: 75vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.max-vw-100 {
  max-width: 100vw !important;
}
.max-vh-100,
.min-vh-100 {
  min-height: 100vh !important;
}
@media only screen and (min-width: 1199px) {
  .vh-xl-25 {
    height: 25vh !important;
  }
  .max-vh-xl-25,
  .min-vh-xl-25 {
    min-height: 25vh !important;
  }
  .vh-xl-33 {
    height: 33vh !important;
  }
  .max-vh-xl-33,
  .min-vh-xl-33 {
    min-height: 33vh !important;
  }
  .vh-xl-50 {
    height: 50vh !important;
  }
  .max-vh-xl-50,
  .min-vh-xl-50 {
    min-height: 50vh !important;
  }
  .vh-xl-75 {
    height: 75vh !important;
  }
  .max-vh-xl-75,
  .min-vh-xl-75 {
    min-height: 75vh !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
  .max-vh-xl-100,
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }
}
@media only screen and (min-width: 991px) {
  .vh-lg-25 {
    height: 25vh !important;
  }
  .max-vh-lg-25,
  .min-vh-lg-25 {
    min-height: 25vh !important;
  }
  .vh-lg-33 {
    height: 33vh !important;
  }
  .max-vh-lg-33,
  .min-vh-lg-33 {
    min-height: 33vh !important;
  }
  .vh-lg-50 {
    height: 50vh !important;
  }
  .max-vh-lg-50,
  .min-vh-lg-50 {
    min-height: 50vh !important;
  }
  .vh-lg-75 {
    height: 75vh !important;
  }
  .max-vh-lg-75,
  .min-vh-lg-75 {
    min-height: 75vh !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .max-vh-lg-100,
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .vh-md-25 {
    height: 25vh !important;
  }
  .max-vh-md-25,
  .min-vh-md-25 {
    min-height: 25vh !important;
  }
  .vh-md-33 {
    height: 33vh !important;
  }
  .max-vh-md-33,
  .min-vh-md-33 {
    min-height: 33vh !important;
  }
  .vh-md-50 {
    height: 50vh !important;
  }
  .max-vh-md-50,
  .min-vh-md-50 {
    min-height: 50vh !important;
  }
  .vh-md-75 {
    height: 75vh !important;
  }
  .max-vh-md-75,
  .min-vh-md-75 {
    min-height: 75vh !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
  .max-vh-md-100,
  .min-vh-md-100 {
    min-height: 100vh !important;
  }
}
@media only screen and (min-width: 576px) {
  .vh-sm-25 {
    height: 25vh !important;
  }
  .max-vh-sm-25,
  .min-vh-sm-25 {
    min-height: 25vh !important;
  }
  .vh-sm-33 {
    height: 33vh !important;
  }
  .max-vh-sm-33,
  .min-vh-sm-33 {
    min-height: 33vh !important;
  }
  .vh-sm-50 {
    height: 50vh !important;
  }
  .max-vh-sm-50,
  .min-vh-sm-50 {
    min-height: 50vh !important;
  }
  .vh-sm-75 {
    height: 75vh !important;
  }
  .max-vh-sm-75,
  .min-vh-sm-75 {
    min-height: 75vh !important;
  }
  .vh-sm-100 {
    height: 100vh !important;
  }
  .max-vh-sm-100,
  .min-vh-sm-100 {
    min-height: 100vh !important;
  }
}
.min-w-0,
.mw-0 {
  min-width: 0 !important;
}
.mw-75 {
  max-width: 75% !important;
}
.mw-50 {
  max-width: 50% !important;
}
.mw-25 {
  max-width: 25% !important;
}
.w100 {
  width: 100px;
}
.w-150 {
  width: 150px;
}
.w-200 {
  width: 200px;
}
.w-250 {
  width: 250px;
}
.w300 {
  width: 300px;
}
.w-350 {
  width: 350px;
}
.w-400 {
  width: 400px;
}
.w-450 {
  width: 450px;
}
.w-500 {
  width: 500px;
}
.w-550 {
  width: 550px;
}
.w-600 {
  width: 600px;
}
.w-650 {
  width: 650px;
}
.max-w-100 {
  max-width: 100px !important;
}
.max-w-150 {
  max-width: 150px !important;
}
.max-w-200 {
  max-width: 200px !important;
}
.max-w-250 {
  max-width: 250px !important;
}
.max-w-300 {
  max-width: 300px !important;
}
.max-w-350 {
  max-width: 350px !important;
}
.max-w-400 {
  max-width: 400px !important;
}
.max-w-450 {
  max-width: 450px !important;
}
.max-w-500 {
  max-width: 500px !important;
}
.max-w-550 {
  max-width: 550px !important;
}
.max-w-600 {
  max-width: 600px !important;
}
.max-w-650 {
  max-width: 650px !important;
}
.max-w-700 {
  max-width: 700px !important;
}
.max-w-750 {
  max-width: 750px !important;
}
.max-w-800 {
  max-width: 800px !important;
}
.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.embed-responsive-16by9:before {
  padding-top: 56.25%;
}
.embed-responsive:before {
  content: "";
  display: block;
}
.custom-file {
  display: inline-block;
  margin-bottom: 0;
}
.custom-file,
.ustomFileInput {
  height: calc(1.5em + 0.75rem + 2px);
  position: relative;
  width: 100%;
}
.ustomFileInput {
  margin: 0;
  opacity: 0;
  overflow: hidden;
  z-index: 2;
}
.custom-control-label:before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.custom-file-label {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  font-weight: 400;
  height: calc(1.5em + 0.75rem + 2px);
  left: 0;
  line-height: 1.5;
  overflow: hidden;
  padding: 0.375rem 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-text {
  cursor: text;
}
.cursor-move,
.sortable-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}
.flex-none {
  flex: none !important;
}
.d-flex-align-middle {
  align-items: center;
  display: flex;
  justify-content: center;
}
.d-flex-1-0-auto {
  flex: 1 0 auto;
}
.d-flex-1-1-auto {
  flex: 1 1 auto !important;
}
.d-flex-inherit {
  flex: inherit !important;
}
.justify-content-inherit {
  justify-content: inherit !important;
}
.row-grid,
.ul-grid {
  border-left: 1px solid #f1f4f8;
  border-top: 1px solid #f1f4f8;
}
.row-grid > div,
.ul-grid > li {
  border-bottom: 1px solid #f1f4f8;
  border-right: 1px solid #f1f4f8;
  padding: 0;
}
.dInlineGrid {
  display: inline-grid !important;
}
.gap-auto-0 {
  grid-gap: 0;
  align-items: center;
  grid-auto-flow: column;
}
.gap-auto-1 {
  grid-gap: 0.25rem;
  align-items: center;
  grid-auto-flow: column;
}
.gap-auto-2 {
  grid-gap: 0.5rem;
  align-items: center;
  grid-auto-flow: column;
}
.gapAuto3 {
  grid-gap: 1rem;
  align-items: center;
  grid-auto-flow: column;
}
.gap-auto-4 {
  grid-gap: 1.5rem;
  align-items: center;
  grid-auto-flow: column;
}
.gap-auto-5 {
  grid-gap: 3rem;
  align-items: center;
  grid-auto-flow: column;
}
.gap-auto-6 {
  grid-gap: 4.5rem;
  align-items: center;
  grid-auto-flow: column;
}
.gap-auto-7 {
  grid-gap: 6rem;
  align-items: center;
  grid-auto-flow: column;
}
.gap-auto-8 {
  grid-gap: 8rem;
  align-items: center;
  grid-auto-flow: column;
}
.col-5th,
.col-lg-5th,
.col-md-5th,
.col-sm-5th,
.col-xl-5th {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.col-5th {
  flex: 0 0 20%;
  max-width: 20%;
}
@media only screen and (min-width: 576px) {
  .col-sm-5th {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-5th {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 960px) {
  .col-lg-5th {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1200px) {
  .col-xl-5th {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.gutters-xxs {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}
.gutters-xxs > [class*="col"] {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.gutters-xs {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.gutters-xs > [class*="col"] {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.gutters-sm {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.gutters-sm > [class*="col"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.gutters-md {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.gutters-md > [class*="col"] {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.gutters-lg {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.gutters-lg > [class*="col"] {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.gutters-xl {
  margin-left: -3rem;
  margin-right: -3rem;
}
.gutters-xl > [class*="col"] {
  padding-left: 3rem;
  padding-right: 3rem;
}
.row.col-border > div:not(:first-child) {
  border-left: 1px solid #f1f6fa;
}
@media only screen and (min-width: 576px) {
  .row.col-border-sm > div:not(:first-child) {
    border-left: 1px solid #f1f6fa;
  }
}
@media only screen and (min-width: 768px) {
  .row.col-border-md > div:not(:first-child) {
    border-left: 1px solid #f1f6fa;
  }
}
@media only screen and (min-width: 992px) {
  .row.col-border-lg > div:not(:first-child) {
    border-left: 1px solid #f1f6fa;
  }
}
.kanban-grid {
  flex-wrap: nowrap;
}
.kanban-grid > [class*="col"] {
  width: 350px;
}
.grid-notebook {
  background-attachment: local;
  background-image: -webkit-linear-gradient(
    top,
    transparent,
    transparent 1.9375rem,
    #e7eff8 0
  );
  background-size: 100% 2rem;
  display: block;
  font-size: 1rem;
  line-height: 2rem;
  line-height: 200%;
  padding: 0.455rem 1.5625rem 0;
}
.grid-notebook ol,
.grid-notebook p,
.grid-notebook ul {
  margin-bottom: 0 !important;
}
.grid-notebook .h1,
.grid-notebook .h2,
.grid-notebook .h3,
.grid-notebook .h4,
.grid-notebook .h5,
.grid-notebook .h6,
.grid-notebook h1,
.grid-notebook h2,
.grid-notebook h3,
.grid-notebook h4,
.grid-notebook h5,
.grid-notebook h6 {
  line-height: 2rem !important;
  margin-bottom: 0 !important;
}
.grid-notebook .h1,
.grid-notebook .h2,
.grid-notebook h1,
.grid-notebook h2 {
  font-size: 1.5625rem !important;
}
.grid-notebook .h3,
.grid-notebook h3 {
  font-size: 1.25rem !important;
}
.grid-notebook .h4,
.grid-notebook .h5,
.grid-notebook .h6,
.grid-notebook h4,
.grid-notebook h5,
.grid-notebook h6 {
  font-size: 1.125rem !important;
}
.line-height-0 {
  line-height: 0 !important;
}
.line-height-1 {
  line-height: 1 !important;
}
.line-height-inherit {
  line-height: inherit !important;
}
.d-table {
  height: 100%;
  position: relative;
  width: 100%;
}
.smaller {
  font-size: 0.75rem !important;
}
.textDashed {
  border-bottom: 1px dashed #506690;
}
.text-shadow-dark {
  text-shadow: rgba(0, 0, 0, 0.3) 0 1px 0;
}
.text-monospace {
  -webkit-font-smoothing: initial;
}
.text-gray-100 {
  color: #f9fbfd !important;
}
.text-gray-200 {
  color: #f1f4f8 !important;
}
.text-gray-300 {
  color: #d9e2ef !important;
}
.text-gray-400 {
  color: #c6d3e6 !important;
}
.text-gray-500 {
  color: #abbcd5 !important;
}
.text-gray-600 {
  color: #869ab8 !important;
}
.text-gray-700 {
  color: #506690 !important;
}
.text-gray-800 {
  color: #384c74 !important;
}
.text-gray-900 {
  color: #1b2a4e !important;
}
.linkMuted {
  color: #506690;
}
.link-dashed,
.linkMuted {
  text-decoration: none !important;
}
.link-dashed {
  border-bottom: 1px dashed var(--bs-primary) !important;
  color: var(--bs-primary) !important;
}
.link-dashed-hover,
.link-dashed:hover {
  border-bottom: 1px dashed var(--bs-primary);
}
.linkNormal {
  color: #1c0950;
}
.linkNormal:hover {
  color: var(--bs-primary);
}
.disabled {
  opacity: 0.65;
  pointer-events: none;
}
.text-primary-hover {
  transition: all 0.2s ease-in-out;
}
a.text-primary-hover:focus,
a.text-primary-hover:hover {
  color: var(--bs-primary) !important;
}
.text-secondary-hover {
  transition: all 0.2s ease-in-out;
}
a.text-secondary-hover:focus,
a.text-secondary-hover:hover {
  color: #6c757d !important;
}
.text-success-hover {
  transition: all 0.2s ease-in-out;
}
a.text-success-hover:focus,
a.text-success-hover:hover {
  color: #6dbb30 !important;
}
.text-info-hover {
  transition: all 0.2s ease-in-out;
}
a.text-info-hover:focus,
a.text-info-hover:hover {
  color: #73e7f7 !important;
}
.text-warning-hover {
  transition: all 0.2s ease-in-out;
}
a.text-warning-hover:focus,
a.text-warning-hover:hover {
  color: #fad776 !important;
}
.text-danger-hover {
  transition: all 0.2s ease-in-out;
}
a.text-danger-hover:focus,
a.text-danger-hover:hover {
  color: #f64e60 !important;
}
.text-light-hover {
  transition: all 0.2s ease-in-out;
}
a.text-light-hover:focus,
a.text-light-hover:hover {
  color: #f8f9fa !important;
}
.text-dark-hover {
  transition: all 0.2s ease-in-out;
}
a.text-dark-hover:focus,
a.text-dark-hover:hover {
  color: #2e2e2e !important;
}
.text-danger-gradient {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(-107deg, #82009f, #f7345e);
  -webkit-background-clip: text;
  color: #f7345e;
  display: inline-block;
  position: relative;
}
em.text-danger-gradient {
  font-style: normal;
  transform: skewX(-10deg);
}
.z-index-n1 {
  z-index: -1 !important;
}
.z-index-0 {
  z-index: 0 !important;
}
.z-index-1 {
  z-index: 1 !important;
}
.z-index-2 {
  z-index: 2 !important;
}
.z-index-3 {
  z-index: 3 !important;
}
.z-index-4 {
  z-index: 4 !important;
}
.z-index-5 {
  z-index: 5 !important;
}
.z-index-10 {
  z-index: 10 !important;
}
.z-index-99 {
  z-index: 99 !important;
}
.z-index-100 {
  z-index: 100 !important;
}
.z-index-1000 {
  z-index: 1000 !important;
}
.z-index-9999 {
  z-index: 9999 !important;
}
.z-index-11 {
  z-index: 11 !important;
}
.bull {
  border-radius: 50%;
  display: inline-block;
  flex: none;
  height: 6px;
  width: 6px;
}
.bull-sm {
  height: 4px;
  width: 4px;
}
.bull-lg {
  height: 8px;
  width: 8px;
}
.bull-xl {
  height: 12px;
  width: 12px;
}
.text-align-start {
  text-align: left !important;
}
.text-align-end {
  text-align: right !important;
}
.float-start {
  float: left !important;
  margin-right: 10px;
}
.floatEnd {
  float: right !important;
  margin-left: 10px;
}
.p--0-start {
  padding-left: 0 !important;
}
.p--0-end {
  padding-right: 0 !important;
}
.m--0-start {
  margin-left: 0 !important;
}
.m--0-end {
  margin-right: 0 !important;
}
.m-auto-start {
  margin-left: auto !important;
}
.m-auto-end {
  margin-right: auto !important;
}
.b--0-start {
  border-left: 0 !important;
}
.b--0-end {
  border-right: 0 !important;
}
.hide-ltr {
  display: none !important;
}
.animate-none {
  animation: none !important;
}
@keyframes __slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
  0% {
    opacity: 0;
    transform: translateY(0.8rem);
  }
}
.animate-slidein {
  animation-duration: 0.21s;
  -webkit-animation-duration: 0.21s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: __slideIn;
  -webkit-animation-name: __slideIn;
}
@keyframes __animateBlink {
  to {
    visibility: hidden;
  }
}
.animate-blink {
  animation: __animateBlink 1s steps(5, start) infinite;
}
.animate-reveal-down,
.animate-reveal-down-item > .reveal-item,
.animate-reveal-up,
.animate-reveal-up-item > .reveal-item {
  transform: translateZ(0);
  transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
}
.animate-reveal-down-item:hover > .reveal-item,
.animate-reveal-down:hover {
  transform: translate3d(0, 50px, 0);
}
.animate-reveal-up-item:hover > .reveal-item,
.animate-reveal-up:hover {
  transform: translate3d(0, -50px, 0);
}
@keyframes __utilFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animate-fadein {
  animation: __utilFadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
@keyframes __utilFadeInSlow {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
.animate-fadein__slow {
  animation-delay: 0.5s;
  animation-duration: 4s;
  animation-fill-mode: both;
  animation: __utilFadeInSlow 2s ease;
}
@keyframes __utilFadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
  0% {
    opacity: 0;
    transform: translateY(0.8rem);
  }
}
@keyframes __utilFadeInDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
  0% {
    opacity: 0;
    transform: translateY(-0.8rem);
  }
}
.animate-fadeinup {
  animation: __utilFadeInUp 0.25s ease-out forwards !important;
}
.animate-fadeindown {
  animation: __utilFadeInDown 0.25s ease-out forwards !important;
}
@keyframes __utilBounceIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  60% {
    opacity: 1;
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
.animate-bouncein {
  animation: __utilBounceIn 0.25s ease-out forwards;
}
@keyframes __utilBounceInUp {
  0% {
    opacity: 0;
    transform: translateY(600px);
  }
  25% {
    opacity: 1;
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}
.animate-bounceinup {
  animation-delay: 0.5s;
  animation-duration: 6s;
  animation-fill-mode: both;
  animation-name: __utilBounceInUp;
}
@keyframes __utilShine {
  1% {
    opacity: 0;
    transition-duration: 0.1s;
    transition-property: opacity;
  }
  25% {
    opacity: 0.1;
    transform: rotate(180deg);
    transition-duration: 0.1s;
  }
  50% {
    opacity: 1;
    transform: rotate(-90deg);
    transition-duration: 0.1s;
  }
  75% {
    opacity: 0.1;
    transform: rotate(30deg);
    transition-duration: 0.1s;
  }
  to {
    opacity: 0;
    transform: rotate(180deg);
    transition-duration: 0.1s;
  }
}
.animate-shine:after {
  animation: __utilShine 6s ease-in-out infinite;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  background: rgba(248, 249, 250, 0.3);
  background: linear-gradient(
    90deg,
    rgba(248, 249, 250, 0) 0,
    hsla(0, 0%, 100%, 0.3) 50%,
    rgba(248, 249, 250, 0)
  );
  border-radius: 50%;
  content: "";
  height: 120%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: -10%;
  transform: rotate(40deg);
  width: 120%;
  z-index: 0;
}
@keyframes __pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(80, 102, 144, 0);
  }
  to {
    box-shadow: 0 0 0 0 rgba(80, 102, 144, 0);
  }
}
@keyframes __pulsePrimary {
  0% {
    box-shadow: 0 0 0 0 rgba(87, 79, 236, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(87, 79, 236, 0);
  }
  to {
    box-shadow: 0 0 0 0 rgba(87, 79, 236, 0);
  }
}
@keyframes __pulseDanger {
  0% {
    box-shadow: 0 0 0 0 rgba(246, 78, 96, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(246, 78, 96, 0);
  }
  to {
    box-shadow: 0 0 0 0 rgba(246, 78, 96, 0);
  }
}
@keyframes __pulseWarning {
  0% {
    box-shadow: 0 0 0 0 hsla(44, 93%, 72%, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px hsla(44, 93%, 72%, 0);
  }
  to {
    box-shadow: 0 0 0 0 hsla(44, 93%, 72%, 0);
  }
}
@keyframes __pulseSuccess {
  0% {
    box-shadow: 0 0 0 0 rgba(109, 187, 48, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(109, 187, 48, 0);
  }
  to {
    box-shadow: 0 0 0 0 rgba(109, 187, 48, 0);
  }
}
.animate-pulse {
  animation: __pulse 2s infinite;
}
.animate-pulse-primary {
  animation: __pulsePrimary 2s infinite;
}
.animate-pulse-danger {
  animation: __pulseDanger 2s infinite;
}
.animate-pulse-warning {
  animation: __pulseWarning 2s infinite;
}
.animate-pulse-success {
  animation: __pulseSuccess 2s infinite;
}
.img-hover-zoom {
  background: #222f3e;
  border: 5px solid hsla(0, 0%, 100%, 0.05);
  border-radius: 0.5em;
  height: 300px;
  overflow: hidden;
}
.img-hover-zoom--empty {
  background-color: hsla(0, 0%, 100%, 0.1);
  border-width: 0;
}
.img-hover-zoom:not(.img-hover-zoom--empty):hover {
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
}
.img-hover-zoom--basic img {
  transition: transform 0.5s ease;
}
.img-hover-zoom--basic:hover img {
  transform: scale(1.5);
}
.img-hover-zoom--quick-zoom img {
  transform-origin: 0 0;
  transition: transform 0.25s, visibility 0.25s ease-in;
}
.img-hover-zoom--quick-zoom:hover img {
  transform: scale(2);
}
.img-hover-zoom--point-zoom img {
  transform-origin: 65% 75%;
  transition: transform 1s, filter 0.5s ease-out;
}
.img-hover-zoom--point-zoom:hover img {
  transform: scale(5);
}
.img-hover-zoom--zoom-n-rotate img {
  transition: transform 0.5s ease-in-out;
}
.img-hover-zoom--zoom-n-rotate:hover img {
  transform: scale(2) rotate(25deg);
}
.img-hover-zoom--slowmo img {
  filter: brightness(150%);
  transform-origin: 50% 65%;
  transition: transform 5s, filter 3s ease-in-out;
}
.img-hover-zoom--slowmo:hover img {
  filter: brightness(100%);
  transform: scale(3);
}
.img-hover-zoom--brightness img {
  filter: brightness(50%);
  transform-origin: center center;
  transition: transform 2s, filter 1.5s ease-in-out;
}
.img-hover-zoom--brightness:hover img {
  filter: brightness(100%);
  transform: scale(1.3);
}
.img-hover-zoom--zoom-n-pan-h img {
  transform: scale(1.4);
  transform-origin: 100% 0;
  transition: transform 0.5s ease-in-out;
}
.img-hover-zoom--zoom-n-pan-h:hover img {
  transform: scale(1.5) translateX(30%);
}
.img-hover-zoom--zoom-n-pan-v img {
  transform: scale(1.4);
  transform-origin: 0 0;
  transition: transform 0.5s ease-in-out;
}
.img-hover-zoom--zoom-n-pan-v:hover img {
  transform: scale(1.25) translateY(-30%);
}
.img-hover-zoom--blur img {
  filter: blur(2px);
  transform: scale(1.2);
  transition: transform 1s, filter 2s ease-in-out;
}
.img-hover-zoom--blur:hover img {
  filter: blur(0);
  transform: scale(1);
}
.img-hover-zoom--colorize img {
  filter: grayscale(100%);
  transition: transform 0.5s, filter 1.5s ease-in-out;
}
.img-hover-zoom--colorize:hover img {
  filter: grayscale(0);
  transform: scale(1.1);
}
@media only screen and (min-width: 991px) {
  .float-none-lg {
    float: none !important;
  }
  .b--0-lg,
  .b-0-lg {
    border: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: 1px solid #dde4ea;
  }
  .border-top-lg {
    border-top: 1px solid #dde4ea;
  }
  .m--0-lg,
  .m-0-lg,
  .p--0-lg,
  .p-0-lg {
    border: 0 !important;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .text-center-md {
    text-align: center !important;
  }
  .text-start-md {
    text-align: left !important;
  }
  .float-none-md {
    float: none !important;
  }
  .d-block-md {
    display: block !important;
  }
  .btn.d-block-md {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .position-absolute-md {
    position: absolute !important;
  }
  .position-relative-md {
    position: relative !important;
  }
  .position-fixed-md {
    position: fixed !important;
  }
  .shadow-none-md {
    box-shadow: none !important;
  }
  .m--0-md,
  .m-0-md {
    margin: 0 !important;
  }
  .mt--0-md,
  .mt-0-md {
    margin-top: 0 !important;
  }
  .ml--0-md,
  .ml-0-md {
    margin-left: 0 !important;
  }
  .mr--0-md,
  .mr-0-md {
    margin-right: 0 !important;
  }
  .mb--0-md,
  .mb-0-md {
    margin-bottom: 0 !important;
  }
  .m--15-md,
  .m-15-md {
    margin: 15px !important;
  }
  .p--0-md,
  .p-0-md {
    padding: 0 !important;
  }
  .pt--0-md,
  .pt-0-md {
    padding-top: 0 !important;
  }
  .pl--0-md,
  .pl-0-md {
    padding-left: 0 !important;
  }
  .pr--0-md,
  .pr-0-md {
    padding-right: 0 !important;
  }
  .pb--0-md,
  .pb-0-md {
    padding-bottom: 0 !important;
  }
  .p--15-md,
  .p-15-md {
    padding: 15px !important;
  }
  .b--0-md,
  .b-0-md {
    border: 0 !important;
  }
  .bt--0-md,
  .bt-0-md {
    border-top: 0 !important;
  }
  .bl--0-md,
  .bl-0-md {
    border-left: 0 !important;
  }
  .br--0-md,
  .br-0-md {
    border-right: 0 !important;
  }
  .bb--0-md,
  .bb-0-md {
    border-bottom: 0 !important;
  }
  .border-bottom-md {
    border-bottom: 1px solid #dde4ea;
  }
  .border-top-md {
    border-top: 1px solid #dde4ea;
  }
  .r--0-md,
  .r-0-md,
  .round-0-md,
  .rounded-0-md {
    border-radius: 0 !important;
  }
  .w-100-md {
    min-width: 0 !important;
    width: 100% !important;
  }
  .w-100-md,
  .w-100vh-md {
    max-width: 100% !important;
  }
  .w-100vh-md {
    width: 100vh !important;
  }
  .w-auto-md {
    width: auto !important;
  }
  .h-100-md {
    height: auto;
    min-height: 100% !important;
  }
  .h-100vh-md {
    height: auto;
    min-height: 100vh !important;
  }
  .h-auto-md {
    height: auto !important;
    max-height: auto !important;
    min-height: auto !important;
  }
  .rounded-circle-md {
    border-radius: 50% !important;
  }
  .overlay-none-md:after {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  .text-center-xs {
    text-align: center !important;
  }
  .text-start-xs {
    text-align: left !important;
  }
  .float-none-xs {
    float: none !important;
  }
  .d-block-xs {
    display: block !important;
  }
  .btn.d-block-xs {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .position-absolute-xs {
    position: absolute !important;
  }
  .position-relative-xs {
    position: relative !important;
  }
  .position-fixed-xs {
    position: fixed !important;
  }
  .shadow-none-xs {
    box-shadow: none !important;
  }
  .m--0-xs,
  .m-0-xs {
    margin: 0 !important;
  }
  .mt--0-xs,
  .mt-0-xs {
    margin-top: 0 !important;
  }
  .ml--0-xs,
  .ml-0-xs {
    margin-left: 0 !important;
  }
  .mr--0-xs,
  .mr-0-xs {
    margin-right: 0 !important;
  }
  .mb--0-xs,
  .mb-0-xs {
    margin-bottom: 0 !important;
  }
  .m--15-xs,
  .m-15-xs {
    margin: 15px !important;
  }
  .p--0-xs,
  .p-0-xs {
    padding: 0 !important;
  }
  .pt--0-xs,
  .pt-0-xs {
    padding-top: 0 !important;
  }
  .pl--0-xs,
  .pl-0-xs {
    padding-left: 0 !important;
  }
  .pr--0-xs,
  .pr-0-xs {
    padding-right: 0 !important;
  }
  .pb--0-xs,
  .pb-0-xs {
    padding-bottom: 0 !important;
  }
  .p--15-xs,
  .p-15-xs {
    padding: 15px !important;
  }
  .b--0-xs,
  .b-0-xs {
    border: 0 !important;
  }
  .bt--0-xs,
  .bt-0-xs {
    border-top: 0 !important;
  }
  .bl--0-xs,
  .bl-0-xs {
    border-left: 0 !important;
  }
  .br--0-xs,
  .br-0-xs {
    border-right: 0 !important;
  }
  .bb--0-xs,
  .bb-0-xs {
    border-bottom: 0 !important;
  }
  .border-bottom-xs {
    border-bottom: 1px solid #dde4ea;
  }
  .border-top-xs {
    border-top: 1px solid #dde4ea;
  }
  .r--0-xs,
  .r-0-xs,
  .round-0-xs,
  .rounded-0-xs {
    border-radius: 0 !important;
  }
  .w-100-xs {
    min-width: 0 !important;
    width: 100% !important;
  }
  .w-100-xs,
  .w-100vh-xs {
    max-width: 100% !important;
  }
  .w-100vh-xs {
    width: 100vh !important;
  }
  .w-auto-xs {
    width: auto !important;
  }
  .h-100-xs {
    height: auto;
    min-height: 100% !important;
  }
  .h-100vh-xs {
    height: auto;
    min-height: 100vh !important;
  }
  .h-auto-xs {
    height: auto !important;
    max-height: auto !important;
    min-height: auto !important;
  }
  .rounded-circle-xs {
    border-radius: 50% !important;
  }
  .overlay-none-xs:after {
    display: none !important;
  }
  .gutters-xxs--xs {
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }
  .gutters-xxs--xs > [class*="col"] {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }
  .gutters-xs--xs {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .gutters-xs--xs > [class*="col"] {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .gutters-sm--xs {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .gutters-sm--xs > [class*="col"] {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .gutters-md--xs {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .gutters-md--xs > [class*="col"] {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .m-1-xs {
    margin: 0.25rem !important;
  }
  .m-2-xs {
    margin: 0.5rem !important;
  }
  .m-3-xs {
    margin: 1rem !important;
  }
  .m-4-xs {
    margin: 1.5rem !important;
  }
  .m-5-xs {
    margin: 3rem !important;
  }
  .ml-1-xs,
  .mx-1-xs {
    margin-left: 0.25rem !important;
  }
  .ml-2-xs,
  .mx-2-xs {
    margin-left: 0.5rem !important;
  }
  .ml-3-xs,
  .mx-3-xs {
    margin-left: 1rem !important;
  }
  .ml-4-xs,
  .mx-4-xs {
    margin-left: 1.5rem !important;
  }
  .ml-5-xs,
  .mx-5-xs {
    margin-left: 3rem !important;
  }
  .mr-1-xs,
  .mx-1-xs {
    margin-right: 0.25rem !important;
  }
  .mr-2-xs,
  .mx-2-xs {
    margin-right: 0.5rem !important;
  }
  .mr-3-xs,
  .mx-3-xs {
    margin-right: 1rem !important;
  }
  .mr-4-xs,
  .mx-4-xs {
    margin-right: 1.5rem !important;
  }
  .mr-5-xs,
  .mx-5-xs {
    margin-right: 3rem !important;
  }
  .mt-1-xs,
  .my-1-xs {
    margin-top: 0.25rem !important;
  }
  .mt-2-xs,
  .my-2-xs {
    margin-top: 0.5rem !important;
  }
  .mt-3-xs,
  .my-3-xs {
    margin-top: 1rem !important;
  }
  .mt-4-xs,
  .my-4-xs {
    margin-top: 1.5rem !important;
  }
  .mt-5-xs,
  .my-5-xs {
    margin-top: 3rem !important;
  }
  .mb-1-xs,
  .my-1-xs {
    margin-bottom: 0.25rem !important;
  }
  .mb-2-xs,
  .my-2-xs {
    margin-bottom: 0.5rem !important;
  }
  .mb-3-xs,
  .my-3-xs {
    margin-bottom: 1rem !important;
  }
  .mb-4-xs,
  .my-4-xs {
    margin-bottom: 1.5rem !important;
  }
  .mb-5-xs,
  .my-5-xs {
    margin-bottom: 3rem !important;
  }
  .p-1-xs {
    padding: 0.25rem !important;
  }
  .p-2-xs {
    padding: 0.5rem !important;
  }
  .p-3-xs {
    padding: 1rem !important;
  }
  .p-4-xs {
    padding: 1.5rem !important;
  }
  .p-5-xs {
    padding: 3rem !important;
  }
  .pl-1-xs,
  .px-1-xs {
    padding-left: 0.25rem !important;
  }
  .pl-2-xs,
  .px-2-xs {
    padding-left: 0.5rem !important;
  }
  .pl-3-xs,
  .px-3-xs {
    padding-left: 1rem !important;
  }
  .pl-4-xs,
  .px-4-xs {
    padding-left: 1.5rem !important;
  }
  .pl-5-xs,
  .px-5-xs {
    padding-left: 3rem !important;
  }
  .pr-1-xs,
  .px-1-xs {
    padding-right: 0.25rem !important;
  }
  .pr-2-xs,
  .px-2-xs {
    padding-right: 0.5rem !important;
  }
  .pr-3-xs,
  .px-3-xs {
    padding-right: 1rem !important;
  }
  .pr-4-xs,
  .px-4-xs {
    padding-right: 1.5rem !important;
  }
  .pr-5-xs,
  .px-5-xs {
    padding-right: 3rem !important;
  }
  .pt-1-xs,
  .py-1-xs {
    padding-top: 0.25rem !important;
  }
  .pt-2-xs,
  .py-2-xs {
    padding-top: 0.5rem !important;
  }
  .pt-3-xs,
  .py-3-xs {
    padding-top: 1rem !important;
  }
  .pt-4-xs,
  .py-4-xs {
    padding-top: 1.5rem !important;
  }
  .pt-5-xs,
  .py-5-xs {
    padding-top: 3rem !important;
  }
  .pb-1-xs,
  .py-1-xs {
    padding-bottom: 0.25rem !important;
  }
  .pb-2-xs,
  .py-2-xs {
    padding-bottom: 0.5rem !important;
  }
  .pb-3-xs,
  .py-3-xs {
    padding-bottom: 1rem !important;
  }
  .pb-4-xs,
  .py-4-xs {
    padding-bottom: 1.5rem !important;
  }
  .pb-5-xs,
  .py-5-xs {
    padding-bottom: 3rem !important;
  }
}
@media print {
  @page {
    margin: 0 -6cm;
  }
  .row > * {
    float: left;
  }
  .colLg12,
  .col-md-12,
  .colSm12,
  .col-xl-12 {
    width: 100%;
  }
  .col-lg-9,
  .col-md-9,
  .col-sm-9,
  .col-xl-9 {
    width: 75%;
  }
  .col-lg-8,
  .col-md-8,
  .col-sm-8,
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-lg-7,
  .col-md-7,
  .col-sm-7,
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-lg-6,
  .col-md-6,
  .colSm6,
  .col-xl-6 {
    width: 50%;
  }
  .col-lg-4,
  .col-md-4,
  .col-sm-4,
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-lg-3,
  .col-md-3,
  .colSm3,
  .col-xl-3 {
    width: 25%;
  }
  .text-lg-start,
  .textMdStart,
  .text-sm-start,
  .text-xl-start {
    text-align: left !important;
  }
  .text-lg-end,
  .text-md-end,
  .text-sm-end,
  .text-xl-end {
    text-align: right !important;
  }
  .hide-print {
    display: none !important;
    visibility: hidden !important;
  }
  .shadow-none-print {
    box-shadow: none !important;
  }
  .border-none-print {
    border: 0 !important;
  }
  .padding-0-print {
    padding: 0 !important;
  }
}
.text-red-100 {
  color: #ffcdd2 !important;
}
.text-red-200 {
  color: #ef9a9a !important;
}
.text-red-300 {
  color: #e57373 !important;
}
.text-red-400 {
  color: #ef5350 !important;
}
.text-red-500 {
  color: #f44336 !important;
}
.text-red-600 {
  color: #e53935 !important;
}
.text-red-700 {
  color: #d32f2f !important;
}
.text-red-800 {
  color: #c62828 !important;
}
.text-red-900 {
  color: #b71c1c !important;
}
.text-pink-100 {
  color: #f8bbd0 !important;
}
.text-pink-200 {
  color: #f48fb1 !important;
}
.text-pink-300 {
  color: #f06292 !important;
}
.text-pink-400 {
  color: #ec407a !important;
}
.text-pink-500 {
  color: #e91e63 !important;
}
.text-pink-600 {
  color: #d81b60 !important;
}
.text-pink-700 {
  color: #c2185b !important;
}
.text-pink-800 {
  color: #ad1457 !important;
}
.text-pink-900 {
  color: #880e4f !important;
}
.text-purple-100 {
  color: #e1bee7 !important;
}
.text-purple-200 {
  color: #ce93d8 !important;
}
.text-purple-300 {
  color: #ba68c8 !important;
}
.text-purple-400 {
  color: #ab47bc !important;
}
.text-purple-500 {
  color: #9c27b0 !important;
}
.text-purple-600 {
  color: #8e24aa !important;
}
.text-purple-700 {
  color: #7b1fa2 !important;
}
.text-purple-800 {
  color: #6a1b9a !important;
}
.text-purple-900 {
  color: #4a148c !important;
}
.text-deep-purple-100 {
  color: #d1c4e9 !important;
}
.text-deep-purple-200 {
  color: #b39ddb !important;
}
.text-deep-purple-300 {
  color: #9575cd !important;
}
.text-deep-purple-400 {
  color: #7e57c2 !important;
}
.text-deep-purple-500 {
  color: #673ab7 !important;
}
.text-deep-purple-600 {
  color: #5e35b1 !important;
}
.text-deep-purple-700 {
  color: #512da8 !important;
}
.text-deep-purple-800 {
  color: #4527a0 !important;
}
.text-deep-purple-900 {
  color: #311b92 !important;
}
.text-indigo-100 {
  color: #c5cae9 !important;
}
.text-indigo-200 {
  color: #9fa8da !important;
}
.text-indigo-300 {
  color: #7986cb !important;
}
.text-indigo-400 {
  color: #5c6bc0 !important;
}
.text-indigo-500 {
  color: #3f51b5 !important;
}
.text-indigo-600 {
  color: #3949ab !important;
}
.text-indigo-700 {
  color: #303f9f !important;
}
.text-indigo-800 {
  color: #283593 !important;
}
.text-indigo-900 {
  color: #1a237e !important;
}
.text-blue-100 {
  color: #bbdefb !important;
}
.text-blue-200 {
  color: #90caf9 !important;
}
.text-blue-300 {
  color: #64b5f6 !important;
}
.text-blue-400 {
  color: #42a5f5 !important;
}
.text-blue-500 {
  color: #2196f3 !important;
}
.text-blue-600 {
  color: #1e88e5 !important;
}
.text-blue-700 {
  color: #1976d2 !important;
}
.text-blue-800 {
  color: #1565c0 !important;
}
.text-blue-900 {
  color: #0d47a1 !important;
}
.text-light-blue-100 {
  color: #b3e5fc !important;
}
.text-light-blue-200 {
  color: #81d4fa !important;
}
.text-light-blue-300 {
  color: #4fc3f7 !important;
}
.text-light-blue-400 {
  color: #29b6f6 !important;
}
.text-light-blue-500 {
  color: #03a9f4 !important;
}
.text-light-blue-600 {
  color: #039be5 !important;
}
.text-light-blue-700 {
  color: #0288d1 !important;
}
.text-light-blue-800 {
  color: #0277bd !important;
}
.text-light-blue-900 {
  color: #01579b !important;
}
.text-cyan-100 {
  color: #b2ebf2 !important;
}
.text-cyan-200 {
  color: #80deea !important;
}
.text-cyan-300 {
  color: #4dd0e1 !important;
}
.text-cyan-400 {
  color: #26c6da !important;
}
.text-cyan-500 {
  color: #00bcd4 !important;
}
.text-cyan-600 {
  color: #00acc1 !important;
}
.text-cyan-700 {
  color: #0097a7 !important;
}
.text-cyan-800 {
  color: #00838f !important;
}
.text-cyan-900 {
  color: #006064 !important;
}
.text-teal-100 {
  color: #b2dfdb !important;
}
.text-teal-200 {
  color: #80cbc4 !important;
}
.text-teal-300 {
  color: #4db6ac !important;
}
.text-teal-400 {
  color: #26a69a !important;
}
.text-teal-500 {
  color: #009688 !important;
}
.text-teal-600 {
  color: #00897b !important;
}
.text-teal-700 {
  color: #00796b !important;
}
.text-teal-800 {
  color: #00695c !important;
}
.text-teal-900 {
  color: #004d40 !important;
}
.text-green-100 {
  color: #c8e6c9 !important;
}
.text-green-200 {
  color: #a5d6a7 !important;
}
.text-green-300 {
  color: #81c784 !important;
}
.text-green-400 {
  color: #66bb6a !important;
}
.text-green-500 {
  color: #4caf50 !important;
}
.text-green-600 {
  color: #43a047 !important;
}
.text-green-700 {
  color: #388e3c !important;
}
.text-green-800 {
  color: #2e7d32 !important;
}
.text-green-900 {
  color: #1b5e20 !important;
}
.text-light-green-100 {
  color: #dcedc8 !important;
}
.text-light-green-200 {
  color: #c5e1a5 !important;
}
.text-light-green-300 {
  color: #aed581 !important;
}
.text-light-green-400 {
  color: #9ccc65 !important;
}
.text-light-green-500 {
  color: #8bc34a !important;
}
.text-light-green-600 {
  color: #7cb342 !important;
}
.text-light-green-700 {
  color: #689f38 !important;
}
.text-light-green-800 {
  color: #558b2f !important;
}
.text-light-green-900 {
  color: #33691e !important;
}
.text-lime-100 {
  color: #f0f4c3 !important;
}
.text-lime-200 {
  color: #e6ee9c !important;
}
.text-lime-300 {
  color: #dce775 !important;
}
.text-lime-400 {
  color: #d4e157 !important;
}
.text-lime-500 {
  color: #cddc39 !important;
}
.text-lime-600 {
  color: #c0ca33 !important;
}
.text-lime-700 {
  color: #afb42b !important;
}
.text-lime-800 {
  color: #9e9d24 !important;
}
.text-lime-900 {
  color: #827717 !important;
}
.text-yellow-100 {
  color: #fff9c4 !important;
}
.text-yellow-200 {
  color: #fff59d !important;
}
.text-yellow-300 {
  color: #fff176 !important;
}
.text-yellow-400 {
  color: #ffee58 !important;
}
.text-yellow-500 {
  color: #ffeb3b !important;
}
.text-yellow-600 {
  color: #fdd835 !important;
}
.text-yellow-700 {
  color: #fbc02d !important;
}
.text-yellow-800 {
  color: #f9a825 !important;
}
.text-yellow-900 {
  color: #f57f17 !important;
}
.text-amber-100 {
  color: #ffecb3 !important;
}
.text-amber-200 {
  color: #ffe082 !important;
}
.text-amber-300 {
  color: #ffd54f !important;
}
.text-amber-400 {
  color: #ffca28 !important;
}
.text-amber-500 {
  color: #ffc107 !important;
}
.text-amber-600 {
  color: #ffb300 !important;
}
.text-amber-700 {
  color: #ffa000 !important;
}
.text-amber-800 {
  color: #ff8f00 !important;
}
.text-amber-900 {
  color: #ff6f00 !important;
}
.text-orange-100 {
  color: #ffe0b2 !important;
}
.text-orange-200 {
  color: #ffcc80 !important;
}
.text-orange-300 {
  color: #ffb74d !important;
}
.text-orange-400 {
  color: #ffa726 !important;
}
.text-orange-500 {
  color: #ff9800 !important;
}
.text-orange-600 {
  color: #fb8c00 !important;
}
.text-orange-700 {
  color: #f57c00 !important;
}
.text-orange-800 {
  color: #ef6c00 !important;
}
.text-orange-900 {
  color: #e65100 !important;
}
.text-deep-orange-100 {
  color: #ffccbc !important;
}
.text-deep-orange-200 {
  color: #ffab91 !important;
}
.text-deep-orange-300 {
  color: #ff8a65 !important;
}
.text-deep-orange-400 {
  color: #ff7043 !important;
}
.text-deep-orange-500 {
  color: #ff5722 !important;
}
.text-deep-orange-600 {
  color: #f4511e !important;
}
.text-deep-orange-700 {
  color: #e64a19 !important;
}
.text-deep-orange-800 {
  color: #d84315 !important;
}
.text-deep-orange-900 {
  color: #bf360c !important;
}
.text-brown-100 {
  color: #d7ccc8 !important;
}
.text-brown-200 {
  color: #bcaaa4 !important;
}
.text-brown-300 {
  color: #a1887f !important;
}
.text-brown-400 {
  color: #8d6e63 !important;
}
.text-brown-500 {
  color: #795548 !important;
}
.text-brown-600 {
  color: #6d4c41 !important;
}
.text-brown-700 {
  color: #5d4037 !important;
}
.text-brown-800 {
  color: #4e342e !important;
}
.text-brown-900 {
  color: #3e2723 !important;
}
.text-blue-gray-100 {
  color: #cfd8dc !important;
}
.text-blue-gray-200 {
  color: #b0bec5 !important;
}
.text-blue-gray-300 {
  color: #90a4ae !important;
}
.text-blue-gray-400 {
  color: #78909c !important;
}
.text-blue-gray-500 {
  color: #607d8b !important;
}
.text-blue-gray-600 {
  color: #546e7a !important;
}
.text-blue-gray-700 {
  color: #455a64 !important;
}
.text-blue-gray-800 {
  color: #37474f !important;
}
.text-blue-gray-900 {
  color: #263238 !important;
}
.avatar {
  align-items: center;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.35rem;
  display: inline-flex;
  font-weight: 500;
  height: 3.125rem;
  justify-content: center;
  position: relative;
  width: 3.125rem;
}
.avatar > i {
  font-style: normal;
  position: absolute;
}
.avatar-border {
  border: 0.125rem solid #fff;
}
.avatar-border-lg {
  border: 0.25rem solid #fff;
}
.avatar-status {
  border: 0.125rem solid #fff;
  border-radius: 50%;
  bottom: 0;
  font-size: 0.5rem;
  height: 0.7rem;
  width: 0.7rem;
}
.avatar:not(.roundedCircle) .avatar-status {
  margin: -0.35rem;
}
.avatar-group {
  align-items: center;
  display: inline-flex;
}
.avatar-group > .avatar:hover {
  z-index: 1;
}
.avatar-xs {
  font-size: 0.75rem;
  height: 1.75rem !important;
  width: 1.75rem !important;
}
.avatar-group > .avatar-xs:not(:first-child) {
  margin-left: -0.58333rem;
}
.avatar-sm {
  font-size: 0.875rem;
  height: 2.6875rem !important;
  width: 2.6875rem !important;
}
.avatar-group > .avatar-sm:not(:first-child) {
  margin-left: -0.89583rem;
}
.avatar-md {
  font-size: 1rem;
  height: 3.125rem !important;
  width: 3.125rem !important;
}
.avatar-group > .avatar-md:not(:first-child) {
  margin-left: -1.04167rem;
}
.avatar-lg {
  font-size: 1.1rem;
  height: 4.25rem !important;
  width: 4.25rem !important;
}
.avatar-group > .avatar-lg:not(:first-child) {
  margin-left: -1.41667rem;
}
.avatar-xl {
  font-size: 1.3rem;
  height: 5.625rem !important;
  width: 5.625rem !important;
}
.avatar-group > .avatar-xl:not(:first-child) {
  margin-left: -1.875rem;
}
.avatar-xxl {
  height: 7rem !important;
  width: 7rem !important;
}
.avatar-group > .avatar-xxl:not(:first-child) {
  margin-left: -2.33333rem;
}
.arrow {
  border: 17px solid transparent;
  display: inline-block;
  height: 0;
  position: absolute;
  top: 0;
  width: 0;
}
.arrow-left,
.arrow-start {
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  border-right: 10px solid #f9fbfd;
  border-top-color: transparent !important;
  left: -30px;
  margin-left: 3px;
}
.arrow-end,
.arrow-right {
  border-bottom-color: transparent !important;
  border-left: 10px solid #f9fbfd;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  margin-right: 3px;
  right: -30px;
}
.arrow-top {
  border-bottom: 10px solid #f9fbfd;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  bottom: auto !important;
  top: -27px;
}
.arrow-bottom {
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top: 10px solid #f9fbfd;
  bottom: -27px;
  top: auto !important;
}
.arrow-lg {
  border: 34px solid transparent;
}
.arrow-lg.arrow-left,
.arrow-lg.arrow-start {
  border-right: 20px solid #f9fbfd;
  left: -60px;
  margin-left: 6px;
}
.arrow-lg.arrow-end,
.arrow-lg.arrow-right {
  border-left: 20px solid #f9fbfd;
  margin-right: 6px;
  right: -60px;
}
.arrow-lg.arrow-top {
  border-bottom: 20px solid #f9fbfd;
  top: -54px;
}
.arrow-lg.arrow-bottom {
  border-top: 20px solid #f9fbfd;
  bottom: -54px;
}
.arrow-center.arrow-bottom,
.arrow-center.arrow-top {
  left: 50% !important;
  margin-left: -17px !important;
}
.arrow-center.arrow-end,
.arrow-center.arrow-left,
.arrow-center.arrow-right,
.arrow-center.arrow-start {
  margin-top: -17px !important;
  top: 50% !important;
}
.arrow-lg.arrow-center.arrow-bottom,
.arrow-lg.arrow-center.arrow-top {
  margin-left: -34px !important;
}
.arrow-lg.arrow-center.arrow-end,
.arrow-lg.arrow-center.arrow-left,
.arrow-lg.arrow-center.arrow-right,
.arrow-lg.arrow-center.arrow-start {
  margin-top: -34px !important;
}
@media only screen and (min-width: 992px) {
  .stretch-end {
    border-radius: 0.35rem;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    margin-right: -19.6vw !important;
    overflow: hidden;
  }
  .stretch-start {
    border-radius: 0.35rem;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-left: -19.6vw !important;
    overflow: hidden;
  }
}
.block-img-end .block-img,
.block-img-start .block-img {
  border-radius: 0.35rem;
  overflow: hidden;
  position: relative;
}
@media only screen and (min-width: 992px) {
  .block-img-end .block-img-2,
  .block-img-start .block-img-2 {
    margin-top: -400px;
  }
  .block-img-start .block-img-1 {
    left: -78%;
    top: -80px;
  }
  .block-img-start .block-img-2 {
    left: -10%;
  }
  .block-img-start .block-img-3 {
    left: -78%;
    margin-top: -300px;
  }
  .block-img-end .block-img-1 {
    right: -78%;
    top: -80px;
  }
  .block-img-end .block-img-2 {
    right: -10%;
  }
  .block-img-end .block-img-3 {
    margin-top: -300px;
    right: -78%;
  }
}
@media only screen and (max-width: 1199px) {
  .block-img-end .block-img,
  .block-img-start .block-img {
    display: inline-block;
    left: 0;
    position: relative;
    top: 0;
    width: 50%;
  }
  .block-img-end .block-img-1,
  .block-img-start .block-img-1 {
    left: 0;
  }
  .block-img-end .block-img-2,
  .block-img-start .block-img-2 {
    left: 25%;
    margin-top: -100%;
  }
  .block-img-end .block-img-3,
  .block-img-start .block-img-3 {
    left: 50%;
    margin-top: -100%;
  }
}
.block-img-card {
  display: block;
  position: relative;
}
.block-img-card .block-img {
  border-radius: 0.35rem;
  display: inline-block;
  left: 0;
  width: 50%;
}
.block-img-card .block-img-1 {
  left: 0;
  position: relative;
  top: 0;
}
.block-img-card .block-img-2 {
  left: 25%;
  position: absolute;
  top: 5%;
}
.block-img-card .block-img-3 {
  left: 50%;
  position: absolute;
  top: 10%;
}
@media only screen and (min-width: 768px) {
  body:not(.layoutAdmin) .section,
  body:not(.layoutAdmin) section {
    padding: 100px 0;
  }
}
@media only screen and (max-width: 768px) {
  body:not(.layoutAdmin) .section,
  body:not(.layoutAdmin) section {
    padding: 80px 0;
  }
}
.close {
  background: 0 0;
  border: 0;
  color: #000;
  cursor: pointer;
  float: right;
  font-size: 1.59375rem;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  padding: 0;
  text-shadow: 0 1px 0 #fff;
}
.close:hover {
  opacity: 1;
}
.btn:not(.roundedCircle) > i,
ul.list-icons > li > i {
  display: inline-block;
  margin-right: 10px;
}
.btn:not(.roundedCircle) > span + i,
.btn:not(.roundedCircle) > span + svg,
.btn > .groupIcon + span,
.btn > span + .groupIcon {
  display: inline-block;
  margin-left: 10px;
  margin-right: 0;
}
.btn + .btn {
  margin-left: 0.5rem;
}
button.close .fi {
  font-size: 18px;
}
.btn-soft-static.btnPrimary:not([aria-expanded="true"]):not(.active),
.btn-soft.btnPrimary:not(:hover):not([aria-expanded="true"]):not(.active) {
  background-color: #f1f0fd;
  border-color: #f1f0fd;
  color: #2117d7;
}
.btn-soft-static.btn-secondary:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-secondary:not(:hover):not([aria-expanded="true"]):not(.active) {
  background-color: #f0f2f2;
  border-color: #f0f2f2;
  color: #494f54;
}
.btn-soft-static.btn-success:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-success:not(:hover):not([aria-expanded="true"]):not(.active) {
  background-color: #e4f5d7;
  border-color: #e4f5d7;
  color: #4a7e20;
}
.btn-soft-static.btn-info:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-info:not(:hover):not([aria-expanded="true"]):not(.active) {
  background-color: #bbf3fb;
  border-color: #bbf3fb;
  color: #2bdbf3;
}
.btn-soft-static.btn-warning:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-warning:not(:hover):not([aria-expanded="true"]):not(.active) {
  background-color: #fdedc0;
  border-color: #fdedc0;
  color: #f7c12c;
}
.btn-soft-static.btn-danger:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-danger:not(:hover):not([aria-expanded="true"]):not(.active) {
  background-color: #feeef0;
  border-color: #feeef0;
  color: #ec0c24;
}
.btn-soft-static.btn-light:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-light:not(:hover):not([aria-expanded="true"]):not(.active) {
  background-color: #fff;
  border-color: #fff;
  color: #cbd3da;
}
.btn-soft-static.btn-dark:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-dark:not(:hover):not([aria-expanded="true"]):not(.active) {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
  color: #080808;
}
.btnPrimary:not(.btn-noshadow):hover,
.btnPrimary:not(.btn-noshadow):not(.btn-soft):active,
.btnPrimary:not(.btn-noshadow):not(.btn-soft):focus {
  box-shadow: 0 4px 11px rgba(87, 79, 236, 0.35);
}
.btn-secondary:not(.btn-noshadow):hover,
.btn-secondary:not(.btn-noshadow):not(.btn-soft):active,
.btn-secondary:not(.btn-noshadow):not(.btn-soft):focus {
  box-shadow: 0 4px 11px hsla(208, 7%, 46%, 0.35);
}
.btn-success:not(.btn-noshadow):hover,
.btn-success:not(.btn-noshadow):not(.btn-soft):active,
.btn-success:not(.btn-noshadow):not(.btn-soft):focus {
  box-shadow: 0 4px 11px rgba(109, 187, 48, 0.35);
}
.btn-info:not(.btn-noshadow):hover,
.btn-info:not(.btn-noshadow):not(.btn-soft):active,
.btn-info:not(.btn-noshadow):not(.btn-soft):focus {
  box-shadow: 0 4px 11px rgba(115, 231, 247, 0.35);
}
.btn-warning:not(.btn-noshadow):hover,
.btn-warning:not(.btn-noshadow):not(.btn-soft):active,
.btn-warning:not(.btn-noshadow):not(.btn-soft):focus {
  box-shadow: 0 4px 11px hsla(44, 93%, 72%, 0.35);
}
.btn-danger:not(.btn-noshadow):hover,
.btn-danger:not(.btn-noshadow):not(.btn-soft):active,
.btn-danger:not(.btn-noshadow):not(.btn-soft):focus {
  box-shadow: 0 4px 11px rgba(246, 78, 96, 0.35);
}
.btn-light:not(.btn-noshadow):hover,
.btn-light:not(.btn-noshadow):not(.btn-soft):active,
.btn-light:not(.btn-noshadow):not(.btn-soft):focus {
  box-shadow: 0 4px 11px rgba(248, 249, 250, 0.35);
}
.btn-dark:not(.btn-noshadow):hover,
.btn-dark:not(.btn-noshadow):not(.btn-soft):active,
.btn-dark:not(.btn-noshadow):not(.btn-soft):focus {
  box-shadow: 0 4px 11px rgba(46, 46, 46, 0.35);
}
.btn-facebook,
.btn-facebook:hover {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}
.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
  box-shadow: 0 4px 11px rgba(59, 89, 152, 0.35);
}
.btn-twitter,
.btn-twitter:hover {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}
.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
  box-shadow: 0 4px 11px rgba(29, 161, 242, 0.35);
}
.btn-gplus,
.btn-gplus:hover {
  background-color: #dd4b39;
  border-color: #dd4b39;
  color: #fff;
}
.btn-gplus:active,
.btn-gplus:focus,
.btn-gplus:hover {
  box-shadow: 0 4px 11px rgba(221, 75, 57, 0.35);
}
.btn-instagram,
.btn-instagram:hover {
  background-color: #3f729b;
  border-color: #3f729b;
  color: #fff;
}
.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
  box-shadow: 0 4px 11px rgba(63, 114, 155, 0.35);
}
.btn-linkedin,
.btn-linkedin:hover {
  background-color: #007bb6;
  border-color: #007bb6;
  color: #fff;
}
.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover {
  box-shadow: 0 4px 11px rgba(0, 123, 182, 0.35);
}
.btn-vimeo,
.btn-vimeo:hover {
  background-color: #aad450;
  border-color: #aad450;
  color: #fff;
}
.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover {
  box-shadow: 0 4px 11px rgba(170, 212, 80, 0.35);
}
.btn-youtube,
.btn-youtube:hover {
  background-color: #b00;
  border-color: #b00;
  color: #fff;
}
.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
  box-shadow: 0 4px 11px rgba(187, 0, 0, 0.35);
}
.btn-github,
.btn-github:hover {
  background-color: #24292e;
  border-color: #24292e;
  color: #fff;
}
.btn-github:active,
.btn-github:focus,
.btn-github:hover {
  box-shadow: 0 4px 11px rgba(36, 41, 46, 0.35);
}
.btn-snapchat,
.btn-snapchat:hover {
  background-color: #fffc00;
  border-color: #fffc00;
  color: #fff;
}
.btn-snapchat:active,
.btn-snapchat:focus,
.btn-snapchat:hover {
  box-shadow: 0 4px 11px rgba(255, 252, 0, 0.35);
}
.btn-email,
.btn-email:hover {
  background-color: #049fb3;
  border-color: #049fb3;
  color: #fff;
}
.btn-email:active,
.btn-email:focus,
.btn-email:hover {
  box-shadow: 0 4px 11px rgba(4, 159, 179, 0.35);
}
.btn-pill {
  border-radius: 50rem;
}
.btn.btn-icon {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.btn.roundedCircle {
  font-size: 1rem;
  line-height: 1.6;
  padding: 1.53rem;
  position: relative;
  text-align: center;
}
.btn-group-sm > .btn.roundedCircle,
.btn.btnSm.roundedCircle {
  font-size: 1rem;
  height: 1.94rem;
  padding: 1.22rem;
  width: 1.94rem;
}
.btn-group-lg > .btn.roundedCircle,
.btn.btn-lg.roundedCircle {
  font-size: 1rem;
  height: 3.125rem;
  padding: 1.83rem;
  width: 3.125rem;
}
.btn.roundedCircle > i,
.btn.roundedCircle > span:not(.badge),
.btn.roundedCircle > svg {
  font-size: 1rem !important;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
.btn-ghost:not(:hover):not(:active):not(:focus) {
  background-color: transparent !important;
  border-color: transparent !important;
}
@media only screen and (max-width: 991px) {
  .btn.rounded-circle-xs {
    font-size: 1rem;
    line-height: 1.6;
    padding: 1.53rem;
    position: relative;
    text-align: center;
  }
  .btn-group-sm > .btn.rounded-circle-xs,
  .btn.btnSm.rounded-circle-xs {
    font-size: 1rem;
    height: 1.94rem;
    padding: 1.22rem;
    width: 1.94rem;
  }
  .btn-group-lg > .btn.rounded-circle-xs,
  .btn.btn-lg.rounded-circle-xs {
    font-size: 1rem;
    height: 3.125rem;
    padding: 1.83rem;
    width: 3.125rem;
  }
  .btn.rounded-circle-xs > i,
  .btn.rounded-circle-xs > span,
  .btn.rounded-circle-xs > svg {
    font-size: 1rem !important;
    left: 0;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn.rounded-circle-xs > .groupIcon {
    float: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .btn.rounded-circle-xs > i + span,
  .btn.rounded-circle-xs > span + span,
  .btn.rounded-circle-xs > svg + span {
    display: none;
  }
}
.groupIcon > i,
.groupIcon > span,
.groupIcon > svg {
  font-style: normal;
}
.groupIcon > i:last-child,
.groupIcon > span:last-child,
.groupIcon > svg:last-child {
  display: none;
}
.active > .groupIcon > i:last-child,
.active > .groupIcon > span:last-child,
.active > .groupIcon > svg:last-child,
.active > a > .groupIcon > i:last-child,
.active > a > .groupIcon > span:last-child,
.active > a > .groupIcon > svg:last-child,
.groupIcon > i:first-child,
.groupIcon > span:first-child,
.groupIcon > svg:first-child {
  display: inline-block;
}
.active > .groupIcon > i:first-child,
.active > .groupIcon > span:first-child,
.active > .groupIcon > svg:first-child,
.active > a > .groupIcon > i:first-child,
.active > a > .groupIcon > span:first-child,
[aria-expanded="true"] .groupIcon > i:first-child,
[aria-expanded="true"] .groupIcon > span:first-child,
[aria-expanded="true"] .groupIcon > svg:first-child {
  display: none;
}
[aria-expanded="true"] .groupIcon > i:last-child,
[aria-expanded="true"] .groupIcon > span:last-child,
[aria-expanded="true"] .groupIcon > svg:last-child {
  display: inline-block;
}
.burger-menu {
  border-top: 2px solid #384c74;
  display: block;
  height: 100%;
  position: relative;
  transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
  width: 100%;
}
.burger-menu:before {
  border-top: 2px solid #384c74;
  margin-top: -2px;
  top: 50%;
  width: 75%;
}
.burger-menu:after,
.burger-menu:before {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
}
.burger-menu:after {
  border-bottom: 2px solid #384c74;
  bottom: 0;
  width: 25%;
}
.start-0 .burger-menu:after,
.start-0 .burger-menu:before {
  left: 0;
}
.end-0 .burger-menu:after,
.end-0 .burger-menu:before {
  right: 0;
}
a.btn-burger-menu:not(.bgWhite) .burger-menu,
a.btn-burger-menu:not(.bgWhite) .burger-menu:after,
a.btn-burger-menu:not(.bgWhite) .burger-menu:before {
  border-color: #fff;
}
a.btn-burger-menu.active:hover .burger-menu:after,
a.btn-burger-menu.active:hover .burger-menu:before,
a.btn-burger-menu:hover .burger-menu:after,
a.btn-burger-menu:hover .burger-menu:before,
a.btn-burger-menu[aria-expanded="true"] .burger-menu:after,
a.btn-burger-menu[aria-expanded="true"] .burger-menu:before {
  width: 100%;
}
.breadcrumb-sublime a {
  color: #b2b6c3;
  text-decoration: none;
  transition: all 0.3s;
}
.breadcrumb-sublime a:hover {
  color: inherit;
}
.breadcrumb-sublime .breadcrumbItem.active {
  color: #b2b6c3;
}
.card {
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);
}
.card hr {
  background-color: #dde4ea;
  opacity: 1;
}
.card-columns {
  -moz-column-count: 3;
  column-count: 3;
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
  orphans: 1;
  widows: 1;
}
.card-columns .card {
  display: inline-block;
  margin-bottom: 0.75rem;
  width: 100%;
}
.card-columns .card img {
  width: 100%;
}
@media (max-width: 575.98px) {
  .card-columns {
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .card-columns {
    -moz-column-count: 4;
    column-count: 4;
  }
}
.badge-soft.badge-primary {
  background-color: #f1f0fd;
  color: #2117d7;
}
.bg-primary-soft .badge-soft.badge-primary {
  background-color: #dbd9fb;
}
.badge-soft.badge-secondary {
  background-color: #f0f2f2;
  color: #494f54;
}
.bg-secondary-soft .badge-soft.badge-secondary {
  background-color: #e3e5e7;
}
.badge-soft.badge-success {
  background-color: #e4f5d7;
  color: #4a7e20;
}
.bg-success-soft .badge-soft.badge-success {
  background-color: #d6efc2;
}
.badge-soft.badge-info {
  background-color: #bbf3fb;
  color: #2bdbf3;
}
.bg-info-soft .badge-soft.badge-info {
  background-color: #a3effa;
}
.badge-soft.badge-warning {
  background-color: #fdedc0;
  color: #f7c12c;
}
.bg-warning-soft .badge-soft.badge-warning {
  background-color: #fce5a7;
}
.badge-soft.badge-danger {
  background-color: #feeef0;
  color: #ec0c24;
}
.bg-danger-soft .badge-soft.badge-danger {
  background-color: #fdd6da;
}
.badge-soft.badge-light {
  background-color: #fff;
  color: #cbd3da;
}
.bg-light-soft .badge-soft.badge-light {
  background-color: #fff;
}
.badge-soft.badge-dark {
  background-color: #c4c4c4;
  color: #080808;
}
.bg-dark-soft .badge-soft.badge-dark {
  background-color: #b8b8b8;
}
.badge-ico,
.badge-ico-md,
.badge-ico-sm {
  align-items: center;
  display: flex;
  justify-content: center;
}
.badge-ico-sm {
  height: 25px;
  width: 25px;
}
.badge-ico,
.badge-ico-md {
  font-size: 16px;
  height: 35px;
  width: 35px;
}
.badge-ico-md > i,
.badge-ico-sm > i {
  display: inline-block;
}
.badge-ico-sm > i:before {
  padding-top: 0;
}
.dropdownMenu {
  animation: fadeIn 0.6s;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  font-size: 0.9375rem;
  text-align: left;
}
.dropdown-link.disabled {
  color: #abbcd5 !important;
  pointer-events: none;
}
.dropdown-link {
  color: #1c0950;
}
.active > .dropdown-link,
.dropdown-link.active {
  background-color: #f9fbfd;
  color: var(--bs-primary);
}
.dropdown-header {
  font-size: 1rem;
}
.dropdown-footer {
  display: block;
  padding: 10px 15px;
  position: relative;
}
.dropdown-footer,
a.dropdown-footer {
  text-decoration: none;
}
.dropdown-footer > a:not(:hover),
a.dropdown-footer:not(:hover) {
  color: #1c0950;
}
.dropdownMenu .dropdown-item {
  margin: 0;
  padding: 0;
}
a.dropdown-item {
  position: relative;
}
.dropdownMenu .dropdown-item.show > a {
  color: var(--bs-primary);
}
.dropdownMenu .dropdown-item > a,
.dropdownMenu a.dropdown-item,
.dropdownMenu li:not(.dropdown-item) > a {
  display: block;
  padding: 8px 40px 8px 25px;
  position: relative;
  transition: all 0.3s ease;
}
.dropdownMenu a:not([data-bs-toggle="dropdown"]) {
  padding-right: 15px;
}
.dropdown-item .row ul:not(.prefix-link-icon) .dropdown-link {
  padding-left: 0;
  padding-right: 0;
}
.dropdownMenu .dropdown-item > a > i,
.dropdownMenu a.dropdown-item > i,
.dropdownMenu li:not(.dropdown-item) > a > i {
  margin-right: 15px;
}
.dropdown-item > i + span,
.dropdown-item > span + i,
.dropdown-item > span + span,
.dropdown-item > span + svg,
.dropdown-item > svg + span {
  margin-left: 12px;
}
.dropdownMenu.dropdown-menu-uppercase a {
  font-size: 13px;
  text-transform: uppercase;
}
.dropdownMenuClean a:not(.btn).active,
.dropdownMenuClean a:not(.btn):focus,
.dropdownMenuClean a:not(.btn):hover,
.dropdownMenuClean li.active,
.dropdownMenuClean li.active > a:not(.btn),
.dropdownMenuClean li:focus,
.dropdownMenuClean li:hover {
  background-color: transparent !important;
  text-decoration: none;
}
.dropdownMenuClean:not(.prefix-link-icon) a:not(.btn):hover {
  color: var(--bs-primary);
}
.dropdownMenu.dropdownMenuInvert {
  left: auto;
  right: 0;
}
.dropdownMenu li > .dropdownMenu {
  left: 100% !important;
  right: auto !important;
  top: 0 !important;
}
.dropdownMenu:not(.dropdownMenuInvert)
  li
  > a[data-bs-toggle="dropdown"]:after {
  content: "\f1ae";
  font-family: Flaticon;
  font-size: 12px;
  line-height: 1;
  margin-top: -6px;
  opacity: 0.35;
  opacity: all 0.3s ease;
  position: absolute;
  right: 15px;
  top: 50%;
  transition: all 0.2s ease;
}
.dropdownMenu:not(.dropdownMenuInvert) .dropdown-item.show > a:after,
.dropdownMenu:not(.dropdownMenuInvert) .dropdown-item:hover > a:after {
  opacity: 1;
}
@media only screen and (max-width: 991px) {
  .dropdownMenu:not(.dropdownMenuInvert) .dropdown-item.show > a:after {
    transform: rotate(90deg);
  }
}
.dropdown-mega .row ul:not(.dropdownMenu) {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
.dropdown-mega .row ul:not(.dropdownMenu) .dropdown-divider {
  margin-bottom: 1px;
  margin-left: 0;
  margin-top: 1px;
}
.dropdown-mega .row ul + .h2,
.dropdown-mega .row ul + .h3,
.dropdown-mega .row ul + .h4,
.dropdown-mega .row ul + .h5,
.dropdown-mega .row ul + .h6,
.dropdown-mega .row ul + div,
.dropdown-mega .row ul + h2,
.dropdown-mega .row ul + h3,
.dropdown-mega .row ul + h4,
.dropdown-mega .row ul + h5,
.dropdown-mega .row ul + h6 {
  margin-top: 1.22rem;
}
.prefix-link-icon .dropdown-header {
  padding: 10px 15px;
}
.prefix-link-icon:not(.prefix-icon-ico) a:not(.prefix-icon-ignore),
a.prefix-link-icon:not(.prefix-icon-ico):not(.prefix-icon-ignore) {
  padding-left: 40px !important;
  padding-right: 25px !important;
}
.prefix-link-icon a:not(.prefix-icon-ignore):before,
a.prefix-link-icon:not(.prefix-icon-ignore):before {
  background-color: #f1f4f8;
  color: #f1f4f8;
  content: "";
  left: 20px;
  position: absolute;
  top: 50%;
  transition: all 0.2s ease;
}
.prefix-icon-dot a:not(.prefix-icon-ignore):before,
a.prefix-icon-dot:not(.prefix-icon-ignore):before {
  border-radius: 100%;
  height: 4px;
  left: 22px;
  margin-top: -2px;
  width: 4px;
}
.prefix-icon-line a:not(.prefix-icon-ignore):before,
a.prefix-icon-line:not(.prefix-icon-ignore):before {
  height: 1px;
  left: 19px;
  margin-top: -1px;
  width: 10px;
}
.prefix-icon-square a:not(.prefix-icon-ignore):before,
a.prefix-icon-square:not(.prefix-icon-ignore):before {
  height: 4px;
  left: 21px;
  margin-top: -3px;
  width: 4px;
}
.prefix-icon-arrow a:not(.prefix-icon-ignore):before,
a.prefix-icon-arrow:not(.prefix-icon-ignore):before {
  background-color: transparent;
  content: "\f1ae";
  font-family: Flaticon;
  font-size: 12px;
  height: 12px;
  left: 19px;
  line-height: 1;
  margin-top: -6px;
  width: 12px;
}
.prefix-link-icon:not(.prefix-icon-arrow)
  .dropdown-item.active:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow)
  .dropdown-item.active
  > a:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow)
  .dropdown-item.show:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow)
  .dropdown-item.show
  > a:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow)
  .dropdown-item:hover:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow)
  .dropdown-item:hover
  > a:not(.prefix-icon-ignore):before {
  background-color: var(--bs-primary) !important;
}
.prefix-link-icon.prefix-icon-arrow
  .dropdown-item.active:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow
  .dropdown-item.active
  > a:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow
  .dropdown-item.show:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow
  .dropdown-item.show
  > a:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow
  .dropdown-item:hover:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow
  .dropdown-item:hover
  > a:not(.prefix-icon-ignore):before {
  color: var(--bs-primary) !important;
}
.text-white > .dropdown-item > a {
  color: #d9e2ef !important;
}
.text-white > .dropdown-item.active > a,
.text-white > .dropdown-item > a:hover {
  color: #fff !important;
}
@media only screen and (min-width: 991px) {
  .dropdownMenu .dropdown-item:hover > a {
    color: var(--bs-primary);
    text-decoration: none;
  }
  .dropdown-link:hover {
    background-color: #f9fbfd;
    color: var(--bs-primary);
  }
  [data-bs-toggle="dropdown"] + .dropdown-menu-hover {
    margin-top: 0 !important;
  }
  .dropdown-menu-hover + .dropdownMenu:hover,
  .dropdown-menu-hover:hover,
  .dropdown-menu-hover:hover + .dropdownMenu,
  [data-bs-toggle="dropdown"]:hover + .dropdown-menu-hover,
  [data-bs-toggle="dropdown"]:hover ~ .dropdown-menu-hover {
    display: block;
  }
  .dropdown-mega > .dropdownMenu {
    left: 0;
    max-width: 100%;
    padding: 25px;
    right: 0;
    width: 100%;
  }
  .dropdown-mega-sm {
    width: 300px;
  }
  .dropdown-mega-md {
    width: 400px;
  }
  .dropdown-mega-lg {
    width: 600px;
  }
  .dropdown-mega-xl {
    width: 700px;
  }
  .dropdown-mega-xxl {
    width: 800px;
  }
  .dropdown-menu-dark,
  .dropdown-menu-dark .dropdownMenu {
    background-color: #333;
    color: #fff;
  }
  .dropdown-menu-dark a {
    color: #ccc !important;
  }
  .dropdown-menu-dark .dropdown-item.active > a,
  .dropdown-menu-dark .dropdown-item:hover > a,
  .dropdown-menu-dark .dropdown-item > a.active,
  .dropdown-menu-dark a.dropdown-item.active,
  .dropdown-menu-dark a.dropdown-item:hover,
  .dropdown-menu-dark a:hover {
    color: #fff !important;
  }
  .dropdown-menu-dark .dropdown-divider,
  .dropdown-menu-dark .row.col-border-lg > div,
  .dropdown-menu-dark .row.col-border-md > div,
  .dropdown-menu-dark .row.col-border-sm > div,
  .dropdown-menu-dark .row.col-border > div {
    border-color: #4d4d4d;
  }
  .dropdown-menu-block-md .dropdown-item > .dropdownMenu {
    transform: none !important;
  }
  .dropdown-fadeinup {
    animation: __utilFadeInUp 0.25s ease-out forwards !important;
  }
  .dropdownFadeindown {
    animation: __utilFadeInDown 0.25s ease-out forwards !important;
  }
  .dropdown-bounceinup {
    animation: __utilBounceInUp 0.25s ease-out forwards !important;
  }
}
@media only screen and (max-width: 991px) {
  a.dropdown-link:hover {
    color: inherit;
  }
  .dropdown-mega .row {
    margin-left: 0;
  }
  .dropdown-mega .row > div:not(:last-child) {
    margin-bottom: 30px;
  }
  .dropdown-menu-block-md .dropdown-item > .dropdownMenu {
    background-color: rgba(0, 0, 0, 0.02) !important;
    border-radius: 0;
    box-shadow: none !important;
    left: 0 !important;
    position: relative !important;
    right: 0 !important;
    width: 100% !important;
  }
  .dropdownMenuNavbarAutopos {
    bottom: auto !important;
    margin-left: 8px;
    margin-right: 8px;
    position: fixed;
    top: auto !important;
  }
}
.list-divider > :not(:last-child) {
  border-bottom: 1px solid #dde4ea;
}
.form-check-input-primary:checked {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}
.form-check-input-danger:checked {
  background-color: #f64e60 !important;
  border-color: #f64e60 !important;
}
.form-check-input-warning:checked {
  background-color: #fad776 !important;
  border-color: #fad776 !important;
}
.form-check-input-success:checked {
  background-color: #6dbb30 !important;
  border-color: #6dbb30 !important;
}
.form-check-input-info:checked {
  background-color: #73e7f7 !important;
  border-color: #73e7f7 !important;
}
th > .form-check {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
th > .form-check input {
  margin: 0 !important;
}
.form-check-input:checked[type="checkbox"],
.form-check-input:checked[type="radio"] {
  background-position: inherit;
}
.switch-icon {
  background-color: #f1f4f8;
  border-radius: 50rem;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100%;
}
.switch-icon:before {
  align-items: center;
  background-color: #fbfbfb;
  border-radius: 50rem;
  box-shadow: 0 3px 6px 0 rgba(131, 149, 164, 0.28);
  content: "";
  display: inline-flex;
  justify-content: center;
  left: 0;
  margin: 0.125rem;
  position: absolute;
  transition: all 114ms ease-in;
}
.active + .switch-icon:before,
.switch-icon.active:before,
input:checked + .switch-icon:before {
  background-color: #fff;
}
.switch-icon.disabled,
input[disabled] + .switch-icon {
  cursor: not-allowed;
  opacity: 0.5;
}
.was-validated input:not([disabled]).is-invalid + .switch-icon + *,
.was-validated input:not([disabled]):invalid + .switch-icon + * {
  color: #f64e60;
}
.active + .switch-icon,
.switch-icon.active,
input:checked + .switch-icon {
  background-color: #506690;
}
.active + .switch-icon-primary,
.switch-icon-primary.active,
input:checked + .switch-icon-primary {
  background-color: var(--bs-primary);
}
.active + .switch-icon-danger,
.switch-icon-danger.active,
input:checked + .switch-icon-danger {
  background-color: #f64e60;
}
.active + .switch-icon-warning,
.switch-icon-warning.active,
input:checked + .switch-icon-warning {
  background-color: #fad776;
}
.active + .switch-icon-success,
.switch-icon-success.active,
input:checked + .switch-icon-success {
  background-color: #6dbb30;
}
.active + .switch-icon-info,
.switch-icon-info.active,
input:checked + .switch-icon-info {
  background-color: #73e7f7;
}
.switch-icon {
  height: 1.875rem;
  max-width: 3.125rem;
  min-width: 3.125rem;
}
.switch-icon:before {
  height: 1.625rem;
  width: 1.625rem;
}
.active + .switch-icon:before,
.switch-icon.active:before,
input:checked + .switch-icon:before {
  margin-left: calc(100% - 1.75rem);
}
.switch-icon-lg {
  height: 2.3125rem;
  max-width: 3.875rem;
  min-width: 3.875rem;
}
.switch-icon-lg:before {
  height: 2.0625rem;
  width: 2.0625rem;
}
.active + .switch-icon-lg:before,
.switch-icon.active-lg:before,
input:checked + .switch-icon-lg:before {
  margin-left: calc(100% - 2.1875rem);
}
.switch-icon-sm {
  height: 1.25rem;
  max-width: 2.5rem;
  min-width: 2.5rem;
}
.switch-icon-sm:before {
  height: 1rem;
  width: 1rem;
}
.active + .switch-icon-sm:before,
.switch-icon.active-sm:before,
input:checked + .switch-icon-sm:before {
  margin-left: calc(100% - 1.125rem);
}
.switch-icon-xs {
  height: 1.125rem;
  max-width: 1.875rem;
  min-width: 1.875rem;
}
.switch-icon-xs:before {
  height: 0.875rem;
  width: 0.875rem;
}
.active + .switch-icon-xs:before,
.switch-icon.active-xs:before,
input:checked + .switch-icon-xs:before {
  margin-left: calc(100% - 1rem);
}
table.tableAlignMiddle td,
table.tableAlignMiddle th {
  vertical-align: middle;
}
table label.form-checkbox,
table label.form-radio {
  height: 20px !important;
  margin: 0;
  padding: 0;
  width: 20px !important;
}
table label.form-checkbox > i,
table label.form-radio > i {
  margin-left: 0;
  margin-right: 0;
}
table tfoot th label.form-checkbox,
table tfoot th label.form-radio,
table thead th label.form-checkbox,
table thead th label.form-radio {
  margin-top: 3px;
}
.table:not(.table-framed):not(.table-bordered) thead > tr > th {
  border-top-width: 0;
}
.table thead > tr > th {
  border-bottom-width: 1px;
}
.table-framed tfoot th {
  border-bottom: 1px solid #eaf0f5;
}
.table-lg tr > td,
.table-lg tr > th {
  padding: 1.5rem 2rem;
}
table.table-striped td,
table.table-striped th {
  border: 0;
}
.nav-invert {
  background-color: #f1f4f8;
  display: inline-flex;
  padding: 0.25rem;
}
.nav-invert,
.nav-invert .navLink {
  border-radius: 0.35rem;
}
.nav-pills.nav-invert {
  border-radius: 50rem;
}
.nav-invert .navLink.active {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.08),
    0 4px 6px 0 rgba(112, 157, 199, 0.08);
  color: var(--bs-primary) !important;
}
.rounded-pill .navLink {
  border-radius: 50rem;
}
.nav-sm .navLink {
  font-size: 0.875rem;
  padding: 0.344rem 0.65rem;
}
.nav-lg .navLink {
  padding: 0.7rem 1.4rem;
}
.nav-tabs-border-bottom,
.nav-tabs-border-top {
  border: 0;
}
.nav-tabs-border-bottom .navItem:not(:last-child),
.nav-tabs-border-top .navItem:not(:last-child) {
  margin-right: 15px;
}
.nav-tabs-border-bottom .navLink,
.nav-tabs-border-top .navLink {
  border: 0;
  border-radius: 0;
  padding-left: 3px;
  padding-right: 3px;
}
.nav-tabs-border-top .navLink.active {
  border-top: 1px solid var(--bs-primary) !important;
}
.nav-tabs-border-bottom .navLink.active {
  border-bottom: 1px solid var(--bs-primary) !important;
}
.nav-pills .navLink.btn:not(.active) {
  background: #fff !important;
  color: #869ab8 !important;
}
aside {
  flex-shrink: 0;
  font-size: 16px;
  position: relative;
}
aside.aside-end,
aside.asideStart {
  height: 100%;
  transition: all 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.aside-width,
body:not(.aside-compact) aside.aside-end,
body:not(.aside-compact) aside.asideStart {
  width: 265px;
}
body.aside-compact aside.aside-end,
body.aside-compact aside.asideStart {
  width: 140px;
}
aside.asideStart {
  left: 0;
  right: auto;
}
aside.aside-end {
  left: auto;
  right: 0;
}
.aside-secondary {
  background-color: #fff;
  color: #1c0950;
}
.aside-secondary .text-aside {
  color: var(--bs-primary);
}
.aside-primary {
  background-color: #1b2a4e;
  color: #bfc6d7;
}
.aside-primary .text-aside {
  color: #fff;
}
.bg-aside {
  background-color: #1b2a4e;
}
aside .navDeep > ul > li.navTitle,
aside .navDeep > ul > li > a.navLink {
  padding: 0.8rem 1rem;
}
aside .navDeep.nav-deep-lg > ul > li.navTitle,
aside .navDeep.nav-deep-lg > ul > li > a.navLink {
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}
aside .navDeep.nav-deep-lg > ul > li li.navTitle,
aside .navDeep.nav-deep-lg > ul > li ul > li > a {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
aside .navDeep.navDeepSm > ul > li.navTitle,
aside .navDeep.navDeepSm > ul > li > a.navLink {
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
}
aside .navDeep.navDeepSm > ul > li li.navTitle,
aside .navDeep.navDeepSm > ul > li ul > li > a {
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
}
aside .navDeep.nav-deep-xs > ul > li li.navTitle,
aside .navDeep.nav-deep-xs > ul > li ul > li > a,
aside .navDeep.nav-deep-xs > ul > li.navTitle,
aside .navDeep.nav-deep-xs > ul > li > a.navLink {
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
}
.aside-secondary nav.nav-deep-hover > ul > li.navItem.active,
.aside-secondary nav.nav-deep-hover > ul > li.navItem:hover {
  background-color: #f5f6fc !important;
}
.aside-primary nav.nav-deep-hover > ul > li.navItem.active,
.aside-primary nav.nav-deep-hover > ul > li.navItem:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.aside-secondary a.disabled {
  color: #8d6aee !important;
}
.aside-primary a.disabled {
  color: #61729c !important;
}
aside .navItem.nav-item-extended > div.aside-item {
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 0 !important;
  border-left: 0;
  border-radius: 0.35rem;
  border-top-left-radius: 0 !important;
  bottom: 15px;
  color: #121212;
  font-size: 1rem;
  left: 265px;
  max-width: calc(100vw - 280px);
  min-width: 265px;
  overflow: hidden;
  position: fixed;
  top: 95px;
}
aside .navItem.nav-item-extended:not(.active).overlay-dark:after,
aside .navItem.nav-item-extended:not(.active).overlay-light:after,
aside .navItem.nav-item-extended:not(.active) > div.aside-item {
  display: none !important;
}
aside .navItem.nav-item-extended.active.overlay-dark:after,
aside .navItem.nav-item-extended.active.overlay-light:after {
  left: 265px;
  position: fixed;
  right: 0;
  z-index: -1;
}
aside .navItem.nav-item-extended.active > .aside-item {
  animation: __utilFadeIn 0.25s ease-out forwards;
}
#header.header-match-aside-primary {
  background-color: #1b2a4e;
}
#header.header-match-aside-secondary {
  background-color: #fff;
}
.aside-custom,
.aside-custom-end,
.aside-custom-start {
  animation: __asidePageLoadFix;
  animation-duration: 10ms;
  background: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  max-width: 400px;
  position: fixed !important;
  top: 0;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.56s cubic-bezier(0.6, 0.4, 0.05, 0.95) !important;
  width: 90%;
  z-index: 11;
}
@keyframes __asidePageLoadFix {
  to {
    transform: none;
  }
}
.aside-custom-end {
  left: auto;
  right: 0;
  transform: translate3d(100%, 0, 0);
}
.aside-custom-end.js-aside-show,
.aside-custom-start.js-aside-show,
.aside-custom.js-aside-show {
  transform: translateZ(0);
}
@media only screen and (min-width: 992px) {
  body.asideSticky aside {
    bottom: 0;
    position: fixed;
    top: 0;
    z-index: 11;
  }
  body:not(.aside-compact).layoutAdmin #footer {
    padding-left: 265px;
  }
  body.asideSticky:not(.aside-compact) #header,
  body.asideSticky:not(.aside-compact) #js_header_spacer,
  body.asideSticky:not(.aside-compact) #wrapper_content {
    margin-left: 265px;
  }
  body.layoutAdmin.aside-compact #footer {
    padding-left: 140px;
  }
  body.asideSticky.aside-compact #header,
  body.asideSticky.aside-compact #js_header_spacer,
  body.asideSticky.aside-compact #wrapper_content {
    margin-left: 140px;
  }
  body.asideStart:not(.layout-boxed) #wrapper {
    margin-left: 265px;
  }
  body.aside-end:not(.layout-boxed) #wrapper {
    margin-right: 265px;
  }
  body.aside-end.header-fixed:not(.layout-boxed) #header,
  body.asideStart.header-fixed:not(.layout-boxed) #header {
    max-width: 100% !important;
    width: auto !important;
  }
  body.aside-float aside {
    bottom: 0;
    position: fixed;
    top: 0;
    z-index: 11;
  }
  body.aside-float #header,
  body.asideStart.asideSticky:not(.headerSticky) #header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  body.aside-float:not(.aside-show) .asideStart:not(.js-aside-show) {
    margin-left: -100%;
  }
  body.aside-float:not(.aside-show) .aside-end:not(.js-aside-show) {
    margin-right: -100%;
  }
}
@media only screen and (max-width: 991px) {
  aside {
    bottom: 0;
    position: fixed !important;
    top: 0;
    z-index: 11;
  }
  aside:not(.js-aside-show).asideStart {
    margin-left: -265px;
  }
  aside:not(.js-aside-show).aside-end {
    margin-right: -265px;
  }
  aside:not(.js-aside-show).asideStart .nav-item-extended > div.aside-item {
    left: -100%;
  }
  aside:not(.js-aside-show).aside-end .nav-item-extended > div.aside-item {
    right: -100%;
  }
  aside .navItem.nav-item-extended > div.aside-item {
    border-radius: 0.35rem;
    bottom: 15px;
    height: auto !important;
    left: 15px;
    max-height: 100% !important;
    max-width: 100% !important;
    right: 15px;
    top: 15px;
    width: auto !important;
    z-index: 9999;
  }
}
.fullscreen {
  -webkit-overflow-scrolling: touch;
  background-color: #fff !important;
  bottom: 15px !important;
  -ms-box-shadow: 0 0 35px 19px rgba(0, 0, 0, 0.75) !important;
  -o-box-shadow: 0 0 35px 19px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0 0 35px 19px rgba(0, 0, 0, 0.75) !important;
  height: auto;
  left: 15px !important;
  margin: 0 !important;
  max-height: 100%;
  overflow-y: scroll;
  position: fixed !important;
  right: 15px !important;
  top: 15px !important;
  z-index: 1049 !important;
}
.fullscreen .fullscreen-show {
  display: block !important;
}
.fullscreen .fullscreen-hide {
  display: hidden !important;
}
.modal-full {
  margin: 15px;
  max-width: 100%;
}
.modal-backdrop.show {
  opacity: 1 !important;
}
.modal-backdrop,
.offcanvas-backdrop {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
.offcanvas-backdrop {
  animation: _bkdFadeIn;
  animation-duration: 0.25s;
  opacity: 1 !important;
}
.pagination-pill > .page-item:first-child > .page-link {
  border-bottom-left-radius: 50rem;
  border-top-left-radius: 50rem;
}
.pagination-pill > .page-item:last-child > .page-link {
  border-bottom-right-radius: 50rem;
  border-top-right-radius: 50rem;
}
.process-steps {
  display: flex;
  font-size: 14px;
  list-style: none outside;
  margin: 0;
  overflow: hidden;
  padding: 0 0 24px;
  width: 100%;
}
.process-step-item {
  border-bottom: 3px solid #f1f4f8;
  margin: 0;
  padding: 0 0 0.8em;
  position: relative;
  text-align: center;
  width: 100%;
}
.process-step-item:before {
  background: #fff;
  border: 3px solid #f1f4f8;
  border-radius: 100%;
  bottom: 0;
  content: "";
  height: 12px;
  left: 50%;
  margin-bottom: -7.5px;
  margin-left: -6px;
  position: absolute;
  width: 12px;
}
.process-step-item.active,
.process-step-item.complete {
  border-color: #869ab8;
}
.process-step-item.active:before,
.process-step-item.complete:before {
  background: #869ab8;
  border-color: #869ab8;
}
.process-step-item.active:before {
  background: #fff !important;
}
.process-steps-primary > .active,
.process-steps-primary > .complete {
  border-color: var(--bs-primary);
}
.process-steps-primary > .active:before,
.process-steps-primary > .complete:before {
  background: var(--bs-primary);
  border-color: var(--bs-primary);
}
.thumbnail-shadow-drop {
  position: relative;
}
.thumbnail-shadow-drop:after {
  bottom: 128px;
  box-shadow: 0 210px 60px rgba(0, 0, 0, 0.2);
  height: 26%;
  left: 20%;
  margin-bottom: 13%;
  transform: rotate(-10deg);
  width: 56%;
  z-index: 1;
}
.thumbnail-shadow-drop:after,
.thumbnail-shadow-drop:before {
  animation: __utilFadeInSlow 2s ease;
  animation-delay: 0.5s;
  animation-duration: 4s;
  animation-fill-mode: both;
  border-radius: 50%;
  content: "";
  opacity: 0;
  position: absolute;
}
.thumbnail-shadow-drop:before {
  bottom: 25%;
  box-shadow: 0 194px 50px rgba(0, 0, 0, 0.3);
  height: 25%;
  left: 23%;
  margin-bottom: 0;
  transform: rotate(-17deg);
  width: 41%;
  z-index: 0;
}
@media only screen and (max-width: 768px) {
  .thumbnail-shadow-drop:before {
    bottom: 45%;
    left: 18%;
  }
}
.sow-timeline {
  border-left: 2px dashed #dde4ea;
  list-style: none;
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
  padding: 0 1.75rem !important;
}
.sow-timeline-title:before {
  background: #dde4ea;
  border-radius: 50%;
  content: "";
  height: 1.25rem;
  left: -2.4375rem;
  position: absolute;
  width: 1.25rem;
}
.timeline-container,
.timeline-container > div {
  position: relative;
}
.timeline-container:before {
  background-color: #f1f4f8;
  bottom: 7px;
  content: "";
  height: calc(100% - 4px - 3);
  left: 100px;
  position: absolute;
  top: 7px;
  width: 4px;
}
.timeline-container i.badge {
  border: 4px solid transparent;
  display: inline-block;
  height: 17px;
  left: 93.5px;
  padding: 0;
  position: absolute;
  top: 7px;
  width: 17px;
}
.timeline-container i.badge:after {
  border: 4px solid #fff;
  border-radius: 50rem;
  content: "";
  height: 25px;
  left: -8px;
  position: absolute;
  top: -8px;
  width: 25px;
  z-index: 0;
}
.timeline-container .timeline-content,
.timeline-container .timeline-title {
  border-left: 4px solid transparent;
  display: block;
  margin-left: 100px;
  padding-left: 24px;
  position: relative;
}
.timeline-container.timeline-invert {
  text-align: right;
}
.timeline-container.timeline-invert:before {
  left: auto;
  right: 100px;
}
.timeline-container.timeline-invert i.badge {
  left: auto;
  right: 93.5px;
}
.timeline-container.timeline-invert i.badge:after {
  left: auto;
  right: -8px;
}
.timeline-container.timeline-invert .timeline-content,
.timeline-container.timeline-invert .timeline-title {
  border-left: 0;
  border-right: 4px solid transparent;
  margin-left: 0;
  margin-right: 100px;
  padding-left: 0;
  padding-right: 24px;
}
.shape-v {
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
}
.shape-v + .section,
.shape-v + div,
.shape-v + section {
  z-index: 9;
}
.shape-v:after,
.shape-v:before {
  background-color: #fff;
  bottom: 0;
  content: "";
  height: 100px;
  margin-bottom: -100px;
  position: absolute;
  z-index: 2;
}
.shape-v.bg-light:after,
.shape-v.bg-light:before {
  background-color: #f9fbfd;
}
.shape-v:before {
  left: 0;
  transform: scaleX(2) scaleY(5) rotate(6deg);
  transform-origin: 100% 0;
  width: 40%;
}
.shape-v:after {
  right: 0;
  transform: scaleX(2) scaleY(5) rotate(-8deg);
  transform-origin: 0 0;
  width: 60%;
}
@media only screen and (min-width: 992px) {
  .shape-v.shape-xs:before {
    transform: scaleX(2) scaleY(5) rotate(2deg);
  }
  .shape-v.shape-xs:after {
    transform: scaleX(2) scaleY(5) rotate(-3deg);
  }
}
.offcanvas-lg {
  width: 500px;
}
.form-control-pill,
.form-control-pill .btn,
.form-control-pill input,
.form-control-pill select,
.form-control-pill textarea,
.input-group-pill .btn,
.input-group-pill .input-group-append > span,
.input-group-pill .input-group-prepend > span,
.input-group-pill input,
.input-group-pill select,
.input-group-pill textarea {
  border-radius: 50rem;
}
.input-group select {
  border-radius: 0 !important;
}
.input-group-over--absolute {
  position: absolute;
  right: 0;
  top: 0;
}
.input-group-over {
  position: relative;
}
.input-group-over > input,
.input-group-over > textarea {
  padding-right: 60px;
}
.input-group-over > a,
.input-group-over > button,
.input-group-over > span {
  font-size: 15px;
  font-weight: 400;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 50%;
  transform: translateY(-50%);
}
.el-over-input {
  position: absolute !important;
  right: 0;
  top: 0;
  z-index: 5 !important;
}
.form-switch {
  clear: both;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  min-height: 26px;
  padding-left: 73px;
  position: relative;
}
.m--0.form-switch,
.fiBars.form-switch {
  padding-left: 58px;
}
.form-switch > i {
  background: #b5c1c7;
  border: 0 solid #b5c1c7;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 11px;
  font-style: normal;
  height: 25px;
  left: 0;
  position: relative;
  position: absolute;
  text-align: center;
  transition: 0.3s ease-out;
  width: 58px;
}
.form-switch > i:before {
  content: attr(data-off);
  height: 19px;
  line-height: 19px;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 32px;
}
.form-switch > i:after {
  background: #fff;
  border-radius: 2px;
  content: "";
  display: block;
  height: 19px;
  margin: 3px;
  width: 19px;
}
.form-switch > input {
  display: none !important;
}
.form-switch > input:focus + i {
  background: #a1a6a9;
  border-color: #a1a6a9;
  color: #fff;
}
.form-switch > input:focus + i:after {
  background: #fff;
}
.form-switch > input:checked + i {
  background: #333;
  border-color: #333;
  color: #fff;
  padding-left: 33px;
}
.form-switch.form-switch-primary > input:checked + i {
  background: var(--bs-primary);
  border-color: var(--bs-primary);
}
.form-switch.form-switch-secondary > input:checked + i {
  background: #6c757d;
  border-color: #6c757d;
}
.form-switch.form-switch-success > input:checked + i {
  background: #6dbb30;
  border-color: #6dbb30;
}
.form-switch.form-switch-info > input:checked + i {
  background: #73e7f7;
  border-color: #73e7f7;
}
.form-switch.form-switch-warning > input:checked + i {
  background: #fad776;
  border-color: #fad776;
}
.form-switch.form-switch-danger > input:checked + i {
  background: #f64e60;
  border-color: #f64e60;
}
.form-switch.form-switch-light > input:checked + i {
  background: #f8f9fa;
  border-color: #f8f9fa;
}
.form-switch.form-switch-dark > input:checked + i {
  background: #2e2e2e;
  border-color: #2e2e2e;
}
.form-switch > input:checked + i:before {
  color: #fff;
  content: attr(data-on);
  font-family: Zapf Dingbats, font-regular, sans-serif;
  height: 19px;
  left: 1px;
  line-height: 19px;
  top: 3px;
}
.form-switch-pill > i {
  border-radius: 13px;
}
.form-switch-pill > i:before {
  width: 29px;
}
.form-switch-pill > i:after {
  border-radius: 13px;
  color: #b5c1c7;
  content: "\2022";
  font: 20px/20px Times, Serif;
  height: 19px;
  line-height: 19px;
  width: 19px;
}
.form-switch-pill > input:checked + i {
  padding-left: 33px;
}
.form-switch-pill > input:checked + i:after {
  color: #333;
}
.form-switch input[disabled] + i {
  background: #e9ecef !important;
  border-color: #e9ecef !important;
}
.form-radio {
  cursor: pointer;
  line-height: 1.3;
  padding-left: 30px;
  padding-right: 15px;
  position: relative;
}
a.form-radio {
  color: #1c0950;
  text-decoration: none;
}
.form-radio > input[type="radio"] {
  display: none;
}
.form-radio > i {
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  left: 0;
  margin-right: 10px;
  position: absolute;
  transition: background 0.3s;
  width: 20px;
}
.form-radio-inset {
  display: block;
  height: 20px;
  margin: 2px 0 0;
  overflow: hidden;
  padding: 0;
  width: 20px;
}
.form-radio.form-radio-dark > input[type="radio"]:checked + i,
.form-radio > input[type="radio"]:checked + i,
a.form-radio.active > i {
  background: #121212;
  border: none;
}
.form-radio-bordered.form-radio-dark > i,
.form-radio-bordered > i {
  border: 2px solid #121212;
}
.form-radio.form-radio-primary > input[type="radio"]:checked + i,
a.form-radio.form-radio-primary.active > i {
  background: var(--bs-primary);
}
.form-radio-bordered.form-radio-primary > i {
  border: 2px solid var(--bs-primary);
}
.form-radio.form-radio-secondary > input[type="radio"]:checked + i,
a.form-radio.form-radio-secondary.active > i {
  background: #6c757d;
}
.form-radio-bordered.form-radio-secondary > i {
  border: 2px solid #6c757d;
}
.form-radio.form-radio-success > input[type="radio"]:checked + i,
a.form-radio.form-radio-success.active > i {
  background: #6dbb30;
}
.form-radio-bordered.form-radio-success > i {
  border: 2px solid #6dbb30;
}
.form-radio.form-radio-info > input[type="radio"]:checked + i,
a.form-radio.form-radio-info.active > i {
  background: #73e7f7;
}
.form-radio-bordered.form-radio-info > i {
  border: 2px solid #73e7f7;
}
.form-radio.form-radio-warning > input[type="radio"]:checked + i,
a.form-radio.form-radio-warning.active > i {
  background: #fad776;
}
.form-radio-bordered.form-radio-warning > i {
  border: 2px solid #fad776;
}
.form-radio.form-radio-danger > input[type="radio"]:checked + i,
a.form-radio.form-radio-danger.active > i {
  background: #f64e60;
}
.form-radio-bordered.form-radio-danger > i {
  border: 2px solid #f64e60;
}
.form-radio.form-radio-light > input[type="radio"]:checked + i,
a.form-radio.form-radio-light.active > i {
  background: #f8f9fa;
}
.form-radio-bordered.form-radio-light > i {
  border: 2px solid #f8f9fa;
}
.form-radio.form-radio-dark > input[type="radio"]:checked + i,
a.form-radio.form-radio-dark.active > i {
  background: #2e2e2e;
}
.form-radio-bordered.form-radio-dark > i {
  border: 2px solid #2e2e2e;
}
.form-radio > input[type="radio"].is-invalid ~ span,
.was-validated .form-radio > input[type="radio"]:invalid ~ span {
  color: #f64e60 !important;
}
.form-radio > input[type="radio"]:checked + i:after,
a.form-radio.active > i:after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 6px;
  left: calc(50% - 3px);
  position: absolute;
  top: calc(50% - 3px);
  width: 6px;
}
.form-radio input[disabled] + i,
a.form-radio.disabled > i {
  background: #e9ecef !important;
  border-color: #e9ecef !important;
}
.form-checkbox {
  cursor: pointer;
  line-height: 1.3;
  padding-left: 30px;
  padding-right: 15px;
  position: relative;
}
a.form-checkbox {
  color: #1c0950;
  text-decoration: none;
}
.form-checkbox > input[type="checkbox"] {
  display: none;
}
.form-checkbox > i {
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  left: 0;
  margin-right: 10px;
  position: absolute;
  transition: 0.3s;
  width: 20px;
}
.form-checkbox > input[type="checkbox"].is-invalid ~ span,
.was-validated .form-checkbox > input[type="checkbox"]:invalid ~ span {
  color: #f64e60 !important;
}
.form-checkbox > input[type="checkbox"]:checked + i,
a.form-checkbox.active > i {
  background: #0d6efd;
  border: none;
}
.form-checkbox-inset {
  display: block;
  height: 20px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 20px;
}
.form-checkbox.form-checkbox-dark > input[type="checkbox"]:checked + i,
.form-checkbox > input[type="checkbox"]:checked + i,
a.form-checkbox.active > i {
  background: #121212;
  border: none;
}
.form-checkbox-bordered.form-checkbox-dark > i,
.form-checkbox-bordered > i {
  border: 2px solid #121212;
}
.form-checkbox.form-checkbox-primary > input[type="checkbox"]:checked + i,
a.form-checkbox.form-checkbox-primary.active > i {
  background: var(--bs-primary);
}
.form-checkbox-bordered.form-checkbox-primary > i {
  border: 2px solid var(--bs-primary);
}
.form-checkbox.form-checkbox-secondary > input[type="checkbox"]:checked + i,
a.form-checkbox.form-checkbox-secondary.active > i {
  background: #6c757d;
}
.form-checkbox-bordered.form-checkbox-secondary > i {
  border: 2px solid #6c757d;
}
.form-checkbox.form-checkbox-success > input[type="checkbox"]:checked + i,
a.form-checkbox.form-checkbox-success.active > i {
  background: #6dbb30;
}
.form-checkbox-bordered.form-checkbox-success > i {
  border: 2px solid #6dbb30;
}
.form-checkbox.form-checkbox-info > input[type="checkbox"]:checked + i,
a.form-checkbox.form-checkbox-info.active > i {
  background: #73e7f7;
}
.form-checkbox-bordered.form-checkbox-info > i {
  border: 2px solid #73e7f7;
}
.form-checkbox.form-checkbox-warning > input[type="checkbox"]:checked + i,
a.form-checkbox.form-checkbox-warning.active > i {
  background: #fad776;
}
.form-checkbox-bordered.form-checkbox-warning > i {
  border: 2px solid #fad776;
}
.form-checkbox.form-checkbox-danger > input[type="checkbox"]:checked + i,
a.form-checkbox.form-checkbox-danger.active > i {
  background: #f64e60;
}
.form-checkbox-bordered.form-checkbox-danger > i {
  border: 2px solid #f64e60;
}
.form-checkbox.form-checkbox-light > input[type="checkbox"]:checked + i,
a.form-checkbox.form-checkbox-light.active > i {
  background: #f8f9fa;
}
.form-checkbox-bordered.form-checkbox-light > i {
  border: 2px solid #f8f9fa;
}
.form-checkbox.form-checkbox-dark > input[type="checkbox"]:checked + i,
a.form-checkbox.form-checkbox-dark.active > i {
  background: #2e2e2e;
}
.form-checkbox-bordered.form-checkbox-dark > i {
  border: 2px solid #2e2e2e;
}
.form-checkbox > input[type="checkbox"]:checked + i:after,
a.form-checkbox.active > i:after {
  border-color: #fff;
  border-style: none none solid solid;
  border-width: 2px;
  content: "";
  height: 6px;
  left: 4px;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}
.form-checkbox input[disabled] + i,
a.form-checkbox.disabled > i {
  background: #e9ecef !important;
  border-color: #e9ecef !important;
}
.select-form-control,
select.formControl:not([size]):not([multiple]) {
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg width="15" viewBox="0 0 129 129" xmlns="http://www.w3.org/2000/svg"><path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"/></svg>')
    no-repeat;
  background-color: #fff;
  background-position: center right 15px;
}
select.formControl:not([size]):not([multiple]) {
  padding-right: 40px;
}
.form-control-clean {
  background-color: transparent;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  box-shadow: none !important;
}
.form-label-group {
  position: relative;
}
.form-label-group > .formControl + label {
  color: #95a4af;
  cursor: text;
  left: 0;
  margin-bottom: 0;
  padding: 0.88rem 1rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
}
.form-label-group > .formControlSm + label {
  padding: 0.56rem 1rem;
}
.form-label-group > .formControl::-moz-placeholder {
  color: transparent !important;
}
.form-label-group > .formControl::placeholder {
  color: transparent !important;
}
.form-label-group > .formControl:not(:-moz-placeholder-shown) {
  padding-bottom: 6px;
  padding-top: 19px;
}
.form-label-group > .formControl:not(:placeholder-shown) {
  padding-bottom: 6px;
  padding-top: 19px;
}
.form-label-group > .formControl:not(:-moz-placeholder-shown) ~ label {
  font-size: 12px;
  padding-bottom: 0;
  padding-top: 3px;
}
.form-label-group > .formControl:not(:placeholder-shown) ~ label {
  font-size: 12px;
  padding-bottom: 0;
  padding-top: 3px;
}
.form-label-group > select.formControl:not(:-moz-placeholder-shown) {
  font-weight: 200;
}
.form-label-group > select.formControl:not(:placeholder-shown) {
  font-weight: 200;
}
.form-label-group > select.formControl:valid:not(:-moz-placeholder-shown) {
  color: #495057;
  font-weight: inherit;
}
.form-label-group > select.formControl:valid:not(:placeholder-shown) {
  color: #495057;
  font-weight: inherit;
}
.form-label-group > select:invalid.form-control-lg {
  color: #95a4af;
  font-size: 16px;
  font-weight: inherit;
  height: 48px;
  padding-top: 18px;
}
.form-label-group > select:valid.form-control-lg {
  font-size: 1.25rem;
  padding-bottom: 0;
  padding-top: 16px;
}
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group .formControl::-moz-placeholder {
    color: #95a4af;
  }
  .form-label-group .formControl::placeholder {
    color: #95a4af;
  }
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group .formControl::-moz-placeholder {
    color: #95a4af;
  }
  .form-label-group .formControl::placeholder {
    color: #95a4af;
  }
}
.custom-file-primary .custom-file-label:after {
  background-color: var(--bs-primary);
  color: #fff;
}
.custom-file-secondary .custom-file-label:after {
  background-color: #6c757d;
  color: #fff;
}
.custom-file-success .custom-file-label:after {
  background-color: #6dbb30;
  color: #fff;
}
.custom-file-info .custom-file-label:after {
  background-color: #73e7f7;
  color: #fff;
}
.custom-file-warning .custom-file-label:after {
  background-color: #fad776;
  color: #fff;
}
.custom-file-danger .custom-file-label:after {
  background-color: #f64e60;
  color: #fff;
}
.custom-file-light .custom-file-label:after {
  background-color: #f8f9fa;
  color: #fff;
}
.custom-file-dark .custom-file-label:after {
  background-color: #2e2e2e;
  color: #fff;
}
.custom-file-lg .ustomFileInput,
.custom-file-lg .custom-file-label,
.custom-file-lg .custom-file-label:after,
.custom-file-lg.custom-file-lg {
  font-size: 1rem;
  height: calc(1.5em + 2.18rem + 2px);
}
.custom-file-lg .custom-file-label {
  line-height: 2;
  overflow: hidden;
  padding: 1.09rem 1rem;
}
.custom-file-lg .ustomFileInput,
.custom-file-lg .custom-file-label:after {
  line-height: 2;
  padding: 1.09rem 1rem;
}
.custom-file-sm .ustomFileInput,
.custom-file-sm .custom-file-label,
.custom-file-sm .custom-file-label:after,
.custom-file-sm.custom-file-sm {
  font-size: 1rem;
  height: calc(1.5em + 0.92rem + 2px);
}
.custom-file-sm .custom-file-label {
  overflow: hidden;
}
.custom-file-sm .ustomFileInput,
.custom-file-sm .custom-file-label,
.custom-file-sm .custom-file-label:after {
  line-height: 1.25;
  padding: 0.46rem 1rem;
}
.form-selector {
  border: 1px solid #f1f4f8;
  border-radius: 0.35rem;
  cursor: pointer;
  height: 35px;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  min-width: 35px;
  overflow: hidden;
  position: relative;
}
.form-selector-lg {
  border-radius: 4px;
  height: 46px;
  min-width: 46px;
}
.form-selector-xl {
  border-radius: 4px;
  height: 66px;
  min-width: 66px;
}
a.form-selector {
  color: #1c0950;
  text-decoration: none;
}
.form-selector.roundedCircle {
  border-radius: 50rem;
}
.form-selector > input {
  height: 0;
  opacity: 0.0001;
  position: absolute;
  width: 0;
  z-index: -1;
}
.form-selector > span {
  background-color: #fff;
  border-radius: 0.35rem;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  min-height: 35px;
  min-width: 35px;
  padding: 0.56rem 0.5rem;
  text-align: center;
}
.form-selector-lg > span {
  border-radius: 4px;
  line-height: 1;
  min-height: 46px;
  min-width: 46px;
  padding: 0.89rem 0;
}
.form-selector.roundedCircle > span {
  border-radius: 50rem;
  display: block;
  height: 35px;
  width: 35px;
}
.form-selector > i {
  border-radius: 0.35rem;
  bottom: 4px;
  display: inline-block;
  left: 4px;
  position: absolute;
  right: 4px;
  top: 4px;
}
.form-selector > span:before {
  border: 1px solid transparent;
  border-radius: 0.35rem;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.form-selector.form-selector-lg > span:before,
.form-selector.form-selector-xl > span:before {
  border-radius: 4px;
}
.form-selector.roundedCircle > span:before {
  border-radius: 50rem;
}
.form-selector > i:before {
  border: 1px solid transparent;
  border-radius: 0.35rem;
  bottom: -4px;
  content: "";
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
}
.form-selector.roundedCircle > i,
.form-selector.roundedCircle > i:before {
  border-radius: 50rem;
}
.form-selector input:checked + span,
a.form-selector.active > span {
  background-color: #384c74;
  color: #fff;
}
.form-selector.roundedCircle input:checked + i,
.form-selector:not(.roundedCircle) input:checked + span,
.form-selector > input:checked + i:before {
  border-color: #384c74;
}
.form-selector input:checked + i:not(.bg-cover):after,
a.form-selector.active > i:not(.bg-cover):after {
  color: #fff;
  content: "\f117";
  font-family: Flaticon;
  font-size: 12px;
  left: 50%;
  margin-left: -8px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
}
.was-validated .form-selector > input:invalid + i:before,
.was-validated .form-selector > input:invalid + span:before {
  border-color: #f64e60 !important;
}
.form-selector input[disabled] + i,
.form-selector input[disabled] + span {
  background-color: #f1f4f8 !important;
  cursor: auto;
  opacity: 0.6;
}
.form-selector input[disabled] + i:before {
  background-color: #fff;
  opacity: 0.5 !important;
}
nav.navbarLight a.navLink:not(.active):not(:hover) {
  color: #1c0950;
}
.navbarLight .navbarNav .navItem:hover > .navLink,
.navbarLight .navbarNav .navLink[aria-expanded="true"] {
  color: var(--bs-danger) !important;
}
.navbar-dark .navbarNav .navItem:hover > .navLink,
.navbar-dark .navbarNav .navLink[aria-expanded="true"] {
  color: #fff !important;
}
nav.navDeep {
  font-size: 0.92rem;
}
nav.navDeep .navItem .groupIcon {
  font-size: 10px;
  margin-top: 3px;
}
nav.nav-deep-dark .navItem .groupIcon {
  color: #bfc6d7 !important;
}
nav.nav-deep-dark-contrast .navItem .groupIcon {
  color: #fff !important;
}
nav.navDeepLight .navItem .groupIcon {
  color: #1c0950 !important;
}
nav.navDeepLight .navItem > a.navLink {
  color: #1c0950;
}
nav.navDeepLight .navItem.active > .navLink,
nav.navDeepLight .navItem:hover > .navLink {
  color: var(--bs-primary);
}
nav.navDeepLight.nav-deep-hover > ul > li.navItem.active,
nav.navDeepLight.nav-deep-hover > ul > li.navItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
nav.nav-deep-dark .navItem > a.navLink {
  color: #bfc6d7;
}
nav.nav-deep-dark .navItem.active > .navLink,
nav.nav-deep-dark .navItem:hover > .navLink,
nav.nav-deep-dark-contrast .navItem > a.navLink {
  color: #fff;
}
nav.nav-deep-dark .navItem.active > .navLink,
nav.navDeepLight .navItem.active > .navLink {
  font-weight: 500;
}
nav.nav-deep-dark.nav-deep-hover > ul > li.navItem.active,
nav.nav-deep-dark.nav-deep-hover > ul > li.navItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
aside nav.nav-deep-indicator-dot ul li .navItem.active > .navLink {
  position: relative;
}
aside
  nav.nav-deep-dark.nav-deep-indicator-dot
  ul
  li
  .navItem.active
  > .navLink:before,
aside
  nav.navDeepLight.nav-deep-indicator-dot
  ul
  li
  .navItem.active
  > .navLink:before {
  background-color: #fff;
  border-radius: 100%;
  content: "";
  height: 4px;
  left: 0;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 4px;
}
aside
  nav.navDeepLight.nav-deep-indicator-dot
  ul
  li
  .navItem.active
  > .navLink:before {
  background-color: #1c0950;
}
.nav-deep-bordered > ul > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
nav.navDeep > ul > li > a.navLink > i.nav-icon {
  float: left;
  font-size: 16px;
  margin-right: 10px;
  margin-top: -0.2rem;
  text-align: left;
  width: 20px;
}
nav.navDeep > ul ul > li > a.navLink > i,
nav.navDeep > ul > li > a.navLink > i:not(.nav-icon) {
  margin-right: 10px;
}
nav.navDeep > ul > li > a.navLink > svg {
  margin-right: 10px;
  margin-top: -4px;
}
.navDeep ul > li:not(.active) > ul {
  display: none;
}
.navDeep > ul ul {
  display: block;
}
.navDeep > ul > li > ul {
  padding-left: 30px;
}
.navDeep > ul > li > ul > li > a > i {
  margin-left: -27px;
  margin-right: 8px;
}
.navDeep > ul > li > ul ul {
  padding-left: 15px;
}
.nav-default a {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  padding: 0.54rem 1rem;
  text-decoration: none;
  transition: all 0.33s;
}
.nav-default a.active,
.nav-default a.active span,
.nav-default a:hover,
.nav-default a:hover span,
.nav-default li.active > a,
.nav-default li.active > a span {
  color: var(--bs-primary) !important;
}
.nav-default a.active,
.nav-default a:hover,
.nav-default li.active > a {
  background-color: #f9fbfd;
}
.nav-default a > i,
.nav-default a > span.groupIcon {
  color: #869ab8;
  flex: 0 0 36px;
  font-size: 1.25rem;
  line-height: 0;
}
.nav-default a > span:not(.groupIcon) {
  color: #506690;
  flex-grow: 1;
  font-size: 1rem;
}
.rating-0,
.rating-0_0,
.rating-0_5,
.rating-1,
.rating-1_0,
.rating-1_5,
.rating-2,
.rating-2_0,
.rating-2_5,
.rating-3,
.rating-3_0,
.rating-3_5,
.rating-4,
.rating-4_0,
.rating-4_5,
.rating-5,
.rating-5_0 {
  font: normal normal normal 18px/1 Flaticon;
  letter-spacing: 3px;
}
.rating-0:before,
.rating-0_0:before {
  content: "\f173 \f173 \f173 \f173 \f173 ";
  display: inline-block;
}
.rating-0_5:before {
  content: "\f191 \f173 \f173 \f173 \f173 ";
  display: inline-block;
}
.rating-1:before,
.rating-1_0:before {
  content: "\f190 \f173 \f173 \f173 \f173 ";
  display: inline-block;
}
.rating-1_5:before {
  content: "\f190 \f191 \f173 \f173 \f173 ";
  display: inline-block;
}
.rating-2:before,
.rating-2_0:before {
  content: "\f190 \f190 \f173 \f173 \f173 ";
  display: inline-block;
}
.rating-2_5:before {
  content: "\f190 \f190 \f191 \f173 \f173 ";
  display: inline-block;
}
.rating-3:before,
.rating-3_0:before {
  content: "\f190 \f190 \f190 \f173 \f173 ";
  display: inline-block;
}
.rating-3_5:before {
  content: "\f190 \f190 \f190 \f191 \f173 ";
  display: inline-block;
}
.rating-4:before,
.rating-4_0:before {
  content: "\f190 \f190 \f190 \f190 \f173 ";
  display: inline-block;
}
.rating-4_5:before {
  content: "\f190 \f190 \f190 \f190 \f191 ";
  display: inline-block;
}
.rating-5:before,
.rating-5_0:before {
  content: "\f190 \f190 \f190 \f190 \f190 ";
  display: inline-block;
}
.flag {
  background: url(../images/flags.png) no-repeat;
  display: inline-block;
  height: 11px;
  width: 16px;
}
.bootstrap-select .dropdownMenu .flag,
.bootstrap-select button .flag {
  margin-right: 10px;
}
.flag.flag-ad {
  background-position: -16px 0;
}
.flag.flag-ae {
  background-position: -32px 0;
}
.flag.flag-af {
  background-position: -48px 0;
}
.flag.flag-ag {
  background-position: -64px 0;
}
.flag.flag-ai {
  background-position: -80px 0;
}
.flag.flag-al {
  background-position: -96px 0;
}
.flag.flag-am {
  background-position: -112px 0;
}
.flag.flag-an {
  background-position: -128px 0;
}
.flag.flag-ao {
  background-position: -144px 0;
}
.flag.flag-ar {
  background-position: -160px 0;
}
.flag.flag-as {
  background-position: -176px 0;
}
.flag.flag-at {
  background-position: -192px 0;
}
.flag.flag-au {
  background-position: -208px 0;
}
.flag.flag-aw {
  background-position: -224px 0;
}
.flag.flag-az {
  background-position: -240px 0;
}
.flag.flag-ba {
  background-position: 0 -11px;
}
.flag.flag-bb {
  background-position: -16px -11px;
}
.flag.flag-bd {
  background-position: -32px -11px;
}
.flag.flag-be {
  background-position: -48px -11px;
}
.flag.flag-bf {
  background-position: -64px -11px;
}
.flag.flag-bg {
  background-position: -80px -11px;
}
.flag.flag-bh {
  background-position: -96px -11px;
}
.flag.flag-bi {
  background-position: -112px -11px;
}
.flag.flag-bj {
  background-position: -128px -11px;
}
.flag.flag-bm {
  background-position: -144px -11px;
}
.flag.flag-bn {
  background-position: -160px -11px;
}
.flag.flag-bo {
  background-position: -176px -11px;
}
.flag.flag-br {
  background-position: -192px -11px;
}
.flag.flag-bs {
  background-position: -208px -11px;
}
.flag.flag-bt {
  background-position: -224px -11px;
}
.flag.flag-bv {
  background-position: -240px -11px;
}
.flag.flag-bw {
  background-position: 0 -22px;
}
.flag.flag-by {
  background-position: -16px -22px;
}
.flag.flag-bz {
  background-position: -32px -22px;
}
.flag.flag-ca {
  background-position: -48px -22px;
}
.flag.flag-catalonia {
  background-position: -64px -22px;
}
.flag.flag-cd {
  background-position: -80px -22px;
}
.flag.flag-cf {
  background-position: -96px -22px;
}
.flag.flag-cg {
  background-position: -112px -22px;
}
.flag.flag-ch {
  background-position: -128px -22px;
}
.flag.flag-ci {
  background-position: -144px -22px;
}
.flag.flag-ck {
  background-position: -160px -22px;
}
.flag.flag-cl {
  background-position: -176px -22px;
}
.flag.flag-cm {
  background-position: -192px -22px;
}
.flag.flag-cn {
  background-position: -208px -22px;
}
.flag.flag-co {
  background-position: -224px -22px;
}
.flag.flag-cr {
  background-position: -240px -22px;
}
.flag.flag-cu {
  background-position: 0 -33px;
}
.flag.flag-cv {
  background-position: -16px -33px;
}
.flag.flag-cw {
  background-position: -32px -33px;
}
.flag.flag-cy {
  background-position: -48px -33px;
}
.flag.flag-cz {
  background-position: -64px -33px;
}
.flag.flag-de {
  background-position: -80px -33px;
}
.flag.flag-dj {
  background-position: -96px -33px;
}
.flag.flag-dk {
  background-position: -112px -33px;
}
.flag.flag-dm {
  background-position: -128px -33px;
}
.flag.flag-do {
  background-position: -144px -33px;
}
.flag.flag-dz {
  background-position: -160px -33px;
}
.flag.flag-ec {
  background-position: -176px -33px;
}
.flag.flag-ee {
  background-position: -192px -33px;
}
.flag.flag-eg {
  background-position: -208px -33px;
}
.flag.flag-eh {
  background-position: -224px -33px;
}
.flag.flag-england {
  background-position: -240px -33px;
}
.flag.flag-er {
  background-position: 0 -44px;
}
.flag.flag-es {
  background-position: -16px -44px;
}
.flag.flag-et {
  background-position: -32px -44px;
}
.flag.flag-eu {
  background-position: -48px -44px;
}
.flag.flag-fi {
  background-position: -64px -44px;
}
.flag.flag-fj {
  background-position: -80px -44px;
}
.flag.flag-fk {
  background-position: -96px -44px;
}
.flag.flag-fm {
  background-position: -112px -44px;
}
.flag.flag-fo {
  background-position: -128px -44px;
}
.flag.flag-fr {
  background-position: -144px -44px;
}
.flag.flag-ga {
  background-position: -160px -44px;
}
.flag.flag-gb {
  background-position: -176px -44px;
}
.flag.flag-gd {
  background-position: -192px -44px;
}
.flag.flag-ge {
  background-position: -208px -44px;
}
.flag.flag-gf {
  background-position: -224px -44px;
}
.flag.flag-gg {
  background-position: -240px -44px;
}
.flag.flag-gh {
  background-position: 0 -55px;
}
.flag.flag-gi {
  background-position: -16px -55px;
}
.flag.flag-gl {
  background-position: -32px -55px;
}
.flag.flag-gm {
  background-position: -48px -55px;
}
.flag.flag-gn {
  background-position: -64px -55px;
}
.flag.flag-gp {
  background-position: -80px -55px;
}
.flag.flag-gq {
  background-position: -96px -55px;
}
.flag.flag-gr {
  background-position: -112px -55px;
}
.flag.flag-gs {
  background-position: -128px -55px;
}
.flag.flag-gt {
  background-position: -144px -55px;
}
.flag.flag-gu {
  background-position: -160px -55px;
}
.flag.flag-gw {
  background-position: -176px -55px;
}
.flag.flag-gy {
  background-position: -192px -55px;
}
.flag.flag-hk {
  background-position: -208px -55px;
}
.flag.flag-hm {
  background-position: -224px -55px;
}
.flag.flag-hn {
  background-position: -240px -55px;
}
.flag.flag-hr {
  background-position: 0 -66px;
}
.flag.flag-ht {
  background-position: -16px -66px;
}
.flag.flag-hu {
  background-position: -32px -66px;
}
.flag.flag-ic {
  background-position: -48px -66px;
}
.flag.flag-id {
  background-position: -64px -66px;
}
.flag.flag-ie {
  background-position: -80px -66px;
}
.flag.flag-il {
  background-position: -96px -66px;
}
.flag.flag-im {
  background-position: -112px -66px;
}
.flag.flag-in {
  background-position: -128px -66px;
}
.flag.flag-io {
  background-position: -144px -66px;
}
.flag.flag-iq {
  background-position: -160px -66px;
}
.flag.flag-ir {
  background-position: -176px -66px;
}
.flag.flag-is {
  background-position: -192px -66px;
}
.flag.flag-it {
  background-position: -208px -66px;
}
.flag.flag-je {
  background-position: -224px -66px;
}
.flag.flag-jm {
  background-position: -240px -66px;
}
.flag.flag-jo {
  background-position: 0 -77px;
}
.flag.flag-jp {
  background-position: -16px -77px;
}
.flag.flag-ke {
  background-position: -32px -77px;
}
.flag.flag-kg {
  background-position: -48px -77px;
}
.flag.flag-kh {
  background-position: -64px -77px;
}
.flag.flag-ki {
  background-position: -80px -77px;
}
.flag.flag-km {
  background-position: -96px -77px;
}
.flag.flag-kn {
  background-position: -112px -77px;
}
.flag.flag-kp {
  background-position: -128px -77px;
}
.flag.flag-kr {
  background-position: -144px -77px;
}
.flag.flag-kurdistan {
  background-position: -160px -77px;
}
.flag.flag-kw {
  background-position: -176px -77px;
}
.flag.flag-ky {
  background-position: -192px -77px;
}
.flag.flag-kz {
  background-position: -208px -77px;
}
.flag.flag-la {
  background-position: -224px -77px;
}
.flag.flag-lb {
  background-position: -240px -77px;
}
.flag.flag-lc {
  background-position: 0 -88px;
}
.flag.flag-li {
  background-position: -16px -88px;
}
.flag.flag-lk {
  background-position: -32px -88px;
}
.flag.flag-lr {
  background-position: -48px -88px;
}
.flag.flag-ls {
  background-position: -64px -88px;
}
.flag.flag-lt {
  background-position: -80px -88px;
}
.flag.flag-lu {
  background-position: -96px -88px;
}
.flag.flag-lv {
  background-position: -112px -88px;
}
.flag.flag-ly {
  background-position: -128px -88px;
}
.flag.flag-ma {
  background-position: -144px -88px;
}
.flag.flag-mc {
  background-position: -160px -88px;
}
.flag.flag-md {
  background-position: -176px -88px;
}
.flag.flag-me {
  background-position: -192px -88px;
}
.flag.flag-mg {
  background-position: -208px -88px;
}
.flag.flag-mh {
  background-position: -224px -88px;
}
.flag.flag-mk {
  background-position: -240px -88px;
}
.flag.flag-ml {
  background-position: 0 -99px;
}
.flag.flag-mm {
  background-position: -16px -99px;
}
.flag.flag-mn {
  background-position: -32px -99px;
}
.flag.flag-mo {
  background-position: -48px -99px;
}
.flag.flag-mp {
  background-position: -64px -99px;
}
.flag.flag-mq {
  background-position: -80px -99px;
}
.flag.flag-mr {
  background-position: -96px -99px;
}
.flag.flag-ms {
  background-position: -112px -99px;
}
.flag.flag-mt {
  background-position: -128px -99px;
}
.flag.flag-mu {
  background-position: -144px -99px;
}
.flag.flag-mv {
  background-position: -160px -99px;
}
.flag.flag-mw {
  background-position: -176px -99px;
}
.flag.flag-mx {
  background-position: -192px -99px;
}
.flag.flag-my {
  background-position: -208px -99px;
}
.flag.flag-mz {
  background-position: -224px -99px;
}
.flag.flag-na {
  background-position: -240px -99px;
}
.flag.flag-nc {
  background-position: 0 -110px;
}
.flag.flag-ne {
  background-position: -16px -110px;
}
.flag.flag-nf {
  background-position: -32px -110px;
}
.flag.flag-ng {
  background-position: -48px -110px;
}
.flag.flag-ni {
  background-position: -64px -110px;
}
.flag.flag-nl {
  background-position: -80px -110px;
}
.flag.flag-no {
  background-position: -96px -110px;
}
.flag.flag-np {
  background-position: -112px -110px;
}
.flag.flag-nr {
  background-position: -128px -110px;
}
.flag.flag-nu {
  background-position: -144px -110px;
}
.flag.flag-nz {
  background-position: -160px -110px;
}
.flag.flag-om {
  background-position: -176px -110px;
}
.flag.flag-pa {
  background-position: -192px -110px;
}
.flag.flag-pe {
  background-position: -208px -110px;
}
.flag.flag-pf {
  background-position: -224px -110px;
}
.flag.flag-pg {
  background-position: -240px -110px;
}
.flag.flag-ph {
  background-position: 0 -121px;
}
.flag.flag-pk {
  background-position: -16px -121px;
}
.flag.flag-pl {
  background-position: -32px -121px;
}
.flag.flag-pm {
  background-position: -48px -121px;
}
.flag.flag-pn {
  background-position: -64px -121px;
}
.flag.flag-pr {
  background-position: -80px -121px;
}
.flag.flag-ps {
  background-position: -96px -121px;
}
.flag.flag-pt {
  background-position: -112px -121px;
}
.flag.flag-pw {
  background-position: -128px -121px;
}
.flag.flag-py {
  background-position: -144px -121px;
}
.flag.flag-qa {
  background-position: -160px -121px;
}
.flag.flag-re {
  background-position: -176px -121px;
}
.flag.flag-ro {
  background-position: -192px -121px;
}
.flag.flag-rs {
  background-position: -208px -121px;
}
.flag.flag-ru {
  background-position: -224px -121px;
}
.flag.flag-rw {
  background-position: -240px -121px;
}
.flag.flag-sa {
  background-position: 0 -132px;
}
.flag.flag-sb {
  background-position: -16px -132px;
}
.flag.flag-sc {
  background-position: -32px -132px;
}
.flag.flag-scotland {
  background-position: -48px -132px;
}
.flag.flag-sd {
  background-position: -64px -132px;
}
.flag.flag-se {
  background-position: -80px -132px;
}
.flag.flag-sg {
  background-position: -96px -132px;
}
.flag.flag-sh {
  background-position: -112px -132px;
}
.flag.flag-si {
  background-position: -128px -132px;
}
.flag.flag-sk {
  background-position: -144px -132px;
}
.flag.flag-sl {
  background-position: -160px -132px;
}
.flag.flag-sm {
  background-position: -176px -132px;
}
.flag.flag-sn {
  background-position: -192px -132px;
}
.flag.flag-so {
  background-position: -208px -132px;
}
.flag.flag-somaliland {
  background-position: -224px -132px;
}
.flag.flag-sr {
  background-position: -240px -132px;
}
.flag.flag-ss {
  background-position: 0 -143px;
}
.flag.flag-st {
  background-position: -16px -143px;
}
.flag.flag-sv {
  background-position: -32px -143px;
}
.flag.flag-sx {
  background-position: -48px -143px;
}
.flag.flag-sy {
  background-position: -64px -143px;
}
.flag.flag-sz {
  background-position: -80px -143px;
}
.flag.flag-tc {
  background-position: -96px -143px;
}
.flag.flag-td {
  background-position: -112px -143px;
}
.flag.flag-tf {
  background-position: -128px -143px;
}
.flag.flag-tg {
  background-position: -144px -143px;
}
.flag.flag-th {
  background-position: -160px -143px;
}
.flag.flag-tibet {
  background-position: -176px -143px;
}
.flag.flag-tj {
  background-position: -192px -143px;
}
.flag.flag-tk {
  background-position: -208px -143px;
}
.flag.flag-tl {
  background-position: -224px -143px;
}
.flag.flag-tm {
  background-position: -240px -143px;
}
.flag.flag-tn {
  background-position: 0 -154px;
}
.flag.flag-to {
  background-position: -16px -154px;
}
.flag.flag-tr {
  background-position: -32px -154px;
}
.flag.flag-tt {
  background-position: -48px -154px;
}
.flag.flag-tv {
  background-position: -64px -154px;
}
.flag.flag-tw {
  background-position: -80px -154px;
}
.flag.flag-tz {
  background-position: -96px -154px;
}
.flag.flag-ua {
  background-position: -112px -154px;
}
.flag.flag-ug {
  background-position: -128px -154px;
}
.flag.flag-um {
  background-position: -144px -154px;
}
.flag.flag-us {
  background-position: -160px -154px;
}
.flag.flag-uy {
  background-position: -176px -154px;
}
.flag.flag-uz {
  background-position: -192px -154px;
}
.flag.flag-va {
  background-position: -208px -154px;
}
.flag.flag-vc {
  background-position: -224px -154px;
}
.flag.flag-ve {
  background-position: -240px -154px;
}
.flag.flag-vg {
  background-position: 0 -165px;
}
.flag.flag-vi {
  background-position: -16px -165px;
}
.flag.flag-vn {
  background-position: -32px -165px;
}
.flag.flag-vu {
  background-position: -48px -165px;
}
.flag.flag-wales {
  background-position: -64px -165px;
}
.flag.flag-wf {
  background-position: -80px -165px;
}
.flag.flag-ws {
  background-position: -96px -165px;
}
.flag.flag-xk {
  background-position: -112px -165px;
}
.flag.flag-ye {
  background-position: -128px -165px;
}
.flag.flag-yt {
  background-position: -144px -165px;
}
.flag.flag-za {
  background-position: -160px -165px;
}
.flag.flag-zanzibar {
  background-position: -176px -165px;
}
.flag.flag-zm {
  background-position: -192px -165px;
}
.flag.flag-zw {
  background-position: -208px -165px;
}
@font-face {
  font-display: swap;
  font-family: Flaticon;
  font-style: normal;
  font-weight: 400;
  src: local("Flaticon"), url(../fonts/flaticon/Flaticon.woff2) format("woff2"),
    url(../fonts/flaticon/Flaticon.woff) format("woff"),
    url(../fonts/flaticon/Flaticon.ttf) format("truetype"),
    url(../fonts/flaticon/Flaticon.eot) format("embedded-opentype"),
    url(../fonts/flaticon/Flaticon.eot?#iefix) format("embedded-opentype"),
    url(../fonts/flaticon/Flaticon.svg#Flaticon) format("svg");
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: Flaticon;
    src: url(../fonts/flaticon/Flaticon.svg#Flaticon) format("svg");
  }
}
.fi:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: Flaticon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
}
.fi.d-middle {
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}
.fi.d-middle:before {
  margin: 0 !important;
  padding: 0 !important;
}
.iconsPreview a {
  color: #7e8299;
}
.iconsPreview a:hover {
  background-color: #f3f6f9;
}
.btn:not(.roundedCircle):not(.btnSm) i.fi {
  font-size: 15px;
}
.btn-group-sm > .btn:not(.roundedCircle) i.fi,
.btnSm:not(.roundedCircle) i.fi {
  font-size: 13px;
}
.fi {
  display: inline-block;
  vertical-align: middle;
}
.h1 .fi:before,
.h2 .fi:before,
.h3 .fi:before,
.h4 .fi:before,
.h5 .fi:before,
.h6 .fi:before,
h1 .fi:before,
h2 .fi:before,
h3 .fi:before,
h4 .fi:before,
h5 .fi:before,
h6 .fi:before {
  line-height: 1.18;
}
button.close {
  font-size: 1rem;
}
.fi-spin:before {
  animation: spin 1.3s linear infinite;
  display: inline-block;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
.mdi-close:before {
  content: "\e900";
}
.mdi-format_align_center:before {
  content: "\e901";
}
.mdi-format_align_justify:before {
  content: "\e902";
}
.mdi-format_align_left:before {
  content: "\e903";
}
.mdi-format_align_right:before {
  content: "\e904";
}
.mdi-format_bold:before {
  content: "\e905";
}
.mdi-format_clear:before {
  content: "\e906";
}
.mdi-format_color_fill:before {
  content: "\e907";
}
.mdi-format_color_reset:before {
  content: "\e908";
}
.mdi-format_color_text:before {
  content: "\e909";
}
.mdi-format_indent_decrease:before {
  content: "\e90a";
}
.mdi-format_indent_increase:before {
  content: "\e90b";
}
.mdi-format_italic:before {
  content: "\e90c";
}
.mdi-format_line_spacing:before {
  content: "\e90d";
}
.mdi-format_list_bulleted:before {
  content: "\e90e";
}
.mdi-format_list_numbered:before {
  content: "\e90f";
}
.mdi-filter_1:before {
  content: "\e910";
}
.mdi-filter_2:before {
  content: "\e911";
}
.mdi-filter_3:before {
  content: "\e912";
}
.mdi-filter_4:before {
  content: "\e913";
}
.mdi-filter_5:before {
  content: "\e914";
}
.mdi-filter_6:before {
  content: "\e915";
}
.mdi-filter_7:before {
  content: "\e916";
}
.mdi-filter_8:before {
  content: "\e917";
}
.mdi-filter_9:before {
  content: "\e918";
}
.mdi-check:before {
  content: "\e91a";
}
.fi-user-female:before {
  content: "\f1ad";
}
.fi-eq-vertical:before {
  content: "\f16b";
}
.fiArrowDown:before {
  content: "\f1c4";
}
.fi-heart-full:before {
  content: "\f18d";
}
.fi-eye:before {
  content: "\f18b";
}
.fi-shape-hexa:before {
  content: "\f143";
}
.fi-layers:before {
  content: "\f16d";
}
.fi-start-half:before {
  content: "\f191";
}
.fi-drop:before {
  content: "\f11c";
}
.fi-colors:before {
  content: "\f105";
}
.fi-menu-dots:before {
  content: "\f1e2";
}
.fi-percent:before {
  content: "\f139";
}
.fi-mic-disabled:before {
  content: "\f14e";
}
.fi-thrash:before {
  content: "\f1ba";
}
.fi-eye-disabled:before {
  content: "\f18a";
}
.fi-round-close:before {
  content: "\f1a8";
}
.fi-brand-apple:before {
  content: "\f1e1";
}
.fi-mollecules:before {
  content: "\f17c";
}
.fi-minus:before {
  content: "\f196";
}
.fi-support-1:before {
  content: "\f123";
}
.fi-arrow-up:before {
  content: "\f19b";
}
.fi-shield-ok:before {
  content: "\f12d";
}
.fi-task-list:before {
  content: "\f15b";
}
.fi-print:before {
  content: "\f102";
}
.fi-heart-half:before {
  content: "\f18e";
}
.fi-chat:before {
  content: "\f11f";
}
.fi-dots-vertical:before {
  content: "\f152";
  display: inherit;
  margin-top: -2px;
}
.fi-arrow-upload:before {
  content: "\f1da";
}
.fi-direction:before {
  content: "\f14b";
}
.fi-cart-1:before {
  content: "\f1a1";
}
.fi-layers-middle:before {
  content: "\f1e3";
}
.fi-circle-spin:before {
  content: "\f1e0";
}
.fi-play-empty:before {
  content: "\f188";
}
.fi-search:before {
  content: "\f1b6";
}
.fi-envelope:before {
  content: "\f1a0";
}
.fi-arrow-up-slim:before {
  content: "\f197";
}
.fi-expand:before {
  content: "\f19f";
}
.fi-arrow-right-full:before {
  content: "\f1bc";
}
.fi-cog-full:before {
  content: "\f13b";
}
.fi-pencil:before {
  content: "\f1b2";
}
.fi-graduate:before {
  content: "\f1c6";
}
.fi-smartphone:before {
  content: "\f159";
}
.fi-like:before {
  content: "\f1b7";
}
.fi-arrow-left-full:before {
  content: "\f1bd";
}
.fi-folder-full:before {
  content: "\f15d";
}
.fi-smartphone-chat:before {
  content: "\f122";
}
.fi-squared-info:before {
  content: "\f125";
}
.fi-eco:before {
  content: "\f11a";
}
.fi-cog:before {
  content: "\f1b8";
}
.fi-heart-empty:before {
  content: "\f18f";
}
.fi-round-target:before {
  content: "\f16f";
}
.fi-bell:before {
  content: "\f163";
}
.fi-squared-minus:before {
  content: "\f194";
}
.fi-share:before {
  content: "\f17e";
}
.fi-recycle:before {
  content: "\f11b";
}
.fi-hourglass:before {
  content: "\f16e";
}
.fi-power:before {
  content: "\f133";
}
.fi-phone-24:before {
  content: "\f128";
}
.fi-plus:before {
  content: "\f195";
}
.fi-shape-abstract-cube:before {
  content: "\f106";
}
.fi-round-info-full:before {
  content: "\f13e";
}
.fi-layerstore:before {
  content: "\f11e";
}
.fi-envelope-2:before {
  content: "\f1cf";
}
.fi-database:before {
  content: "\f176";
}
.fi-sound-on:before {
  content: "\f1cc";
}
.fi-unlink:before {
  content: "\f158";
}
.fi-home:before {
  content: "\f1a9";
}
.fi-check:before {
  content: "\f117";
  display: inline-block;
  font-size: 93%;
  padding-top: 0.06rem;
}
.fi-users:before {
  content: "\f148";
}
.fi-box:before {
  content: "\f156";
}
.fi-heart-slim:before {
  content: "\f174";
}
.fi-arrow-download:before {
  content: "\f1db";
}
.fi-phone:before {
  content: "\f1a3";
}
.fi-orbit:before {
  content: "\f1de";
}
.fi-bell-full-active:before {
  content: "\f1d6";
}
.fi-brand-android:before {
  content: "\f1d1";
}
.fi-smart-devices:before {
  content: "\f100";
}
.fi-pin-2:before {
  content: "\f171";
}
.fi-arrow-down-slim:before {
  content: "\f198";
}
.fi-box-checked:before {
  content: "\f1c9";
}
.fi-atom:before {
  content: "\f179";
}
.fi-arrow-right-3:before {
  content: "\f1b9";
}
.fi-star-empty:before {
  content: "\f1b3";
}
.fi-loading-dots:before {
  content: "\f131";
}
.fi-arrow-up-full:before {
  content: "\f1be";
}
.fi-eq-horizontal:before {
  content: "\f16a";
}
.fi-round-play:before {
  content: "\f187";
}
.fi-list-checked:before {
  content: "\f1c7";
}
.fi-star-empty-radius:before {
  content: "\f173";
}
.fi-target-simple:before {
  content: "\f112";
}
.fi-road:before {
  content: "\f12c";
}
.fi-mic:before {
  content: "\f14f";
}
.fi-round-list:before {
  content: "\f181";
}
.fi-brand-windows:before {
  content: "\f1df";
}
.fi-cart-2:before {
  content: "\f1a2";
}
.fi-product-tag:before {
  content: "\f155";
}
.fi-globe:before {
  content: "\f130";
}
.fi-cart-3:before {
  content: "\f129";
}
.fi-colors-2:before {
  content: "\f134";
}
.fi-lightbulb:before {
  content: "\f109";
}
.fi-round-question-full:before {
  content: "\f1d3";
}
.fi-bars-2:before {
  content: "\f116";
}
.fi-attachment:before {
  content: "\f13d";
}
.fi-checklist-squared:before {
  content: "\f1ce";
}
.fi-graph:before {
  content: "\f110";
}
.fi-selectable:before {
  content: "\f104";
}
.fi-cloud-upload:before {
  content: "\f138";
}
.fi-mdeal:before {
  content: "\f12b";
}
.fi-24:before {
  content: "\f127";
}
.fi-cart-4:before {
  content: "\f10c";
}
.fi-list:before {
  content: "\f1dd";
}
.fi-user-plus:before {
  content: "\f119";
}
.fi-truck-speed:before {
  content: "\f12f";
}
.fi-arrow-down-full:before {
  content: "\f1bf";
}
.fi-cogs:before {
  content: "\f13c";
}
.fi-dashboard:before {
  content: "\f140";
}
.fi-arrow-right-slim:before {
  content: "\f19a";
}
.fi-lightning:before {
  content: "\f11d";
}
.fi-dots-horizontal:before {
  content: "\f153";
}
.fi-loading:before {
  content: "\f1a5";
}
.fi-exit:before {
  content: "\f151";
}
.fi-plus-slim:before {
  content: "\f114";
}
.fi-umbrella:before {
  content: "\f169";
}
.fi-gps:before {
  content: "\f14c";
}
.fi-locked:before {
  content: "\f145";
}
.fi-close:before {
  content: "\f103";
  display: inherit;
  font-size: 82%;
  padding-left: 1px;
  padding-top: 0.156rem;
}
.btn .fi-close:before {
  padding-top: 0 !important;
}
.fi-link:before {
  content: "\f15a";
}
.fi-shape-3dots:before {
  content: "\f17d";
}
.fi-round-question:before {
  content: "\f165";
}
.fi-image:before {
  content: "\f18c";
}
.fi-round-plus:before {
  content: "\f186";
}
.fi-support:before {
  content: "\f10f";
}
.fi-go-back:before {
  content: "\f111";
}
.fi-play:before {
  content: "\f137";
}
.fi-dislike:before {
  content: "\f1a4";
}
.fi-chart-up:before {
  content: "\f1c5";
}
.fi-map:before {
  content: "\f172";
}
.fi-dots-triangle:before {
  content: "\f142";
}
.fi-dots-horizontal-full:before {
  content: "\f1d2";
}
.fi-reload:before {
  content: "\f113";
}
.fi-bookmark:before {
  content: "\f118";
}
.fi-shape-abstract-dots:before {
  content: "\f1cd";
}
.fi-arrow-left:before {
  content: "\f1ac";
}
.fiBars:before {
  content: "\f17b";
}
.fi-speaker-mute:before {
  content: "\f1cb";
}
.fi-send:before {
  content: "\f149";
}
.fi-brain:before {
  content: "\f13f";
}
.fi-bell-active:before {
  content: "\f162";
}
.fi-arrow-left-slim:before {
  content: "\f199";
}
.fi-folder-empty:before {
  content: "\f15e";
}
.fi-cart-5:before {
  content: "\f10b";
}
.fi-shrink:before {
  content: "\f19c";
}
.fi-pin:before {
  content: "\f124";
}
.fi-squares:before {
  content: "\f141";
}
.fi-bell-full:before {
  content: "\f1d8";
}
.fi-squared-graph:before {
  content: "\f136";
}
.fi-squared-dots:before {
  content: "\f154";
}
.fi-gift:before {
  content: "\f164";
}
.fi-star:before {
  content: "\f192";
}
.fi-time:before {
  content: "\f15f";
}
.fi-cloud-download:before {
  content: "\f1dc";
}
.fi-shape-star:before {
  content: "\f1ca";
}
.fi-loud:before {
  content: "\f178";
}
.fi-dots-vertical-full:before {
  content: "\f1d0";
  display: inherit;
  margin-top: -2px;
}
.fi-user-male:before {
  content: "\f147";
}
.fi-star-full:before {
  content: "\f190";
}
.fi-help:before {
  content: "\f168";
}
.fi-fingerprint:before {
  content: "\f167";
}
.fi-plus-squared:before {
  content: "\f193";
}
.fi-round-lightning:before {
  content: "\f12a";
}
.fi-pie-chart:before {
  content: "\f1b1";
}
.fi-arrow-right:before {
  content: "\f1ae";
}
.fi-calendar:before {
  content: "\f108";
}
.fi-shape-squared-unequal:before {
  content: "\f135";
}
.fi-cart-6:before {
  content: "\f1d7";
}
.fi-support-headphones:before {
  content: "\f120";
}
.fi-code:before {
  content: "\f14d";
}
.fi-brand-amazon:before {
  content: "\e004";
}
.fi-social-facebook:before {
  content: "\e028";
}
.fi-social-linkedin:before {
  content: "\e04c";
}
.fi-social-pinterest:before {
  content: "\e063";
}
.fi-social-rss:before {
  content: "\e071";
}
.fi-social-snapchat:before {
  content: "\e076";
}
.fi-social-twitter:before {
  content: "\e08d";
}
.fi-social-vimeo:before {
  content: "\e093";
}
.fi-social-youtube:before {
  content: "\e0a5";
}
.fi-social-unsplash:before {
  content: "\e91b";
}
.fi-homeadvisor:before {
  content: "\e91c";
}
.fi-movie:before {
  content: "\e921";
}
.fi-cart-7:before {
  content: "\e93a";
}
.fi-emoji-happy:before {
  content: "\e9e0";
}
.fi-emoji-smile:before {
  content: "\e9e2";
}
.fi-emoji-tongue:before {
  content: "\e9e4";
}
.fi-emoji-sad:before {
  content: "\e9e6";
}
.fi-emoji-wink:before {
  content: "\e9e8";
}
.fi-emoji-grin:before {
  content: "\e9ea";
}
.fi-emoji-cool:before {
  content: "\e9ec";
}
.fi-emoji-angry:before {
  content: "\e9ee";
}
.fi-emoji-evil:before {
  content: "\e9f0";
}
.fi-emoji-shocked:before {
  content: "\e9f2";
}
.fi-emoji-baffled:before {
  content: "\e9f4";
}
.fi-emoji-confused:before {
  content: "\e9f6";
}
.fi-emoji-neutral:before {
  content: "\e9f8";
}
.fi-emoji-hipster:before {
  content: "\e9fa";
}
.fi-emoji-wondering:before {
  content: "\e9fc";
}
.fi-emoji-sleepy:before {
  content: "\e9fe";
}
.fi-emoji-frustrated:before {
  content: "\ea00";
}
.fi-emoji-crying:before {
  content: "\ea02";
}
.fi-brand-paypal:before {
  content: "\ead8";
}
.fi-social-twitch:before {
  content: "\e920";
}
.fi-social-spotify:before {
  content: "\e91d";
}
.fi-social-instagram:before {
  content: "\e91e";
}
.fi-social-github:before {
  content: "\e922";
}
.fi-social-soundcloud:before {
  content: "\e91f";
}
.fi-arrow-start:before {
  content: "\f1ac";
}
.fiArrowEnd:before {
  content: "\f1ae";
}
.fi-arrow-start-slim:before {
  content: "\f199";
}
.fi-arrow-end-slim:before {
  content: "\f19a";
}
.fi-arrow-start-full:before {
  content: "\f1bd";
}
.fi-arrow-end-full:before {
  content: "\f1bc";
}
#header,
header.main-nav {
  background-color: #fff;
  position: relative;
}
#header a,
header.main-nav a {
  text-decoration: none;
}
.navItem > a {
  position: relative;
}
.navItem .dropdownMenu {
  min-width: 200px;
}
.navItem > .dropdownMenu {
  margin: 0;
}
.navbarNav .navLink.dropdown-toggle:not(.nav-link-caret-hide):before {
  border: 0;
  content: "\f1c4 ";
  font-family: Flaticon;
  font-size: 12px;
  height: 12px;
  line-height: 1em;
  margin-top: -6px;
  position: absolute;
  right: 8px;
  top: 50%;
  width: 12px;
}
.navbarNav .navLink > i {
  vertical-align: text-top;
}
body.header-scroll-reveal.user-scrolled-down #header:not(.shadowNone) {
  box-shadow: 0 0 25px rgba(140, 152, 164, 0.1);
}
.navbarToggler > svg {
  display: none;
}
.navbarToggler[aria-expanded="false"] > svg:first-child,
.navbarToggler[aria-expanded="true"] > svg:last-child {
  display: inline-block;
}
body.header-hide.btn-header-toggle-show .btn-header-toggle {
  animation: _btnslidedown 0.5s ease-out forwards;
  position: fixed !important;
  right: 20px !important;
}
@keyframes _btnslidedown {
  0% {
    top: -100px;
  }
  to {
    top: 15px;
  }
}
body.layoutAdmin #wrapper_content.js-animation-enable {
  transition: all 0.15s linear;
}
.navbarToggler {
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  font-size: unset;
  height: 60px;
  margin-left: -15px;
  margin-right: 8px;
  width: 60px;
}
@media only screen and (min-width: 992px) {
  nav.navbar:not(.hAuto) {
    min-height: 80px;
  }
  .navItem.dropdown-mega {
    position: inherit;
  }
  .navbarNav:not(.navbar-sm) {
    height: 80px;
  }
  .navbar .navbarNav .navLink {
    cursor: pointer;
    padding: 28px 15px;
  }
  .navbarNav .navLink > i + span {
    display: inline-block;
    padding-left: 10px;
  }
  .navbar .navbarNav .navLink.dropdown-toggle:not(.nav-link-caret-hide) {
    padding-right: 30px;
  }
  .navbarNav.navbar-sm {
    height: 55px;
  }
  .navbar .navbarNav.navbar-sm .navLink {
    cursor: pointer;
    padding: 15px;
  }
  .navbar .navbarNav.navbar-sm .navItem:first-child .navLink {
    padding-left: 0;
  }
  .navbar .navbarNav a.navLink {
    height: 100%;
  }
  body:not(.layoutAdmin) .navbarBrand {
    margin-right: 50px;
  }
  .navbar .dropdownMenu.dropdown-menu-up {
    bottom: 0 !important;
    top: auto !important;
  }
  .navbar .dropdownMenu.dropdown-menu-up-n100 {
    bottom: auto !important;
    top: -100px !important;
  }
  .navbar .dropdownMenu.dropdown-menu-up-n150 {
    bottom: auto !important;
    top: -150px !important;
  }
  .navbar .dropdownMenu.dropdown-menu-up-n180 {
    bottom: auto !important;
    top: -180px !important;
  }
  .navbar .dropdownMenu.dropdown-menu-up-n220 {
    bottom: auto !important;
    top: -220px !important;
  }
  .navbar .dropdownMenu.dropdown-menu-up-n250 {
    bottom: auto !important;
    top: -250px !important;
  }
  .navbarToggler.navbarToggler-horizontal {
    display: inline-block;
    height: 100%;
    width: 60px;
  }
  body:not(.layout-boxed) .navbarToggler.navbarToggler-horizontal {
    margin-left: 0;
    margin-right: 15px;
  }
  body:not(.layout-boxed) .navbarLight .navbar-toggler-horizontal {
    border-left-color: rgba(0, 0, 0, 0.1) !important;
    border-right-color: rgba(0, 0, 0, 0.1) !important;
  }
  body:not(.layout-boxed) .navbar-dark .navbar-toggler-horizontal {
    border-left-color: hsla(0, 0%, 100%, 0.1) !important;
    border-right-color: hsla(0, 0%, 100%, 0.1) !important;
  }
  .nav-horizontal {
    display: none;
    left: 0;
    position: absolute;
    top: 100%;
  }
  .nav-horizontal .dropdown-mega {
    position: inherit;
  }
  .nav-horizontal .dropdown-mega > .dropdownMenu {
    max-height: 75vh;
    max-width: 100%;
    min-height: 100%;
    min-width: auto;
    overflow: hidden;
    padding: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .navbarNav .navLink.dropdown-toggle:not(.nav-link-caret-hide):before {
    top: 20px;
  }
  .navbarNav .dropdownMenu {
    max-width: 100% !important;
    min-width: 1px !important;
    width: 100% !important;
  }
  .navbarNav .dropdownMenu > .dropdown-item > .dropdownMenu {
    padding-left: 15px !important;
  }
  header.main-nav .navbarCollapse.collapsing {
    display: none !important;
  }
  .navbarCollapse.navbar-animate-bouncein {
    animation: __utilBounceIn 0.35s ease-out forwards;
  }
  .navbarCollapse.navbar-animate-fadein {
    animation: __utilFadeIn 0.35s ease-out forwards;
  }
  .navbarCollapse.navbar-animate-fadeinup {
    animation: __utilFadeInUp 0.21s ease-out forwards;
  }
  #header .navbarCollapse.collapsing {
    display: none !important;
    transition: none !important;
  }
  .navbarCollapse.show {
    background-color: #f9fbfd;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
  }
  .navbarCollapse.show > .navbarNav {
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    bottom: 15px;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    height: auto;
    left: 15px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 15px;
    position: absolute;
    right: 15px;
    top: 85px;
  }
  .navbar-dark .navbarCollapse.show > .navbarNav a {
    color: #506690;
  }
  .navbarXs {
    background-color: #fff;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    display: block !important;
  }
  .navbarXs > .navbarToggler {
    border: 0;
    float: right;
    height: 70px;
    margin: 0;
    width: 70px;
  }
  .navbarXs > .navbarBrand {
    line-height: 70px;
    min-height: 70px;
  }
  .navbarXs > .navbarBrand > img {
    max-height: 70px !important;
  }
  .navbarXs > .navbarBrand {
    margin: 0 15px;
  }
  .navbarNav .navItem.dropdown > .navLink {
    font-size: 18px;
    font-weight: 500;
  }
  .navbarNav .navItem.dropdown > .navLink.dropdown-toggle {
    color: #869ab8;
  }
  .navbarNav .navItem.dropdown > .navLink:before {
    display: none !important;
  }
  .navbarNav .navItem.dropdown > .dropdownMenu {
    display: block !important;
    margin-bottom: 30px !important;
    margin-top: 8px;
  }
  .navItem .dropdownMenu {
    box-shadow: none !important;
    font-size: inherit;
  }
}
#footer a {
  color: #121212;
}
#footer.text-white a:hover {
  color: #fff !important;
}
#footer.footer-dark {
  background: #313131;
  background: linear-gradient(180deg, #454545 0, #313131);
  color: #fff;
}
#footer.footer-dark .footer-svg-shape > svg {
  fill: #3e3e3e !important;
}
#footer.footer-dark a {
  color: #fff;
}
#footer.footer-dark .bg-distinct {
  background-color: rgba(0, 0, 0, 0.1);
}
#footer.footer-dark hr {
  border-color: rgba(134, 154, 184, 0.3);
}
#footer.footer-light {
  background: #ebebeb;
  background: linear-gradient(180deg, #fff 0, #ebebeb);
  color: #1b2a4e;
}
#footer.footer-light .footer-svg-shape > svg {
  fill: #f8f8f8 !important;
}
#footer.footer-light .bg-distinct {
  background-color: rgba(0, 0, 0, 0.03);
}
@media only screen and (max-width: 992px) {
  #footer .breadcrumbItem {
    display: block;
    float: none !important;
    font-size: 17px;
    margin-top: 3px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  #footer .breadcrumbItem a {
    background-color: hsla(0, 0%, 100%, 0.02);
    border-radius: 0.35rem;
    display: block;
    padding: 8px !important;
    text-decoration: none;
  }
  #footer .breadcrumb .breadcrumbItem:hover a {
    background-color: hsla(0, 0%, 100%, 0.03);
  }
  #footer:not(.footer-dark) .breadcrumbItem a {
    background-color: rgba(0, 0, 0, 0.02);
  }
  #footer:not(.footer-dark) .breadcrumbItem:hover a {
    background-color: rgba(0, 0, 0, 0.03);
  }
  #footer .breadcrumbItem + .breadcrumbItem:before {
    display: none !important;
    padding: 0;
  }
}
@media only screen and (min-width: 992px) {
  body.aside-lg #wrapper_content {
    margin-left: 290px !important;
  }
  body.asideSticky.aside-lg aside.aside-end,
  body.asideSticky.aside-lg aside.asideStart {
    width: 290px !important;
  }
}
#middle {
  max-width: 100% !important;
}
#middle,
.container,
.container-xlg {
  position: relative;
}
.container-xlg {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media only screen and (max-width: 1201px) {
  .container-ignore-breakpoints {
    max-width: 100%;
  }
}
@media only screen and (min-width: 1400px) {
  .container-xlg {
    max-width: 1400px;
  }
}
@media only screen and (max-width: 1400px) {
  .container-xlg {
    max-width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  body:not(.header-over):not(.headerSticky):not(.header-scroll-reveal):not(
      .layoutAdmin
    ).layout-boxed {
    background-color: #f2f3f8;
  }
  body:not(.header-over):not(.headerSticky):not(.header-scroll-reveal):not(
      .layoutAdmin
    ).layout-boxed
    #wrapper {
    background-color: #fff;
    border-radius: 0.25rem;
    -ms-box-shadow: 0 0 35px rgba(140, 152, 164, 0.15);
    -o-box-shadow: 0 0 35px rgba(140, 152, 164, 0.15);
    box-shadow: 0 0 35px rgba(140, 152, 164, 0.15);
    margin: 60px auto;
    max-width: 1200px;
  }
  body:not(.header-over):not(.headerSticky):not(.header-scroll-reveal):not(
      .layoutAdmin
    ).layout-boxed
    #header,
  body:not(.header-over):not(.headerSticky):not(.header-scroll-reveal):not(
      .layoutAdmin
    ).layout-boxed
    #header
    > #top_bar {
    -moz-border-top-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    -moz-border-top-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  body:not(.header-over):not(.headerSticky):not(.header-scroll-reveal):not(
      .layoutAdmin
    ).layout-boxed
    #footer {
    -moz-border-bottom-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    -moz-border-bottom-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
#top_bar {
  font-size: 0.875rem;
}
#top_bar .dropdownMenu {
  max-height: 350px;
  min-width: 1rem;
}
#top_bar .dropdown-item {
  font-size: 0.9rem;
  padding: 0.8rem !important;
}
.flickity-preloader:not(.flickity-enabled) > a:not(:first-child),
.flickity-preloader:not(.flickity-enabled) > div:not(:first-child),
.flickity-preloader:not(.flickity-enabled) > img:not(:first-child),
.swiper-container:not(.swiper-container-initialized)
  .swiper-slide:not(:first-child) {
  display: none;
}
.flickity-preloader:not(.flickity-enabled),
.swiper-container.swiper-preloader:not(.swiper-container-initialized) {
  position: relative;
}
.flickity-preloader:not(.flickity-enabled):after,
.swiper-container.swiper-preloader:not(.swiper-container-initialized):after {
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.flickity-preloader:not(.flickity-enabled) > a:first-child,
.flickity-preloader:not(.flickity-enabled) > div:first-child,
.flickity-preloader:not(.flickity-enabled) > img:first-child,
.swiper-container.swiper-preloader:not(.swiper-container-initialized)
  .swiper-slide:first-child {
  opacity: 0.2;
}
.flickity-preloader:not(.flickity-enabled):before,
.swiper-container.swiper-preloader:not(.swiper-container-initialized):before {
  animation: spin 1.3s linear infinite;
  color: #fff;
  content: "\f1e0";
  font-family: Flaticon;
  font-size: 30px;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  z-index: 2;
}
.slimScrollBar {
  z-index: 9 !important;
}
.slimScrollRail {
  z-index: 8 !important;
}
.section.jarallax,
section.jarallax {
  transition: none !important;
}
.summernote-fix {
  background-color: #fff;
  border: 1px solid #f8f9fa;
  border-radius: 0.35rem;
  box-shadow: 0 0 0 0 #fff, 0 -42px 0 0 #f8f9fa, 0 10px 0 1px #f8f9fa;
  color: #fff;
  margin-bottom: 5px;
  margin-top: 42px;
}
.bs-select-fix[multiple] {
  max-height: 52.47px;
}
.bs-select-fix.formControlSm[multiple] {
  max-height: 42.72px;
}
.col {
  min-width: 0;
}
.pointer-events-none,
a > i,
a > svg,
button > i,
button > svg {
  pointer-events: none;
}
.blockquote-footer {
  margin-top: 0 !important;
}
.max-vh-25 {
  max-height: 25vh !important;
}
.max-vh-25,
.max-vh-50 {
  min-height: auto !important;
}
.max-vh-50 {
  max-height: 50vh !important;
}
.max-vh-75 {
  max-height: 75vh !important;
  min-height: auto !important;
}
.table:not(.table-striped):not(.table-hover) > :not(caption) > * > * {
  box-shadow: inherit;
}
:root {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
}
body.layoutAdmin {
  background-color: #eef0f8;
  display: flex;
  flex-direction: column;
}
body.layoutAdmin #wrapper {
  flex: 1;
}
body.layoutAdmin #header {
  z-index: 10;
}
body.layoutAdmin #middle {
  padding: 30px;
}
body.layoutAdmin #middle > .container > header,
body.layoutAdmin #middle > header {
  margin-bottom: 30px;
}
body.layoutAdmin #middle > .container > header .h1,
body.layoutAdmin #middle > .container > header h1,
body.layoutAdmin #middle > header .h1,
body.layoutAdmin #middle > header h1 {
  margin-bottom: 0;
}
body.layoutAdmin #middle .section,
body.layoutAdmin #middle section {
  border: 0;
  color: #444;
  flex-direction: column;
  font-size: 1rem;
  margin-bottom: 30px;
  min-width: 0;
}
body.layoutAdmin #middle .section:not(.section-clean),
body.layoutAdmin #middle section:not(.section-clean) {
  background-color: #fff;
  border-radius: 0.35rem;
  box-shadow: 0 0 25px rgba(140, 152, 164, 0.1);
  padding: 15px;
}
body.layoutAdmin #middle .section-header,
body.layoutAdmin #middle section > header {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
  margin: -15px -15px 15px;
  padding: 15px;
  position: relative;
  word-break: normal;
}
body.layoutAdmin #middle .section-header > .ui-options,
body.layoutAdmin #middle section > header > .ui-options {
  margin-left: 15px;
  margin-right: -15px;
  padding-right: 15px;
}
body.layoutAdmin #middle .section-header > .ui-options > a,
body.layoutAdmin #middle header > .ui-options > a,
body.layoutAdmin #middle section > header > .ui-options > a {
  color: #313131;
  margin-left: 10px;
  text-decoration: none;
}
body.layoutAdmin
  #middle
  .section-header:not(.bg-light):not(.b-0):not(.b--0):not(.border-0):after,
body.layoutAdmin
  #middle
  section
  > header:not(.bg-light):not(.b-0):not(.b--0):not(.border-0):after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  bottom: 0;
  content: "";
  display: block;
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
}
body.layoutAdmin #middle > .section:last-child,
body.layoutAdmin #middle > section:last-child {
  margin-bottom: 0;
}
body.layoutAdmin #middle .section-body {
  position: relative;
}
#footer .dropdown-item {
  font-size: 0.9rem;
}
body.layoutAdmin #header.bg-dark {
  background: #454545 !important;
}
.portlet {
  align-items: stretch;
  background: #fff;
  border-radius: 0.35rem;
  box-shadow: 0 0 25px rgba(140, 152, 164, 0.1);
  color: #506690;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 100%;
  justify-content: space-between;
}
.portlet-header {
  align-self: baseline;
  padding: 1rem;
  width: 100%;
}
.portlet-header > span {
  color: #506690;
  display: block;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.portlet-body {
  height: 100%;
}
.portlet-body,
.portlet-footer {
  align-self: baseline;
  padding: 1rem;
  width: 100%;
}
.portlet-footer {
  display: flex;
}
.portlet .border-bottom,
.portlet .border-top,
.portlet .border:not(.medium-editor):not(.dropdown-toggle) {
  border-color: #f1f4f8 !important;
}
@supports (position: sticky) {
  body.layoutAdmin.headerSticky:not(.header-fixed):not(.header-hide) #header {
    position: sticky;
    top: 0;
  }
  body.layoutAdmin.footer-sticky #footer {
    bottom: 0;
    position: sticky;
  }
}
body.layoutAdmin #header ul.navbarNav a.navLink i + span {
  padding-left: 10px;
}
body.layoutAdmin #middle.dropdownMenu {
  font-size: 14px;
}
body.layoutAdmin .dropdown-mega > .dropdownMenu {
  max-width: 1200px;
}
aside.overlay-dark:after {
  border-radius: 0.35rem;
}
@media only screen and (min-width: 992px) {
  body.layoutAdmin #middle.mxAuto {
    max-width: 1655px !important;
  }
  body.layoutAdmin:not(.aside-focus) #header .navbarBrand.aside-width {
    margin: 0 0 0 -15px !important;
    padding-left: 15px !important;
    width: 265px !important;
  }
  body.layoutAdmin.layout-padded {
    padding: 30px;
  }
  body.layoutAdmin.layout-padded .aside-end,
  body.layoutAdmin.layout-padded .asideStart {
    border-radius: 0.35rem;
    bottom: 30px !important;
    height: auto !important;
    top: 30px !important;
    transition: none !important;
  }
  body.layoutAdmin.layout-padded .asideWrapper {
    border-radius: 0.35rem;
  }
  body.layoutAdmin.layout-padded .asideStart {
    left: 30px;
  }
  body.layoutAdmin.layout-padded .aside-end {
    right: 30px;
  }
  body.layoutAdmin.layout-padded #header {
    margin-left: 295px !important;
  }
  body.layoutAdmin.layout-padded #middle {
    padding-right: 0;
  }
  body.layoutAdmin.layout-padded #footer {
    padding-left: 295px !important;
  }
}
@media only screen and (max-width: 992px) {
  body.layoutAdmin #header {
    min-height: 68px;
  }
  body.layoutAdmin:not(.asideSticky) #header .navbarToggler {
    margin-left: 0 !important;
  }
  body.layoutAdmin .section > .container,
  body.layoutAdmin section > .container {
    padding-left: 0;
    padding-right: 0;
  }
  body.layoutAdmin #middle {
    padding-left: 15px;
    padding-right: 15px;
  }
  body.layoutAdmin #header ul.navbarNav a.navLink > span {
    background-color: transparent !important;
  }
  body.layoutAdmin .navbarCollapse.show li.navItem {
    width: 100%;
  }
}
#js_loading_icon {
  box-shadow: 0 0 25px rgba(140, 152, 164, 0.1);
  height: auto;
  width: auto;
}
#js_loading_icon,
#js_loading_icon:after {
  border-radius: 0.35rem;
}
form.js-ajax {
  position: relative;
}
form.js-ajax #js_loading_icon {
  bottom: -15px;
  left: -15px;
  right: -15px;
  top: -15px;
}
#header {
  z-index: 10;
}
body.header-over #header,
body.header-scroll-reveal #header {
  transition: all 0.5s ease-in-out;
}
body:not(.layoutAdmin).headerSticky #header {
  transition: all 0.15s linear;
}
body.header-fixed #header {
  left: 0;
  position: fixed !important;
  right: 0;
  top: 0;
}
body.header-scroll-reveal #header.header-down {
  top: 0;
}
body.header-scroll-reveal #header.header-up {
  top: -100%;
}
body.header-scroll-reveal:not(.header-is-on-top) #header.header-down #top_bar,
body.header-scroll-reveal:not(.header-is-on-top) #header.header-up #top_bar {
  display: none;
}
body.header-bottom #header {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 10;
}
body.header-bottom .navItem > .dropdownMenu {
  bottom: 80px;
  top: auto;
}
body.header-bottom
  .navbarNav
  .navLink.dropdown-toggle:not(.nav-link-caret-hide):before {
  content: "\f19b";
}
.navbarBrand > img:last-child {
  display: none;
}
.navbarBrand > img:first-child {
  display: inherit;
}
.navbarBrand > img {
  max-height: 80px;
  max-width: 140px;
}
body:not(.user-scrolled-down) .navbarBrand > img:first-child {
  display: none;
}
body:not(.user-scrolled-down) .navbarBrand > img:last-child {
  display: inline-block;
}
body.header-over:not(.user-scrolled-down) #header {
  background: 0 0 !important;
  box-shadow: none !important;
}
body.header-over #js_header_spacer {
  display: none !important;
}
body.header-over #header {
  position: absolute;
  width: 100%;
}
body.header-over:not(.header-scroll-reveal):not(.header-is-on-top):not(
    .headerSticky
  )
  #header {
  position: absolute !important;
}
body.header-over.header-is-on-top #js_header_spacer,
body.header-over.header-scroll-reveal #js_header_spacer {
  margin-top: 0 !important;
}
body.header-over.header-is-on-top:not(.user-scrolled-down) #header #top_bar,
body.header-over:not(.header-scroll-reveal):not(.user-scrolled-down)
  #header
  #top_bar {
  background: 0 0 !important;
  color: #fff;
}
body.header-over:not(.user-scrolled-down) #top_bar a,
body.header-over:not(.user-scrolled-down) #top_bar a > span {
  color: #fff;
}
body.header-over:not(.user-scrolled-down) .navbar .navbarToggler {
  fill: #fff;
  border: 0 !important;
}
body.header-over:not(.user-scrolled-down) .navbarCollapse .navbarToggler {
  fill: inherit !important;
}
body.header-over:not(.user-scrolled-down)
  #header
  .btn.roundedCircle:not(.text-white) {
  background-color: transparent !important;
  color: #fff !important;
}
@media only screen and (min-width: 992px) {
  body.header-over:not(.user-scrolled-down) .navbar .navbarNav .navLink {
    color: #fff !important;
  }
}
form.sow-search {
  position: relative;
}
form.sow-search.sow-search-over {
  animation: fadeIn 0.6s;
  bottom: 0;
  left: 15px;
  position: absolute;
  right: 15px;
  top: 0;
  z-index: 9999;
}
form.sow-search.hide {
  display: none !important;
}
form.sow-search .sow-search-backdrop {
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed !important;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
form.sow-search .sow-search-container,
form.sow-search .sow-search-input {
  position: relative;
  z-index: 100;
}
form.sow-search .sow-search-container {
  border-radius: 0.35rem;
  color: #2e2e2e;
  position: absolute;
  top: 100%;
}
form.sow-search .sow-search-container > .sow-search-container-wrapper {
  background-color: #fff;
  border-radius: 0.35rem;
  width: 100%;
}
form.sow-search ul.list-suggestion > li > a {
  background-color: transparent;
  color: var(--bs-gray-900);
  display: block;
  margin-right: 6px;
  padding: 8px 15px;
  position: relative;
  text-decoration: none;
}
form.sow-search ul.list-suggestion > li.active > a,
form.sow-search ul.list-suggestion > li:hover > a {
  background-color: #f9fbfd;
}
form.sow-search .sow-search-container:before {
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  content: "";
  left: 5%;
  position: absolute;
  top: -12px;
}
form.sow-search.sow-search-over .sow-search-input > .input-group-over {
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
}
form.sow-search.sow-search-over
  .sow-search-input
  > .input-group-over
  .sow-search-buttons {
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 6px;
  padding-right: 6px;
}
form.sow-search .sow-search-container {
  top: 66px;
}
form.sow-search.sow-search-over .sow-search-container {
  top: 96px;
}
form.sow-search
  input.form-control-sow-search.form-control-lg:not(.form-control-custom) {
  height: 50px !important;
  padding-bottom: 0.58rem !important;
  padding-top: 0.58rem !important;
}
form.sow-search
  input.form-control-sow-search:not(.form-control-custom):not(
    .form-control-lg
  ) {
  font-size: 1rem !important;
  height: 50px !important;
}
.sow-search-content a > em {
  color: #2e2e2e;
  font-style: normal;
}
@media only screen and (min-width: 768px) {
  form.sow-search
    input.form-control-sow-search.form-control-lg:not(.form-control-custom) {
    font-size: 1.3rem !important;
  }
}
@media only screen and (max-width: 991px) {
  form.sow-search:not(.sow-search-mobile) {
    display: none;
  }
  form.sow-search-mobile {
    left: 0 !important;
    margin: 0 !important;
    position: fixed !important;
    right: 0 !important;
    top: 18px !important;
    z-index: 9999;
  }
  form.sow-search-mobile .sow-search-container {
    bottom: 15px;
    display: inline-flex !important;
    position: fixed;
    top: 96px;
  }
  form.sow-search-mobile .sow-search-container,
  form.sow-search-mobile .sow-search-input {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px) !important;
  }
  form.sow-search-mobile .sow-search-input input {
    background-color: #fff !important;
    padding-right: 87px;
  }
  form.sow-search-mobile .sow-search-input > .input-group-over {
    height: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  form.sow-search-mobile input.form-control-sow-search,
  form.sow-search-mobile input.form-control-sow-search.form-control-lg {
    border: 0 !important;
    color: #121212 !important;
    font-size: 1.09rem !important;
    padding-left: 10px;
  }
  form.sow-search-mobile
    input.form-control-sow-search.text-white::-webkit-input-placeholder {
    color: #c6d0de !important;
  }
  form.sow-search-mobile
    input.form-control-sow-search.text-white::-moz-placeholder {
    color: #c6d0de !important;
  }
  form.sow-search-mobile
    .sow-search-input
    > .input-group-over
    .sow-search-buttons,
  form.sow-search-mobile
    .sow-search-input
    > .input-group-over
    .sow-search-buttons
    a
    > i,
  form.sow-search-mobile
    .sow-search-input
    > .input-group-over
    .sow-search-buttons
    button
    > i {
    color: #506690 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  form.sow-search-mobile
    .sow-search-input
    > .input-group-over
    .sow-search-buttons
    > .btn-sow-search-toggler {
    background-color: transparent !important;
    border: 0 !important;
    border-left: 1px solid #f9fbfd !important;
    border-radius: 0 !important;
    text-align: center;
    width: 52px !important;
  }
  form.sow-search-mobile
    .sow-search-input
    > .input-group-over
    .sow-search-buttons
    > .btn-sow-search-toggler
    > i {
    margin: 0 !important;
  }
}
img.lazy {
  filter: blur(0);
  transition: filter 0.3s cubic-bezier(0.4, 0.8, 0.74, 1);
}
