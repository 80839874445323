.smooth-loader-wrapper {
    background-color: #1c0950;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999999;
  }
  .smooth-loader-wrapper .smooth-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2100;
    line-height: 0;
    transform: translate(-50%, -50%);
  }
  .sk-circle {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    position: relative;
  }
  @media (min-width: 768px) {
    .sk-circle {
      width: 60px;
      height: 60px;
    }
  }
  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .sk-circle .sk-child:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }
  .sk-circle .sk-circle2 {
    transform: rotate(30deg);
  }
  .sk-circle .sk-circle3 {
    transform: rotate(60deg);
  }
  .sk-circle .sk-circle4 {
    transform: rotate(90deg);
  }
  .sk-circle .sk-circle5 {
    transform: rotate(120deg);
  }
  .sk-circle .sk-circle6 {
    transform: rotate(150deg);
  }
  .sk-circle .sk-circle7 {
    transform: rotate(180deg);
  }
  .sk-circle .sk-circle8 {
    transform: rotate(210deg);
  }
  .sk-circle .sk-circle9 {
    transform: rotate(240deg);
  }
  .sk-circle .sk-circle10 {
    transform: rotate(270deg);
  }
  .sk-circle .sk-circle11 {
    transform: rotate(300deg);
  }
  .sk-circle .sk-circle12 {
    transform: rotate(330deg);
  }
  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
  @-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    to {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  @keyframes sk-circleBounceDelay {
    0%,
    80%,
    to {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  .loaded #preloader {
    opacity: 0;
    visibility: hidden;
    transition: all 0.7s ease-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .loaded #preloader {
      transition: none;
    }
  }